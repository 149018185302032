import { BrandThemeProvider, montu } from '@montugroup/themes';
import { Box, Container, styled } from '@mui/material';
import React from 'react';

import Footer from '@/components/layout/footer';
import HeaderUnauth from '@/components/layout/header-unauth';
import MainContentWrapper from '@/components/layout/MainContentWrapper';
import { FF_UMEDS_REBRAND_UNAUTHENTICATED_ROUTES } from '@/constants/featureFlags';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import useFeatureFlags from '@/hooks/useFeatureFlags';

import PortalPartnerLayout from './portal-partner-layout/PortalPartnerLayout';

const ContentContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(12)
}));

function UnauthenticatedLayout({ children }: React.PropsWithChildren) {
  const { key: partnerKey, Logo: PartnerLogo } = usePortalPartnerBrand();
  const { flags } = useFeatureFlags();

  const isUMedsRebrandedUnauthenticatedRoutes = flags[FF_UMEDS_REBRAND_UNAUTHENTICATED_ROUTES];

  if (isUMedsRebrandedUnauthenticatedRoutes) {
    return (
      <BrandThemeProvider theme={partnerKey}>
        <HeaderContainer>
          <PartnerLogo fillColor={(theme) => theme.palette.text.primary} />
        </HeaderContainer>
        <PortalPartnerLayout.Content>
          <ContentContainer>{children}</ContentContainer>
        </PortalPartnerLayout.Content>
      </BrandThemeProvider>
    );
  }

  return (
    <BrandThemeProvider theme={montu}>
      <MainContentWrapper>
        <HeaderUnauth />
        <ContentContainer>{children}</ContentContainer>
        <Footer />
      </MainContentWrapper>
    </BrandThemeProvider>
  );
}

export default UnauthenticatedLayout;
