import { useQuery } from '@tanstack/react-query';

import { getOrders } from '@/services/data.service';
import type { TableSortOrder } from '@/types';

export type UseGetOrdersParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  filter: string;
  useFastQuery: boolean;
  statusFilters: number[];
  pharmacyIds: number[];
  showRecentOrders: boolean;
};

function useGetOrders({
  page,
  pageSize,
  sortingOrder,
  filter,
  useFastQuery,
  statusFilters,
  pharmacyIds,
  showRecentOrders = false
}: UseGetOrdersParams) {
  return useQuery({
    queryKey: ['getOrders', page, pageSize, sortingOrder, statusFilters, pharmacyIds, filter, showRecentOrders],
    // expensive query so we don't make the request unless users filtering
    enabled: showRecentOrders || statusFilters.length > 0 || pharmacyIds.length > 0 || Boolean(filter),
    refetchOnWindowFocus: false,
    queryFn: async ({ signal }) => {
      try {
        const response = await getOrders({
          page,
          pageSize,
          sortingOrder,
          filter,
          useFastQuery,
          statusFilter: statusFilters,
          pharmacyIds,
          signal
        });
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch orders: ${(e as { message: string }).message}`);
      }
    }
  });
}

export default useGetOrders;
