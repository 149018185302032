import { BrandThemeProvider } from '@montugroup/themes';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Collapse, IconButton, Stack, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';

import USER_ROLES from '@/constants/userRoles';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import useUserMenu from '@/hooks/user/useUserMenu';

import AdminMenuV2 from './navbar/AdminTileV2';
import NavbarMenuV2 from './navbar/DashboardMenuTileV2';
import MoreMenuV2 from './navbar/MoreMenuTileV2';

const LogoContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  width: '6.5rem',
  height: '1.6rem',
  flexShrink: 0
});

const StyledAppBar = styled(AppBar)({
  boxShadow: 'none',
  width: '100%'
});

const DesktopMenuContainer = styled(Box)({
  display: 'flex',
  gap: '20px',
  marginLeft: '6.5rem'
});

const AdminContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto'
});

const MobileIconButton = styled(IconButton)({
  '&:focus': {
    outline: 'none'
  }
});

const MobileMenuStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  paddingTop: '4.5rem',
  gap: '1rem',
  paddingLeft: '0.5rem',
  paddingBottom: '1.5rem',
  width: '100%'
}));

const MobileAdminContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  marginTop: '1.5rem',
  width: '100%'
});

function NavbarV2() {
  const { key: brandName, Logo } = usePortalPartnerBrand();
  const { user, menuItems, moreMenuItems, state } = useUserMenu();

  const [menuOpen, setMenuOpen] = useState(false);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const roleId = user.roleId || USER_ROLES.doctor;

  const renderDesktopMenu = () => (
    <>
      <DesktopMenuContainer>
        {menuItems.map((menu) => (
          <NavbarMenuV2 key={menu.id} name={menu.name} link={menu.link} activeReferal={state.active} />
        ))}
        <MoreMenuV2 moreMenu={moreMenuItems} menuLength={moreMenuItems.length} />
      </DesktopMenuContainer>
      <AdminContainer>
        <AdminMenuV2 roleId={roleId} firstName={user.firstName} gpCode={user.gpCode} />
      </AdminContainer>
    </>
  );

  const renderMobileMenu = () => (
    <Collapse in={menuOpen} timeout="auto" unmountOnExit>
      <MobileMenuStack>
        {!state.loading &&
          menuItems.map((menu) => (
            <NavbarMenuV2 key={menu.id} name={menu.name} link={menu.link} activeReferal={state.active} />
          ))}
        {!state.loading && moreMenuItems && <MoreMenuV2 moreMenu={moreMenuItems} menuLength={moreMenuItems.length} />}
        {!state.loading && (
          <MobileAdminContainer>
            <AdminMenuV2 roleId={roleId} firstName={user.firstName} gpCode={user.gpCode} />
          </MobileAdminContainer>
        )}
      </MobileMenuStack>
    </Collapse>
  );

  return (
    <BrandThemeProvider theme={brandName}>
      <StyledAppBar position="fixed">
        <Toolbar>
          <LogoContainer
            sx={{
              marginLeft: '1.5rem'
            }}
          >
            <Logo fillColor={theme.palette.secondary.main} sx={{ width: '6.5rem' }} />
          </LogoContainer>

          {!isMobileView && !state.loading && renderDesktopMenu()}

          {isMobileView && (
            <Stack sx={{ alignItems: 'center', marginLeft: 'auto' }}>
              <MobileIconButton edge="start" color="inherit" aria-label="menu" onClick={toggleMenu}>
                <MenuIcon
                  sx={{
                    color: theme.palette.secondary.main,
                    fontSize: '2rem',
                    marginRight: '0.5rem'
                  }}
                />
              </MobileIconButton>
            </Stack>
          )}
        </Toolbar>
      </StyledAppBar>
      {isMobileView && renderMobileMenu()}
    </BrandThemeProvider>
  );
}

export default NavbarV2;
