/**
 * Generates a URL for a doctor's booking page on Cal.com.
 *
 * @param calcomUsername - The Cal.com username of the doctor.
 * @param consultationSlug - The slug of the consultation.
 * @return The URL of the doctor's booking page.
 */
const buildDoctorBookingLink = (
  calcomUsername: string | undefined,
  consultationSlug: string | undefined,
  userId: number | undefined | null
): string => {
  if (!calcomUsername || !consultationSlug) {
    return '';
  }

  if (userId) {
    const calcomActionMetaData = generateCalcomActionEmail(userId);
    return `/${calcomUsername}/${consultationSlug}?${calcomActionMetaData}`;
  }

  return `/${calcomUsername}/${consultationSlug}`;
};

export default buildDoctorBookingLink;

/**
 * Determines whether a patient can book a consultation with a new doctor.
 * If their current doctor is not available within the set timeframe (default 7 days).
 * and only if they have no open orders
 * @param currentDoctorIsUnavailable Whether the patient's current doctor is unavailable
 * @param [openOrdersCount] The number of open orders the patient has
 * @return Whether the patient can book a consultation with a new doctor
 */
export function canPatientBookNewDoctor(currentDoctorIsUnavailable: boolean, openOrdersCount?: number): boolean {
  if (openOrdersCount && openOrdersCount > 0) {
    return false;
  }

  return currentDoctorIsUnavailable;
}

/**
 * Business wants to know who has rescheduled or cancelled a Calcom booking. So this function
 * adds the userId and the 'source' of 'circuit' to the Calcom booking link. Calcom uses this to
 * add this value to the 'rescheduledBy' and 'cancelledBy' column on the booking entry. It's a
 * requirement by Calcom that the payload is Base64 encoded.
 * @param userId The users id in Circuit.
 * @return The payload (source, userId) Base64 encoded.
 */
export const generateCalcomActionEmail = (userId: number): string => {
  const payload = {
    source: 'circuit',
    userId
  };

  const payloadBase64 = encodeURIComponent(btoa(JSON.stringify(payload)));

  // pass both parametres through, as some calcom pages allow either action to be taken
  return `rescheduledBy=${payloadBase64}&cancelledBy=${payloadBase64}`;
};
