import { FF_CREATE_ORDER_CTA_EXPERIMENT } from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { BRANDS } from '@/types';
import { Receipt } from '@mui/icons-material';

export const useCreateOrderButton = () => {
  const { flags } = useFeatureFlags();

  const messageLength = flags[FF_CREATE_ORDER_CTA_EXPERIMENT].message.length;

  return {
    id: 8,
    name: flags[FF_CREATE_ORDER_CTA_EXPERIMENT].message,
    link: '/patient/refill',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
    icon: messageLength > 20 ? null : Receipt
  };
};
