import AdminPageLayout from '@/components/layout/AdminPageLayout';
import OrdersActions from '@/components/orders/OrdersActions';
import { OrdersFilters } from '@/components/orders/OrdersFilters';
import OrdersTable from '@/components/orders/OrdersTable';
import ExportAndShipAwaitingModal from '@/components/orders/modals/ExportAndShipAwaitingModal';
import SplitOrderModal from '@/components/orders/modals/SplitOrderModal';
import UnableToDispenseModal from '@/components/orders/modals/UnableToDispenseModal';
import { OrdersProvider } from '@/context/orders/Orders';
import { OrdersModalsProvider } from '@/context/orders/OrdersModals';

function Orders() {
  return (
    <OrdersProvider>
      <OrdersModalsProvider>
        <AdminPageLayout title="Orders">
          <OrdersFilters />
          <OrdersActions />
          <OrdersTable />
          <ExportAndShipAwaitingModal />
          <SplitOrderModal />
          <UnableToDispenseModal />
        </AdminPageLayout>
      </OrdersModalsProvider>
    </OrdersProvider>
  );
}

export default Orders;
