import { Button, toast } from '@montugroup/design-system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Badge, Divider, ListItem, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';

import ExportAndShipMenuItem from '@/components/orders/bulkActions/ExportAndShipMenuItem';
import { useOrders } from '@/context/orders/Orders';
import useGetBulkOrderStatus from '@/hooks/orders/useGetBulkOrderStatus';
import { OrderService } from '@/services/order.service';
import { Logger } from '@/utils/logger';
import { useMutation } from '@tanstack/react-query';

const logger = new Logger('Order.BulkActions');

function BulkActions() {
  const { data, isPending } = useGetBulkOrderStatus();
  const { selectedOrders, refreshOrders, clearSelectedOrders } = useOrders();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const bulkUpdateMutation = useMutation({
    mutationFn: async (orderStatusId: number) => {
      try {
        const response = await OrderService.bulkStatusUpdate(orderStatusId, selectedOrders);
        return response.data;
      } catch (error: any) {
        throw new Error(`Failed to process awaiting orders: ${error.message}`);
      }
    },
    retry: false,
    onSuccess: (data) => {
      if (Object.keys(data).length === 0) {
        toast.error('Order status cannot be updated');
      } else if (data.success === false) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        clearSelectedOrders();
        refreshOrders();
      }
    }
  });

  const handleBulkStatusUpdate = async (orderStatusId: number) => {
    try {
      await bulkUpdateMutation.mutateAsync(orderStatusId);
    } catch (error: any) {
      logger.error('bulkUpdateMutation error', error.message);
      toast.error(`Failed to update orders: ${error.message}`);
    }
  };

  return (
    <Badge badgeContent={selectedOrders.length} color="primary">
      <Button
        id="bulk-actions-button"
        color="info"
        size="large"
        sx={{ minWidth: '7.5rem' }}
        aria-controls={open ? 'bulk-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        disabled={isPending || selectedOrders.length === 0 || bulkUpdateMutation.isPending}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {bulkUpdateMutation.isPending ? 'Updating' : 'Bulk actions'}
      </Button>
      <Menu
        id="bulk-actions-menu"
        MenuListProps={{
          'aria-labelledby': 'bulk-actions-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <ExportAndShipMenuItem />
        <Divider />
        <ListItem>
          <Typography variant="caption" color={theme.palette.grey['500']}>
            Change status
          </Typography>
        </ListItem>
        {(data ?? []).map((orderStatus) => (
          <MenuItem key={orderStatus.id} onClick={() => handleBulkStatusUpdate(orderStatus.id)}>
            {orderStatus.name}
          </MenuItem>
        ))}
      </Menu>
    </Badge>
  );
}

export default BulkActions;
