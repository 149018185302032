import settings from '@/constants/constants';
import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import { Button, toast } from '@montugroup/design-system';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Box } from '@mui/material';
import axios, { isAxiosError } from 'axios';

type SendPatientInvoiceButtonProps = {
  disabled: boolean;
  onSendInvoiceStatusChange: (status: string) => void;
};

export function SendPatientInvoiceButton({ disabled, onSendInvoiceStatusChange }: SendPatientInvoiceButtonProps) {
  const { order, isEditing, isAdminOrderInAuthorisedPaymentStatus } = useOrderFormMeta();

  const handleClick = async () => {
    if (order?.id) {
      onSendInvoiceStatusChange('loading');
      try {
        await axios.post(`${settings.url}/order/${order.id}/send-invoice`);
        onSendInvoiceStatusChange('success');
        toast.success('Invoice sent to patient');
      } catch (e) {
        if (isAxiosError(e)) {
          toast.error((e.response as unknown as { message: string })?.message ?? 'Something went wrong');
        } else {
          toast.error('Something went wrong');
        }
        onSendInvoiceStatusChange('idle');
      }
    }
  };

  if (!isAdminOrderInAuthorisedPaymentStatus || isEditing) {
    return null;
  }

  return (
    <Button
      disabled={disabled}
      size="large"
      variant="outlined"
      startIcon={<RequestQuoteIcon fontSize="small" />}
      onClick={handleClick}
    >
      <Box sx={{ position: 'relative', top: '1.5px' }}>Send Patient Invoice</Box>
    </Button>
  );
}
