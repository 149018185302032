import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { FilterSelect } from '@/components/common/FilterSelect';
import settings from '@/constants/constants';
import { FF_ENABLE_ORDER_FILTER_BY_PHARMACY } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import type { Pharmacy } from '@/types';

type Props = {
  selectedPharmacies: PharmacyFilterOption[];
  onSelect: (status: PharmacyFilterOption[]) => void;
};

export type PharmacyFilterOption = Pick<
  Pharmacy,
  'id' | 'name' | 'pharmacy_code' | 'state' | 'city' | 'zip_code' | 'address' | 'phone' | 'identifier_name'
>;

type PharmacyRes = {
  pharmacies: PharmacyFilterOption[];
};

function FilterOrderByPharmacySelect({ selectedPharmacies, onSelect }: Props) {
  const [pharmacies, setPharmacies] = useState<PharmacyFilterOption[]>([]);
  const { flags } = useFeatureFlags();
  const isPharmacyFilterEnabled = flags[FF_ENABLE_ORDER_FILTER_BY_PHARMACY];

  const fetchPharmacies = useCallback(async () => {
    await axios.get<PharmacyRes>(`${settings.url}/pharmacy/search`).then((resp) => {
      setPharmacies(resp.data.pharmacies);
    });
  }, []);

  useEffect(() => {
    fetchPharmacies();
  }, []);

  if (!isPharmacyFilterEnabled) {
    return null;
  }

  return (
    <FilterSelect
      options={pharmacies}
      selected={selectedPharmacies}
      name="pharmacy-filter"
      label="Filter by Pharmacy"
      placeholder="Select a Pharmacy"
      searchText="Search pharmacies"
      onChange={onSelect}
    />
  );
}

export default FilterOrderByPharmacySelect;
