import { DispensePrioritySwitch } from '@/components/order/OrderActions/DispensePrioritySwitch';
import { EditOrderButton } from '@/components/order/OrderActions/EditOrderButton';
import { ProcessPaymentButton } from '@/components/order/OrderActions/ProcessPaymentButton';
import { SendPatientInvoiceButton } from '@/components/order/OrderActions/SendPatientInvoiceButton';
import { Stack } from '@mui/material';
import { useState } from 'react';

export function OrderActions() {
  const [sendInvoiceStatus, setSendInvoiceStatus] = useState('idle');

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <DispensePrioritySwitch />
      <SendPatientInvoiceButton
        disabled={sendInvoiceStatus !== 'idle'}
        onSendInvoiceStatusChange={setSendInvoiceStatus}
      />
      <ProcessPaymentButton disabled={sendInvoiceStatus !== 'idle'} />
      <EditOrderButton />
    </Stack>
  );
}
