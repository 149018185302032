import { Button, toast } from '@montugroup/design-system';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import CircleLoader from '@/components/common/circleLoader';
import { useOrders } from '@/context/orders/Orders';
import { useExportAndShipAwaitingModal } from '@/context/orders/OrdersModals';
import { OrderService } from '@/services/order.service';
import { useMutation } from '@tanstack/react-query';

function ExportAndShipAwaitingModal() {
  const { refreshOrders } = useOrders();
  const { visible, hideExportAndShipAwaitingModal, data, isPending } = useExportAndShipAwaitingModal();

  const processAwaitingOrdersMutation = useMutation({
    mutationFn: async () => {
      try {
        const response = await OrderService.exportAndShipAwaitingOrders();
        return response.data;
      } catch (error: any) {
        throw new Error(`Failed to process awaiting orders: ${error.message}`);
      }
    },
    retry: false,
    onSuccess: (data) => {
      toast.success(data.message);
      refreshOrders();
      hideExportAndShipAwaitingModal();
    }
  });

  const processAwaitingOrders = async () => {
    try {
      await processAwaitingOrdersMutation.mutateAsync();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const NoAwaitingOrders = () => <Typography>There are no awaiting orders</Typography>;

  const AwaitingOrders = () => (
    <span>
      {data?.count} order{data?.count === 1 ? '' : 's'} will be shipped. Are you sure you want to proceed?
    </span>
  );

  return (
    <Dialog open={visible} onClose={hideExportAndShipAwaitingModal}>
      <DialogTitle>Confirm Export & Ship</DialogTitle>
      <DialogContent>
        {isPending && <CircleLoader />}
        {!isPending && data?.count === 0 && <NoAwaitingOrders />}
        {!isPending && data?.count !== undefined && data?.count > 0 && <AwaitingOrders />}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={hideExportAndShipAwaitingModal}>
          Cancel
        </Button>
        {!isPending && data?.count !== undefined && data?.count !== 0 && (
          <Button color="primary" onClick={processAwaitingOrders}>
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ExportAndShipAwaitingModal;
