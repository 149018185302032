import { outOfBandThemeColors } from '@/theme/colors';
import { MgPrescription } from '@montugroup/icons';
import { Box, Container, Stack, styled, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import type { Prescription, ScriptMetadata } from './common';
import { getUniqueKey } from './common';
import PrescriptionAccordion from './PrescriptionAccordion';
import PrescriptionStatusChip, { StatusChip } from './PrescriptionStatusChip';

export interface IPrescriptionContainer {
  prescriptionCode: string;
  prescriptionMetadata: ScriptMetadata;
  prescription: Prescription[];
}

const TopWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center'
}));

const MainContainer = styled(Container)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2, 2, 0, 2),
  borderRadius: theme.spacing(2),
  marginLeft: 0,
  marginBottom: theme.spacing(5),
  backgroundColor: theme.palette.common.white
}));

const IconWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    width: '60px',
    height: '60px',
    backgroundColor: outOfBandThemeColors.uMeds.warmWhite
  }
}));

const ScriptIdSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}));

const DateInfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
    marginLeft: 0
  }
}));

const DateWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(6),
  justifyContent: 'flex-end',
  alignItems: 'center',
  '& > p': {
    minWidth: '4.5rem'
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    '& .expiry-chip': {
      order: 2
    }
  }
}));

const PrescriptionStatusWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    '& > p': {
      minWidth: '4.5rem'
    },
    gap: theme.spacing(6)
  }
}));

export function PrescriptionContainer({
  prescriptionCode,
  prescriptionMetadata,
  prescription
}: IPrescriptionContainer) {
  const [expanded, setExpanded] = useState<number | false>(false);
  const t = useTheme();
  const isMobile = useMediaQuery(t.breakpoints.down('md'));

  const showExpiryDaysChip =
    prescriptionMetadata.daysUntilExpiry <= 30 &&
    prescriptionMetadata.daysUntilExpiry >= 1 &&
    !prescriptionMetadata.inActive &&
    prescriptionMetadata.status === 'Active';

  const handleOnAccordionChange = (productId: number) => (_event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? productId : false);
  };

  return (
    <MainContainer maxWidth="md">
      <TopWrapper>
        <TopSection>
          <IconWrapper>
            <SvgIcon component={MgPrescription} color="primary" />
          </IconWrapper>
          <ScriptIdSection>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                fontWeight={(theme) => theme.typography.fontWeightMedium}
                marginRight={(theme) => theme.spacing(2)}
              >
                Script I.D:{' '}
              </Typography>
              <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>{prescriptionCode}</Typography>
            </Box>
            {!isMobile && (prescriptionMetadata.inActive || prescriptionMetadata.status.toLowerCase() !== 'active') ? (
              <PrescriptionStatusWrapper>
                <Typography color="text.secondary">Status </Typography>
                <Box>
                  <PrescriptionStatusChip prescriptionMetadata={prescriptionMetadata} size="small" />
                </Box>
              </PrescriptionStatusWrapper>
            ) : null}
          </ScriptIdSection>
        </TopSection>

        <DateInfoSection>
          <DateWrapper>
            <Typography color="text.secondary">Created</Typography>
            <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>
              {prescriptionMetadata.createdDate}
            </Typography>
          </DateWrapper>
          <DateWrapper>
            {showExpiryDaysChip && (
              <StatusChip
                className="expiry-chip"
                label={`${prescriptionMetadata.daysUntilExpiry} days left`}
                variant="filled"
                color="warning"
                size="small"
              />
            )}
            <Typography color="text.secondary">Expiry</Typography>
            <Typography fontWeight={(theme) => theme.typography.fontWeightBold}>
              {prescriptionMetadata.expiryDate}
            </Typography>
          </DateWrapper>
          {isMobile && (prescriptionMetadata.inActive || prescriptionMetadata.status.toLowerCase() !== 'active') ? (
            <PrescriptionStatusWrapper>
              <Typography color="text.secondary">Status </Typography>
              <Box>
                <PrescriptionStatusChip prescriptionMetadata={prescriptionMetadata} size="small" />
              </Box>
            </PrescriptionStatusWrapper>
          ) : null}
        </DateInfoSection>
      </TopWrapper>

      {prescription.map((prescriptionItem) => (
        <PrescriptionAccordion
          key={getUniqueKey(prescriptionItem)}
          prescription={prescriptionItem}
          expanded={expanded}
          onChange={handleOnAccordionChange}
        />
      ))}
    </MainContainer>
  );
}

export default PrescriptionContainer;
