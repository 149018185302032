import useHelpdeskSchedule from '@/hooks/helpdesk/useHelpdeskSchedule';

import BrainfishHelpdesk from './BrainfishHelpdesk';
import ZendeskChatWidget from './ZendeskChatWidget';

function HelpdeskSelector() {
  const { active } = useHelpdeskSchedule();

  if (active?.externalId === 'zendesk') {
    return <ZendeskChatWidget />;
  }

  if (active?.externalId === 'brainfish') {
    return <BrainfishHelpdesk />;
  }

  return null;
}

export default HelpdeskSelector;
