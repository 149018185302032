export const VALID_APP_ENVS = ['test', 'development', 'qa', 'staging', 'uat', 'production'] as const;
type AppEnv = (typeof VALID_APP_ENVS)[number];

export const viteAppEnv = (): AppEnv => {
  const appEnv = import.meta.env.VITE_APP_ENV;
  if (!appEnv) {
    throw new Error('App Environment not configured');
  }
  return appEnv;
};

export const viteAppBaseUrl = (): string => {
  const appBaseUrl = import.meta.env.VITE_APP_BASE_URL;
  if (!appBaseUrl) {
    throw new Error('App Base URL not configured');
  }
  return appBaseUrl;
};

export const viteFFOverrides = (): Record<string, string | number | boolean> | null => {
  const ffOverrides = import.meta.env.VITE_FF_OVERRIDES;

  if (ffOverrides) {
    try {
      return JSON.parse(ffOverrides);
    } catch {
      throw new Error('VITE_FF_OVERRIDES is not a valid JSON string');
    }
  }

  return null;
};

type DomainSplitPortals = 'admin' | 'patient' | 'customer' | 'pharmacist';

export const viteDomainSplitBaseUrl = (requiredPortal: DomainSplitPortals): string => {
  const env = import.meta.env;

  const baseUrls = {
    admin: env.VITE_DSPLIT_ADMIN_BASE_URL,
    patient: env.VITE_DSPLIT_ALT_BASE_URL,
    customer: env.VITE_DSPLIT_UMEDS_BASE_URL,
    pharmacist: env.VITE_DSPLIT_PHARMACIST_BASE_URL
  };

  if (!baseUrls[requiredPortal]) {
    throw new Error(`${requiredPortal} Base URL not configured`);
  }

  return baseUrls[requiredPortal];
};

export default null;
