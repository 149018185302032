import { Divider, Typography } from '@mui/material';
import { ResendInviteButton } from '../ResendInviteButton';
import { usePreOnboardingContext } from '../hooks/usePreOnboardingContext';

export const InviteSent = () => {
  const { customerDetails } = usePreOnboardingContext();
  return (
    <>
      <Typography variant="subtitle1" fontWeight={600}>
        Invite sent
      </Typography>
      <Typography>
        Sign up using the invite link we&apos;ve sent to <b>{customerDetails?.email}</b>
      </Typography>
      <Divider />
      <Typography variant="body2">
        Didn&apos;t get the invite? <ResendInviteButton />
      </Typography>
    </>
  );
};
