import { BrandThemeProvider } from '@montugroup/themes';
import { Button, Stack, TextField, Typography } from '@mui/material';
import type { FormEvent } from 'react';

import usePharmacistSetPassword from '@/hooks/pharmacist/usePharmacistSetPassword';
import useSessionExpirationCheck from '@/hooks/pharmacist/useSessionExpirationCheck';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import { AuthCardContainer, AuthPageContainer, Background, CentreContainer } from './PharmacistStyles';

function PharmacistSetPassword({ onBoardKey }: { onBoardKey: string }) {
  const { key: brandName, Icon: Bg, Logo: TopLogo } = usePortalPartnerBrand();

  const { data, errors, handleUserInput, onRegister, load } = usePharmacistSetPassword(
    {
      email: '',
      password: '',
      loginType: 'pharmacist'
    },
    onBoardKey
  );

  useSessionExpirationCheck();

  const handleRegister = async (e: FormEvent) => {
    e.preventDefault();
    await onRegister(data, e);
  };

  return (
    <BrandThemeProvider theme={brandName}>
      <AuthPageContainer>
        <CentreContainer>
          <AuthCardContainer>
            <Stack mb={10}>
              <TopLogo width="150px" height="auto" fillColor={(theme) => theme.palette.primary.main} />
            </Stack>
            <Typography color="primary" variant="h5" align="left">
              Pharmacist set password
            </Typography>
            <form onSubmit={handleRegister}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email}
                value={data.email}
                autoComplete="email"
                sx={{
                  marginBottom: 0
                }}
                disabled
              />
              <TextField
                label="Password"
                type="password"
                name="password"
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!errors.password}
                helperText={errors.password}
                onChange={handleUserInput('text')}
                value={data.password}
                autoComplete="off"
              />
              <TextField
                label="Confirm Password"
                type="password"
                name="rePassword"
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!errors.rePassword}
                helperText={errors.rePassword}
                onChange={handleUserInput('text')}
                value={data.rePassword}
                autoComplete="off"
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  marginTop: 2
                }}
                color="secondary"
                disabled={load}
                size="large"
              >
                Set Password
              </Button>
            </form>
          </AuthCardContainer>
        </CentreContainer>
      </AuthPageContainer>
      <Background>
        <Bg />
      </Background>
    </BrandThemeProvider>
  );
}

export default PharmacistSetPassword;
