import { Button, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import MobileAppBar from '@/components/layout/navbar/MobileAppBar';
import { alternaleafTheme } from '@/theme';

import { BrandThemeProvider } from '@montugroup/themes';
import { imagePaths } from './Error';

const Container = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  padding: `${theme.spacing(27)} ${theme.spacing(4)} ${theme.spacing(0)} ${theme.spacing(4)}`,
  gap: theme.spacing(10),
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    textAlign: 'left',
    gap: theme.spacing(14.75)
  }
}));

const Image = styled('img')(({ theme }) => ({
  width: theme.spacing(82),
  height: theme.spacing(50),
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(90),
    height: theme.spacing(56)
  }
}));

const TextContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    maxWidth: theme.spacing(91)
  }
}));

const ButtonContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  width: '100%',
  alignItems: 'center',
  position: 'absolute',
  bottom: theme.spacing(12),
  left: 0,
  right: 0,
  boxSizing: 'border-box',
  maxWidth: theme.spacing(108),
  margin: 'auto',
  padding: `0 ${theme.spacing(4)}`,
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(3.75),
    position: 'static',
    maxWidth: 'none',
    padding: '0'
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  boxShadow: theme.shadows[2],
  lineHeight: theme.spacing(6),
  width: '100%'
}));

const OutlinedButton = styled(StyledButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}80`,
  boxShadow: 'none'
}));

const errorTypes = {
  '404': {
    // eslint-disable-next-line quotes
    title: "Sorry, we couldn't find the page you were looking for.",
    description: 'Click below to go back or else you can return to the homepage.',
    primaryText: 'Return home',
    primaryAction: '/',
    secondaryText: 'Back',
    secondaryAction: () => window.history.back()
  },
  '500': {
    // eslint-disable-next-line quotes
    title: "Oops! Looks like we're temporarily offline.",
    description:
      // eslint-disable-next-line quotes
      "We're working hard to resolve this issue and will be back online soon. Return to the homepage or check out our FAQs to learn more.",
    primaryText: 'Return home',
    primaryAction: '/',
    secondaryText: 'FAQs',
    secondaryAction: () => {
      window.location.href = '/faq';
    }
  }
};

type ErrorPageProps = {
  errorCode: keyof typeof errorTypes;
};

function ErrorPage({ errorCode }: ErrorPageProps) {
  const { title, description, primaryText, primaryAction, secondaryText, secondaryAction } = errorTypes[errorCode];
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const [image, setImage] = useState<string | undefined>();

  useEffect(() => {
    const loadImage = async () => {
      let img;
      if (!errorCode) {
        img = await imagePaths.generic();
      } else {
        const statusString = errorCode.toString();
        if (Object.keys(imagePaths).includes(statusString)) {
          img = await imagePaths[statusString]();
        } else {
          img = await imagePaths.generic();
        }
      }
      setImage(img);
    };
    loadImage();
  }, [errorCode]);

  return (
    <BrandThemeProvider theme={alternaleafTheme}>
      {isMobileView && <MobileAppBar />}
      <Container>
        <Image src={image} alt={`Error ${errorCode}`} />
        <TextContainer>
          <Typography variant="h4" component="h1" fontWeight="fontWeightMedium" fontSize="1.5rem" lineHeight="2rem">
            {title}
          </Typography>
          <Typography variant="body1" component="p" fontSize="1rem" lineHeight="1.5rem">
            {description}
          </Typography>
          <ButtonContainer>
            <StyledButton variant="contained" color="primary" onClick={() => window.location.replace(primaryAction)}>
              {primaryText}
            </StyledButton>
            <OutlinedButton variant="outlined" color="primary" onClick={secondaryAction}>
              {secondaryText}
            </OutlinedButton>
          </ButtonContainer>
        </TextContainer>
      </Container>
    </BrandThemeProvider>
  );
}

export default ErrorPage;
