import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, Button, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

import BookRegularOrNewDoctorButton from '@/components/common/BookRegularOrNewDoctorButton';
import useCalendarBooking from '@/hooks/consultations/useCalcomBooking';
import type { LoginUser } from '@/types/user.types';

type BookConsultationCardProps = {
  link: string;
  price: string;
  refetch: () => void;
  user: LoginUser | undefined;
  showDoctorFollowupRoundRobinButton: boolean;
};

const FollowUpContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  padding: theme.spacing(6, 5.25),
  borderRadius: theme.spacing(2),
  border: '1px solid rgba(0, 0, 0, 0.20)',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(98)
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: theme.spacing(6),
  letterSpacing: 0
}));

const Description = styled(Typography)(({ theme }) => ({ fontSize: theme.spacing(3.5) }));

const Price = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.spacing(7),
  lineHeight: theme.spacing(9)
}));

const BookButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[2],
  alignSelf: 'stretch',
  color: theme.palette.common.white,
  height: theme.spacing(10)
}));

function BookConsultationCard({
  link,
  price,
  refetch,
  user,
  showDoctorFollowupRoundRobinButton
}: BookConsultationCardProps) {
  const t = useTheme();
  const prefilledFormData = {
    email: user?.email,
    name: `${user?.first_name} ${user?.last_name}`,
    phoneNumber: user?.phone
  };

  const themeColourScheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';

  const { formData: calendarFormData, origin } = useCalendarBooking({
    formData: prefilledFormData,
    handleBookingSuccess: refetch,
    handleRescheduleSuccess: refetch,
    handleCancelSuccess: refetch,
    theme: themeColourScheme
  });

  const ButtonSx = {
    borderRadius: t.spacing(1),
    boxShadow: t.shadows[2],
    alignSelf: 'stretch',
    color: t.palette.common.white,
    height: t.spacing(10),
    backgroundColor: `${t.palette.primary.main} !important`
  };

  return (
    <FollowUpContainer>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Title>Follow-up consultation</Title>
        <Stack direction="row" alignItems="center" spacing={2}>
          <ScheduleIcon sx={{ width: '1.25rem', height: '1.25rem', color: (theme) => theme.palette.secondary.main }} />
          <Typography>10 minutes</Typography>
        </Stack>
      </Stack>
      <Description>
        During your follow-up session, you&apos;ll have the chance to evaluate your treatment progress
      </Description>
      <Price>{price}</Price>
      {showDoctorFollowupRoundRobinButton ? (
        <BookRegularOrNewDoctorButton
          buttonProps={{ sx: ButtonSx, variant: 'contained' }}
          calcomFollowUpConsultEvent={link}
          handleBookingSuccess={refetch}
          handleRescheduleSuccess={refetch}
          handleCancelSuccess={refetch}
          showDoctorFollowupRoundRobin={showDoctorFollowupRoundRobinButton}
          bookDoctorConsultationButtonText="Book consultation"
          togglePrimaryColours
          currentDoctorLink={link}
        />
      ) : (
        <BookButton
          data-cal-link={link}
          data-cal-config={calendarFormData}
          data-cal-origin={origin}
          data-cal-theme-mode={themeColourScheme}
          variant="contained"
        >
          Book consultation
        </BookButton>
      )}
    </FollowUpContainer>
  );
}

export default BookConsultationCard;
