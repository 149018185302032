import { Box, Stack, styled, Tabs, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import React, { useEffect, useMemo, useState } from 'react';

import { BRAZE_CONTENT_CARD } from '@/assets/data/enums';
import SinglePageContentCard from '@/components/braze/SinglePageContentCard';
import { ErrorAlert, InfoAlert } from '@/components/common/RebrandStyledComponents';
import useGetConsultations from '@/hooks/consultations/useGetConsultations';
import useGetPatientDoctorAvailability from '@/hooks/patient/useGetPatientDoctorAvailability';
import useGetPatientOrderSummary from '@/hooks/patient/useGetPatientOrderSummary';
import usePagination from '@/hooks/usePagination';
import BookConsultationCard from '@/pages/consultations/components/BookConsultationCard';
import FAQAccordion from '@/pages/consultations/components/FAQAccordion';
import HistoryConsultationCardSkeleton from '@/pages/consultations/components/HistoryCardLoadingSkeleton';
import HistoryConsultationCard from '@/pages/consultations/components/HistoryConsultationCard';
import UpcomingConsultationCard from '@/pages/consultations/components/UpcomingConsultationCard';
import AuthService from '@/services/authentication.service';
import BackButton from '@/ui-library/backButton/BackButton';
import Tab from '@/ui-library/tab/Tab';
import TabPanel from '@/ui-library/tabPanel/TabPanel';

import FAQConsultationData from '../patient/umeds_rebrand/FAQConsultationData';

import { FF_CALCOM_EMBED_PARAMS } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import ConsultationPagination from './components/ConsultationPagination';
import UpcomingConsultationCardSkeleton from './components/UpcomingConsultationLoadingSkeleton';
import buildDoctorBookingLink, { canPatientBookNewDoctor } from './utils';

const MainContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 3, 10, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(27, 16, 20, 16)
  }
}));

const FAQContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#EBEAEE', // TODO: Update color once design-system is imported - Neutral Grey
  flexGrow: 1,
  padding: theme.spacing(0, 2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0)
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(30)
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6, 16, 20, 16)
  }
}));

export default function PatientConsultation() {
  const user = AuthService.getUser();
  const userId = user?.user?.id;
  const [value, setValue] = useState(0);
  const { flags } = useFeatureFlags();

  const ffEnabledCalcomEmbedParams = flags[FF_CALCOM_EMBED_PARAMS];

  const { consultData, isLoading, isError, isSuccess, refetch } = useGetConsultations({
    when: 'upcoming'
  });

  const {
    consultData: completedData,
    isLoading: isLoadingCompleted,
    isError: isErrorCompleted,
    isSuccess: isSuccessCompleted
  } = useGetConsultations({
    when: 'completed'
  });

  const {
    data: bookingData,
    isLoading: fetchingAvailability,
    isSuccess: isBookingSuccess,
    isError: isBookingError
  } = useGetPatientDoctorAvailability(userId);

  const [expandedHistoryConsultations, setExpandedHistoryConsultations] = useState<boolean[]>(
    Array(completedData?.length || 0).fill(false)
  );

  const bookingLink = useMemo(() => {
    const embedUserId = ffEnabledCalcomEmbedParams ? userId : undefined;
    return buildDoctorBookingLink(bookingData?.calcomUsername, bookingData?.calcomFollowUpConsultEvent, embedUserId);
  }, [bookingData, ffEnabledCalcomEmbedParams, userId]);

  const currentDoctorIsUnavailable = bookingData?.followUpAvailabilityStatus === 'Unavailable';

  // Check if patient has any active open orders
  const { isLoading: fetchingOrderSummary, data: openOrdersResponse } = useGetPatientOrderSummary();

  const showDoctorFollowupRoundRobinButton =
    !fetchingAvailability &&
    !fetchingOrderSummary &&
    canPatientBookNewDoctor(currentDoctorIsUnavailable, openOrdersResponse?.openOrdersCount);

  const onTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (isSuccessCompleted && completedData.length > 0) {
      const initialExpandedState = completedData.map((_, index) => index < 2);
      setExpandedHistoryConsultations(initialExpandedState);
    }
  }, [completedData, isSuccessCompleted]);

  const [handlePagination, currentPage, filterData, totalPageCount] = usePagination({
    data: completedData,
    search: null
  });

  const toggleExpanded = (index: number) => {
    setExpandedHistoryConsultations((prevState) => {
      const consultationState = [...prevState];
      consultationState[index] = !consultationState[index];
      return consultationState;
    });
  };

  const renderHistoryConsultations = useMemo(() => {
    if (isLoadingCompleted) {
      return (
        <>
          <HistoryConsultationCardSkeleton isExpanded />
          <HistoryConsultationCardSkeleton isExpanded />
        </>
      );
    }
    if (isErrorCompleted) {
      return (
        <ErrorAlert variant="standard" severity="error">
          An unexpected error occurred. Please try again.
        </ErrorAlert>
      );
    }
    if (isSuccessCompleted && completedData.length === 0) {
      return <InfoAlert severity="info">No consultations found.</InfoAlert>;
    }

    return (
      <>
        {filterData.map((consultation, index) => (
          <HistoryConsultationCard
            key={consultation.id}
            consultation={consultation}
            isExpanded={expandedHistoryConsultations[index] || false}
            toggleExpanded={() => toggleExpanded(index)}
          />
        ))}
        {filterData.length > 0 && (
          <ConsultationPagination
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            handlePagination={handlePagination}
          />
        )}
      </>
    );
  }, [expandedHistoryConsultations, filterData, isErrorCompleted, isLoadingCompleted, isSuccessCompleted]);

  const renderUpcomingConsultations = useMemo(() => {
    if (isLoading) {
      return <UpcomingConsultationCardSkeleton />;
    }
    if (isError) {
      return (
        <ErrorAlert variant="standard" severity="error">
          An unexpected error occurred. Please try again.
        </ErrorAlert>
      );
    }
    if (isSuccess && consultData.length === 0) {
      if (isBookingError) {
        return (
          <ErrorAlert variant="standard" severity="error">
            An unexpected error occurred. Please try again.
          </ErrorAlert>
        );
      }

      return (
        <BookConsultationCard
          link={bookingLink}
          price="$59"
          refetch={refetch}
          user={user?.user}
          showDoctorFollowupRoundRobinButton={showDoctorFollowupRoundRobinButton}
        />
      );
    }

    return (
      <Stack gap={4}>
        {consultData.map((consultation) => (
          <UpcomingConsultationCard key={consultation.id} consultation={consultation} refetchConsultations={refetch} />
        ))}
      </Stack>
    );
  }, [consultData, isError, isLoading, isSuccess, bookingLink, isBookingSuccess]);

  return (
    <>
      <MainContainer>
        <Box display="flex">
          <BackButton />
          <Typography variant="h5" component="h1" color="primary.main" lineHeight={(theme) => theme.spacing(10)}>
            Consultations
          </Typography>
        </Box>

        <Box sx={{ margin: (theme) => `${theme.spacing(6)} 0` }}>
          <SinglePageContentCard displayType={BRAZE_CONTENT_CARD.PATIENT_CONSULTATIONS} align="left" />
        </Box>

        <Tabs
          value={value}
          onChange={onTabChange}
          aria-label="Patient consultation tabs"
          sx={{ marginTop: (theme) => theme.spacing(5) }}
        >
          <Tab
            id="tab-upcoming"
            label="Upcoming"
            aria-controls="tabpanel-0"
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                width: '50%'
              }
            })}
          />
          <Tab
            id="tab-history"
            label="History"
            aria-controls="tabpanel-1"
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                width: '50%'
              }
            })}
          />
        </Tabs>
        <TabPanel id="tabpanel-0" index={0} value={value} label="Upcoming">
          {renderUpcomingConsultations}
        </TabPanel>
        <TabPanel id="tabpanel-1" index={1} value={value} label="History">
          {renderHistoryConsultations}
        </TabPanel>
      </MainContainer>
      <FAQContainer>
        <ContentWrapper>
          <Typography
            variant="subtitle2"
            component="h2"
            color="primary.main"
            lineHeight={(theme) => theme.spacing(8)}
            fontWeight={600}
            marginBottom={(theme) => theme.spacing(4)}
          >
            FAQ
          </Typography>
          <Stack gap={(theme) => theme.spacing(2)} maxWidth="lg">
            {FAQConsultationData.map((faq: { id: number; question: string; content: ReactNode }) => (
              <FAQAccordion key={faq.id} question={faq.question} content={faq.content} />
            ))}
          </Stack>
        </ContentWrapper>
      </FAQContainer>
    </>
  );
}

PatientConsultation.displayName = 'PatientConsultation';
