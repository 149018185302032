import useGetPatientForOrder from '@/hooks/order/useGetPatientForOrder';
import { useGetOrder } from '@/hooks/orders/useGetOrder';
import type { PatientForOrderDetails } from '@/services/order.service';
import type { Order } from '@/types';
import { noOp } from '@/utils/noOp';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type OrderMeta = {
  order: Order | undefined;
  patientDetails: PatientForOrderDetails | undefined;
  error: Error | null;
  isFetching: boolean;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

export const OrderMetaContext = createContext<OrderMeta>({
  order: undefined,
  patientDetails: undefined,
  error: null,
  isFetching: false,
  isEditing: false,
  setIsEditing: noOp
});

export function OrderMetaProvider(props: PropsWithChildren) {
  const { id, patientId } = useParams();
  const { data: order, isFetching: orderIsFetching, error: orderError } = useGetOrder({ id: id as `${number}` });
  const {
    data: patientDetails,
    isFetching: patientIsFetching,
    error: patientError
  } = useGetPatientForOrder(patientId ? parseInt(patientId, 10) : undefined);

  const [isEditing, setIsEditing] = useState(false);

  const value = useMemo(
    () => ({
      order,
      patientDetails,
      isFetching: orderIsFetching || patientIsFetching,
      error: orderError ?? patientError,
      isEditing,
      setIsEditing
    }),
    [order, patientDetails, orderIsFetching, patientIsFetching, orderError, patientError, isEditing]
  );

  return <OrderMetaContext.Provider value={value}>{props.children}</OrderMetaContext.Provider>;
}

export const useOrderFormMeta = () => {
  const { id, refill, patientId } = useParams();
  const { order, patientDetails, error, isFetching, isEditing, setIsEditing } = useContext(OrderMetaContext);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  return {
    order,
    patientDetails,
    error,
    isLoading: isFetching,
    isRefill: !!refill,
    isNewOrder: Boolean(!refill && patientId && !order?.patient_id),
    isExistingOrder: Boolean(!!id && !refill && !patientId && order?.patient_id),
    isAdminOrderInAuthorisedPaymentStatus: Boolean(
      order?.payment_status === 'AUTHORISED' && order?.payment_initiated_path === 'ADMIN_INITIATED'
    ),
    isEditing,
    toggleEditing
  };
};
