import { ProfileSection } from '@/components/admin/patient-profile/ProfileSection';
import Note from '@/components/note/Note';
import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import AuthService from '@/services/authentication.service';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export const OrderNote = () => {
  const [isAddingNote, setIsAddingNote] = useState(false);
  const { order, isLoading, isNewOrder, isEditing } = useOrderFormMeta();

  const [addedNotes, setAddedNotes] = useState<{ id: number; note: string }[]>([]);

  const orderNotes =
    order?.order_notes
      ?.split('|')
      .filter((note) => note.trim() !== '')
      .map((note: string, id: number) => {
        return { id, note };
      }) || [];

  const { reset } = useForm<{ note: string }>();

  const mergedNotes = [...addedNotes, ...(orderNotes || [])];

  const canWeAddNote = isAddingNote && (!isNewOrder || !isEditing);

  const onNoteCancel = () => {
    reset({ note: '' });
    setIsAddingNote(false);
  };
  const onNoteSubmit = (data: { note: string }) => {
    const newId = addedNotes.length + orderNotes.length;
    const newNote = { id: newId, note: data.note };
    setAddedNotes([newNote, ...addedNotes]);
    reset({ note: '' });
    setIsAddingNote(false);
  };

  if (isLoading) {
    return (
      <ProfileSection
        title="Order Notes"
        Actions={<Skeleton width={200} height={38} variant="rectangular" data-testid="loading" />}
      >
        <Skeleton width="100%" height={100} variant="rectangular" />
      </ProfileSection>
    );
  }

  return (
    AuthService.isAdmin() && (
      <Note
        key={isAddingNote ? 'adding' : 'viewing'}
        title="Order Notes"
        isAddingNote={canWeAddNote}
        setIsAddingNote={setIsAddingNote}
        onNoteSubmit={onNoteSubmit}
        onNoteCancel={onNoteCancel}
        notes={mergedNotes}
        enableAuthor={false}
        enableDate={false}
        canShowPagination={false}
        label={'Creating a new order note'}
      />
    )
  );
};

export default OrderNote;
