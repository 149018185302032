import type { CreditLineItem } from './types';

export const SESSION_STORAGE_KEY = 'orderProductSelection';

export interface PersistedProductDetails {
  id: number;
  name: string;
  price: number;
  quantity: number;
  supplier: string;
  is_concession: boolean;
}

export interface PersistedOrderDetails {
  selectedProducts: PersistedProductDetails[];
  discounts: CreditLineItem[];
  needSasApproval: boolean;
}

export const managePersistedOrderDetails = () => {
  const getOrderDetails = (): PersistedOrderDetails | null => {
    try {
      const sessionDataJson = sessionStorage.getItem(SESSION_STORAGE_KEY);
      if (sessionDataJson) {
        const sessionData = JSON.parse(sessionDataJson);
        return sessionData;
      }
    } catch {
      return null;
    }

    return null;
  };

  const getSelection = (): Array<PersistedProductDetails> | null => {
    const orderDetails = getOrderDetails();
    return orderDetails?.selectedProducts || null;
  };

  const setOrderDetails = (
    selectedProducts: Array<PersistedProductDetails>,
    discounts: CreditLineItem[],
    needSasApproval: boolean = false
  ): void => {
    const orderDetails: PersistedOrderDetails = {
      selectedProducts,
      discounts,
      needSasApproval
    };

    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(orderDetails, null, 2));
  };

  return {
    getSelection,
    getOrderDetails,
    setOrderDetails
  };
};
