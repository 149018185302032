import type { SelectedProduct, SplitOrderData } from '@/components/orders/modals/SplitOrderModal';
import settings from '@/constants/constants';
import { MenuItem, Select } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material/Select';

type SplitOrderSelectionCellProps = {
  product: SelectedProduct;
  onChange: (orderId: number, product: SelectedProduct) => void;
  disabled: boolean;
  data: SplitOrderData;
};

function SplitOrderSelectionCell({ data, product, disabled, onChange }: SplitOrderSelectionCellProps) {
  const { splitOrders, inactiveProducts } = data;

  const order = splitOrders.find((splitOrder) => splitOrder.order === product.orderId);

  const inactiveOrderIds = inactiveProducts.map((product) => product.orderId);

  const options = splitOrders.filter((splitOrder) => !inactiveOrderIds.includes(splitOrder.order));

  const handleChange = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number, product);
  };

  if (!order) {
    return null;
  }

  if (product.status === settings.productStatus.inactive) {
    return (
      <Select value={order.order} disabled data-testid="split-orders-selection">
        <MenuItem key={`split-order-option-${order.order}`} value={order.order}>
          {order.orderName}
        </MenuItem>
      </Select>
    );
  }

  return (
    <Select value={order.order} onChange={handleChange} disabled={disabled} data-testid="split-orders-selection">
      {options.map((splitOrder) => (
        <MenuItem key={`split-order-option-${splitOrder.order}`} value={splitOrder.order}>
          {splitOrder.orderName}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SplitOrderSelectionCell;
