import axios from 'axios';

import { GENERATIVE, OUT_OF_STOCK } from '@/components/patient/enquiryModal/common';
import settings from '@/constants/constants';
import type { IDoctorAvailabilityResponse } from '@/hooks/patient/useGetPatientDoctorAvailability';
import type { PatientOrder } from '@/hooks/patient/useGetPatientOrders';
import type {
  LoginUser,
  Patient,
  PatientDiscount,
  PatientNoteData,
  PatientPrescription,
  PatientProduct,
  ShopifyProduct
} from '@/types';
import type { ArrayElement } from '@/utils/typscript-helpers';
import type { apiV1Contract } from '@montugroup/circuit-api-contracts';
import type { ClientInferResponseBody } from '@ts-rest/core';

const API_URL = `${settings.url}/patient`;

// eslint-disable-next-line no-shadow
export enum RescriptRequestReasons {
  Discontinued = `${GENERATIVE}`,
  OutOfStock = `${OUT_OF_STOCK}`
}

type GetReferralToggleDetails = {
  id: number;
  referral_code: string;
  active: boolean;
  is_mail_sent: boolean;
  patient_id: number;
  created_date: string;
  modified_date: string;
};

type SASIngredientDocument = ArrayElement<
  ClientInferResponseBody<(typeof apiV1Contract)['patient']['getPatient'], 200>['SASIngredients']
>;
type SASDocument = {
  sas_path: string;
  Product?: { name?: string };
  product_id?: number;
  state_path?: string;
} & Pick<
  SASIngredientDocument,
  | 'GeneralPractitioner'
  | 'created_date'
  | 'id'
  | 'patient_id'
  | 'active'
  | 'User'
  | 'expiry_date'
  | 'expiry_date_tz'
  | 'patient_script'
  | 'patient_sas_approval'
  | 'patient_s8_approval'
  | 'upload_date'
  | 'upload_date_tz'
  | 'modified_by'
  | 'general_practitioner_id'
  | 'created_by'
  | 'modified_date'
>;

export type PatientData = Omit<
  Patient,
  | 'client_code'
  | 'xero_contact_id'
  | 'modified_by'
  | 'medicare_number'
  | 'dob'
  | 'is_new_patient'
  | 'is_concession_card_holder'
  | 'created_date'
  | 'modified_date'
> &
  Omit<LoginUser, 'id' | 'active' | 'zip_code'> & {
    shopify_customer_id: number;
    country_name: string;
    state: string;
    postal_code: string;
    SASIngredients: SASIngredientDocument[];
    sas: SASDocument[];
    pharmacy: string;
    pharmaddress: string;
    pharmzipcode: string;
    pharmsuburb: string;
    patientDiscounts: PatientDiscount[];
    has_valid_concession_card: boolean;
    products: PatientProduct[];
    shopifyProducts: ShopifyProduct[];
    prescriptions: PatientPrescription[];
    notes: PatientNoteData[];
    getReferralToggleDetails: GetReferralToggleDetails;
  };

const getPatient = (patientId: string) => axios.get<PatientData>(`${API_URL}/${patientId}`);

export interface PatientRescriptRequestPayload {
  prescriptionId: number;
  productId: number;
  rescriptRequestReason: RescriptRequestReasons;
}

const createPatientRescriptRequest = async (patientId: number, data: PatientRescriptRequestPayload) => {
  const res = await axios.post(`${API_URL}/${patientId}/rescript-request`, data);

  return res.data;
};

export interface PatientOrderTrackingStatus {
  orderCode: string;
  orderDate: string;
  orderTimezone: string;
  trackingId: string | null;
  carrier: string | null;
  orderStatus: number;
}

export const getPatientOrderTrackingStatus = async () => {
  const result = await axios.get(`${API_URL}/orders/track-last-shipped-order`);
  return result.data;
};

const getPatientOrders = () => axios.get<PatientOrder[]>(`${API_URL}/orders`);

const getPatientOrderDetails = (orderId: string | number) => axios.get(`${API_URL}/orderDetail/${orderId}`);

const getPatientDoctorAvailability = (patientId?: string | number) =>
  axios.get<IDoctorAvailabilityResponse>(`${API_URL}/currentDoctorsAvailability/${patientId}`);

const getPatientConsultations = (patientId?: string | number) => axios.get(`${API_URL}/consultations/${patientId}`);

type PatientNotePayload = {
  patient_id?: string;
  note: string;
};
const postPatientNote = async ({ patient_id, note }: PatientNotePayload) => {
  const response = await axios.post(`${API_URL}/note`, { patient_id, note });
  return response.data;
};

export const PatientService = {
  getPatient,
  createPatientRescriptRequest,
  getPatientConsultations,
  getPatientDoctorAvailability,
  getPatientOrders,
  getPatientOrderDetails,
  postPatientNote
};
