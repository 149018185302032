import { jsx as c, jsxs as G, Fragment as F } from "@emotion/react/jsx-runtime";
import { createElement as L } from "@emotion/react";
import H from "@mui/icons-material/Edit";
import { styled as W, TextField as Z, useTheme as $, Autocomplete as J, IconButton as Q, ListItem as k, Typography as X } from "@mui/material";
import Y from "@mui/material/InputAdornment";
import { useState as f, useEffect as w } from "react";
import R, { getGeocode as ee } from "use-places-autocomplete";
import { LoaderStatus as E, Loader as te } from "@googlemaps/js-api-loader";
let s = null;
function oe({ googlePlacesApiKey: r, onLoad: o }) {
  const [n, i] = f(!1), l = async () => {
    try {
      if (console.info("Initializing GooglePlaces"), i(!0), (s == null ? void 0 : s.status) === E.INITIALIZED && (console.info("GooglePlaces Autocomplete init", {
        status: s.status
      }), i(!1), o()), (s == null ? void 0 : s.status) === E.LOADING)
        return;
      s = new te({
        apiKey: r,
        version: "weekly"
      }), await s.importLibrary("places"), console.info("GooglePlaces initialized"), o();
    } catch (d) {
      d instanceof Error && console.error("Error initializing GooglePlaces", d), console.error("Unexpected error initializing GooglePlaces");
    } finally {
      i(!1);
    }
  };
  return w(() => {
    l();
  }, []), { loading: n };
}
const ne = (r) => r.reduce(
  (o, n) => {
    const [i] = n.types;
    switch (i) {
      case "locality":
        return { ...o, suburb: n.long_name };
      case "administrative_area_level_1":
        return { ...o, state: n.short_name };
      case "administrative_area_level_2":
        return { ...o, city: n.short_name };
      case "postal_code":
        return { ...o, postcode: n.long_name };
      case "route":
        return { ...o, address: n.long_name };
      case "street_number":
        return { ...o, street_number: n.long_name };
      case "subpremise":
        return { ...o, subpremise: n.long_name };
      default:
        return o;
    }
  },
  {
    address: "",
    suburb: "",
    city: "",
    state: "",
    postcode: "",
    street_number: "",
    subpremise: ""
  }
), re = W(Z, {
  shouldForwardProp: (r) => r !== "disableBorder"
})(({ disableBorder: r }) => ({
  ...r && {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none"
      }
    },
    "& .MuiOutlinedInput-input": {
      textDecoration: "underline",
      textDecorationThickness: "1px",
      textDecorationColor: "black",
      textUnderlineOffset: "3px"
    }
  }
})), C = {
  description: "",
  place_id: "manual-entry-option",
  structured_formatting: {
    main_text: "Can’t find address?",
    secondary_text: "Enter address manually",
    main_text_matched_substrings: [{ length: 0, offset: 0 }]
  },
  types: [""],
  matched_substrings: [],
  terms: []
}, ie = (r, o, n = void 0) => {
  const i = r.filter(
    (l) => l.description.toLowerCase().includes(o.inputValue.toLowerCase())
  );
  return n && !i.some((l) => l.place_id === "manual-entry-option") && i.unshift(C), i;
};
function me({
  name: r,
  value: o,
  label: n,
  shrinkLabel: i = void 0,
  className: l,
  disableClearable: d = !1,
  disableBorder: S = !1,
  manualAddressFn: u = void 0,
  editIcon: m = !1,
  style: T,
  id: M,
  onSelectedAddress: g,
  onReset: _,
  googlePlacesApiKey: z,
  variant: D = "outlined",
  error: h
}) {
  const [p, y] = f(null), [q, b] = f(!1), B = $(), {
    suggestions: { status: j, data: x },
    setValue: I,
    clearSuggestions: O,
    init: A
  } = R({
    debounce: 300,
    callbackName: "initMap",
    requestOptions: {
      componentRestrictions: { country: "au" },
      types: ["address"]
    },
    initOnMount: !1
  }), { loading: U } = oe({ onLoad: () => A(), googlePlacesApiKey: z }), v = x.length ? {} : { freeSolo: !0, autoSelect: !0 };
  w(() => {
    p && g(ne(p.address_components));
  }, [p]);
  const P = () => {
    O(), I(""), y(null), _ && _();
  }, V = async (e) => {
    if (!e) {
      P();
      return;
    }
    if (typeof e == "object" && e !== null && "place_id" in e && e.place_id === C.place_id)
      try {
        u == null || u();
        return;
      } catch (a) {
        console.log(`${a} failed to fire manual address func`);
      }
    const t = typeof e == "string" ? e : e.description;
    if (v.freeSolo) {
      g({
        address: t,
        suburb: "",
        city: "",
        state: "",
        postcode: "",
        street_number: "",
        subpremise: ""
      });
      return;
    }
    if (t)
      try {
        const a = await ee({ address: t });
        a.length > 0 && (P(), y(a[0])), O();
      } catch (a) {
        a instanceof Error && console.error("Error during Google Geocode API request", a), console.error("Unexpected error during Google Geocode API request");
      }
  }, K = () => {
    b(!0);
  }, N = () => {
    b(!1);
  };
  return /* @__PURE__ */ c("div", { children: /* @__PURE__ */ c(
    J,
    {
      "data-testid": "autocomplete",
      ...v,
      disabled: U,
      id: M,
      className: l,
      disableClearable: d,
      style: T,
      value: o,
      options: j === "OK" ? x : [],
      getOptionLabel: (e) => typeof e == "string" ? e : e.description,
      size: "small",
      filterOptions: u ? (e, t) => ie(e, t, u) : void 0,
      isOptionEqualToValue: (e, t) => typeof t == "string" ? e.structured_formatting.main_text === t : !1,
      onChange: (e, t) => {
        V(t);
      },
      renderInput: (e) => /* @__PURE__ */ c(
        re,
        {
          name: r,
          label: n,
          variant: D,
          onChange: (t) => {
            I(t.target.value);
          },
          ...m ? { onFocus: K, onBlur: N } : {},
          error: !!h,
          helperText: h,
          className: l,
          disableBorder: S,
          ...e,
          InputLabelProps: { ...e.InputLabelProps, shrink: i },
          InputProps: {
            ...e.InputProps,
            endAdornment: /* @__PURE__ */ G(F, { children: [
              e.InputProps.endAdornment,
              !q && m && /* @__PURE__ */ c(Y, { position: "end", children: /* @__PURE__ */ c(Q, { sx: { color: B.palette.secondary.dark, width: "16px", height: "16px" }, children: /* @__PURE__ */ c(H, {}) }) })
            ] })
          }
        }
      ),
      renderOption: (e, t) => u && t.place_id === "manual-entry-option" ? /* @__PURE__ */ L(
        k,
        {
          ...e,
          key: "manual-entry-option",
          id: "manual-entry-option",
          onClick: u,
          role: "option",
          tabIndex: 0
        },
        /* @__PURE__ */ G(
          X,
          {
            variant: "subtitle2",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "18px",
            onClick: u,
            children: [
              t.structured_formatting.main_text,
              " ",
              /* @__PURE__ */ c("b", { children: t.structured_formatting.secondary_text })
            ]
          }
        )
      ) : /* @__PURE__ */ L(k, { ...e, key: t.place_id, role: "option" }, t.description)
    }
  ) });
}
export {
  me as L
};
