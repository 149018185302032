import { jsxs as f, jsx as o } from "@emotion/react/jsx-runtime";
import { DialogContent as g, DialogContentText as a, DialogActions as l } from "@mui/material";
import p from "@mui/material/Dialog";
import s from "@mui/material/DialogTitle";
function u({ title: i, children: r, contentProps: t, actions: n, actionProps: m, ...e }) {
  return /* @__PURE__ */ f(p, { ...e, children: [
    typeof i == "string" ? /* @__PURE__ */ o(s, { children: i }) : i,
    /* @__PURE__ */ o(g, { ...t, children: typeof r == "string" ? /* @__PURE__ */ o(a, { children: r }) : r }),
    /* @__PURE__ */ o(l, { ...m, children: n })
  ] });
}
export {
  u as C
};
