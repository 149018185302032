import { Typography } from '@mui/material';
import eTokenExample from '../../../assets/images/eTokenExample.png';
import { ResponsiveDialog } from '../../../components/responsiveDialog/ResponsiveDialog';
import { StyledDialogContent, StyledDialogImage } from './TokenDialogStyles';

interface ITokenDialog {
  onClose: () => void;
  open: boolean;
}

export function TokenDialog({ onClose, open }: ITokenDialog) {
  return (
    <ResponsiveDialog open={open} onClose={onClose} title="Where to find your token" closeButton>
      <StyledDialogContent>
        <StyledDialogImage src={eTokenExample} alt="E token example" />
        <Typography marginTop={6} variant="body2">
          You may have received your electronic prescription via email or SMS. The script token is an 18-digit code that
          accompanies a QR code.
        </Typography>
        <Typography marginTop={6} variant="body2">
          You can also find the script token in the link to your prescription.
        </Typography>
      </StyledDialogContent>
    </ResponsiveDialog>
  );
}
