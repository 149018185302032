import { getTsCustomerPortalClient } from '@/api/customer-portal.client';

const client = getTsCustomerPortalClient();

export default function useGetZendeskToken() {
  const { data, isError, isLoading } = client.getZendeskToken.useQuery({
    queryKey: ['zendeskToken']
  });

  return {
    data: data?.body.data,
    isError,
    isLoading
  };
}
