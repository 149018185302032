import CopyToClipboard from '@/components/buttons/CopyToClipboard';
import { FF_ENABLE_PATIENT_PROFILE_UPLIFT } from '@/constants/featureFlags';
import { useErrorManagement } from '@/context/ErrorManagement';
import { useViewPatients } from '@/context/view-patients/ViewPatients';
import { useSearchPatients } from '@/hooks/admin/useSearchPatients';
import { useTableManualPagination } from '@/hooks/table/useTableManualPagination';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import type { SearchPatient } from '@/services/data.service';
import { UserService } from '@/services/user.service';
import type { PaginationModel } from '@montugroup/design-system';
import { PaginationVariant, Table, toast } from '@montugroup/design-system';
import { Email } from '@mui/icons-material';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { Box, IconButton, Chip as MuiChip, styled } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const sortFieldOverrides = {
  patient_name: 'PatientUser.first_name',
  phone: 'PatientUser.phone',
  email: 'PatientUser.email',
  state: 'PatientUser.State.name'
} as const;

const resendInvite = async (email: string) => {
  const response = await UserService.resendLoginInvite({ email });
  if (response.data.status === 200) {
    toast.success('Invite sent.');
  } else {
    toast.error('Failed to send invite.');
  }
};
const PatientDetailContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2)
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  flexGrow: 1
}));

const Chip = styled(MuiChip)(({ theme }) => ({
  margin: theme.spacing(1)
}));

// Workaround: Need to update chep color as per Figma once available
const chipColors = (status: string) => {
  switch (status) {
    case 'Inactive':
      return 'error';
    case 'Pending':
      return 'warning';
    case 'Concession':
      return 'secondary';
    case 'Active':
      return 'primary';
    default:
      return 'secondary';
  }
};

const TABLE_OFFSET = 176;

function PatientListTable() {
  const { enqueueError } = useErrorManagement();

  const { flags } = useFeatureFlags();
  const { filter } = useViewPatients();
  const {
    paginationModel: { page, pageSize },
    setPaginationModel,
    shouldResetPageIndex,
    setShouldResetPageIndex
  } = useTableManualPagination();
  const { sortingOrder } = useTableSortingOrder({ sortFieldOverrides });
  const { data, isLoading, error } = useSearchPatients({
    page,
    pageSize,
    sortingOrder,
    filter
  });
  const containerRef = useRef<HTMLElement>();
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (error) {
      enqueueError({ title: 'Something went wrong', body: (error as { message: string }).message || '' });
    }
  }, [enqueueError, error]);

  const columns: ColumnDef<SearchPatient>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        enableSorting: true
      },
      {
        accessorKey: 'patient_code',
        header: 'Patient ID',
        enableSorting: true,
        cell: ({ row }) => (
          <>
            <Link to={`/${flags[FF_ENABLE_PATIENT_PROFILE_UPLIFT] ? 'patient' : 'onboard'}/${row.original.id}`}>
              {row.original.patient_code}
            </Link>
            <CopyToClipboard textToCopy={row.original.patient_code} buttonVariant="icon" size="small" />
          </>
        )
      },
      {
        accessorKey: 'patient_name',
        header: 'Patient Details',
        enableSorting: true,
        cell: ({ row }) => (
          <Box>
            <Box>
              {row.original.first_name} {row.original.last_name}
            </Box>
            {row.original.email && (
              <PatientDetailContainer>
                <MailIcon color="action" />

                {row.original.email}
                <CopyToClipboard textToCopy={row.original.email} buttonVariant="icon" size="small" />
              </PatientDetailContainer>
            )}

            {row.original.phone && (
              <PatientDetailContainer>
                <PhoneIphoneIcon color="action" />
                {row.original.phone}

                <CopyToClipboard textToCopy={row.original.phone} buttonVariant="icon" size="small" />
              </PatientDetailContainer>
            )}
          </Box>
        )
      },
      {
        accessorKey: 'state',
        header: 'State',
        enableSorting: true
      },
      {
        accessorKey: 'gpname',
        header: 'Doctor',
        enableSorting: false
      },

      {
        accessorKey: 'total_prescriptions',
        header: 'Total prescriptions',
        enableSorting: false
      },
      {
        accessorKey: 'patient_status',
        header: 'Status',
        enableSorting: false,
        cell: ({ row }) => {
          return row.original.patient_status?.map((status: string) => (
            <Box key={status}>
              <Chip label={status} color={chipColors(status)} />
            </Box>
          ));
        }
      },

      {
        id: 'resend',
        header: 'Resend invite',
        enableSorting: false,
        cell: ({ row }) => {
          if (row.original.circuit_access === 'Non-PMS' || row.original.circuit_access === 'Not active') {
            return null;
          }
          return (
            <IconButton
              disabled={row.original.circuit_access === 'Active'}
              onClick={() => resendInvite(row.original.email)}
            >
              <Email aria-label="resend invite" />
            </IconButton>
          );
        }
      }
    ],
    [flags]
  );

  useEffect(() => {
    setShouldResetPageIndex(true);
  }, [filter, setShouldResetPageIndex]);

  const onPaginationModelChange = (changedPaginationModel: PaginationModel) => {
    setPaginationModel(changedPaginationModel);
    setShouldResetPageIndex(false);
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { top } = containerRef.current.getBoundingClientRect();
        setContainerHeight(window.innerHeight - TABLE_OFFSET - top);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <Table
        isLoading={isLoading}
        data={data?.patients ?? []}
        columns={columns}
        columnVisibility={{ id: false }}
        total={data?.count}
        maxHeight={containerHeight}
        showPagination
        manualPagination
        shouldResetPageIndex={shouldResetPageIndex}
        pageSize={pageSize}
        onPaginationModelChange={onPaginationModelChange}
        paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
        hasRowBackgroundColor={false}
        isScrollable
      />
    </Container>
  );
}

export default PatientListTable;
