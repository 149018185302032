import { jsxs as h, jsx as e } from "@emotion/react/jsx-runtime";
import { styled as d, Tooltip as S, Button as O, Grid as m, Box as u, List as L, ListItem as M, ListItemText as T, ListItemIcon as z, Checkbox as I, Typography as R } from "@mui/material";
import A from "@mui/icons-material/KeyboardArrowLeft";
import B from "@mui/icons-material/KeyboardArrowRight";
import w from "@mui/icons-material/KeyboardDoubleArrowLeft";
import K from "@mui/icons-material/KeyboardDoubleArrowRight";
const b = (t) => t.map((i) => i.id), F = (t, i) => t == null ? void 0 : t.filter((l) => b(i).indexOf(l.id) === -1), k = (t, i) => t == null ? void 0 : t.filter((l) => b(i).indexOf(l.id) !== -1), p = d(S)({
  ":has(.Mui-disabled)": {
    cursor: "not-allowed"
  }
}), f = d(O)(() => ({
  padding: 15,
  marginBottom: 15,
  minWidth: "inherit",
  "&:hover": {
    padding: "15px !important"
  }
}));
function j(t) {
  const {
    checkedItems: i,
    leftSideItems: l,
    rightSideItems: r,
    handleMoveAllLeftOnClick: c,
    handleMoveAllRightOnClick: a,
    handleMoveCheckedLeftOnClick: n,
    handleMoveCheckedRightOnClick: o
  } = t, s = k(i, l), g = k(i, r);
  return /* @__PURE__ */ h(m, { container: !0, direction: "column", alignItems: "center", children: [
    /* @__PURE__ */ e(p, { title: "Move all items to the right", placement: "right", children: /* @__PURE__ */ e(u, { component: "span", marginBottom: 10, children: /* @__PURE__ */ e(
      f,
      {
        variant: "outlined",
        size: "small",
        onClick: a,
        disabled: (l == null ? void 0 : l.length) === 0,
        "aria-label": "move all right",
        children: /* @__PURE__ */ e(K, { fontSize: "large" })
      }
    ) }) }),
    /* @__PURE__ */ e(p, { title: "Move checked items to the right", placement: "right", children: /* @__PURE__ */ e("span", { children: /* @__PURE__ */ e(
      f,
      {
        variant: "outlined",
        size: "small",
        onClick: o,
        disabled: (s == null ? void 0 : s.length) === 0,
        "aria-label": "move selected right",
        children: /* @__PURE__ */ e(B, { fontSize: "large" })
      }
    ) }) }),
    /* @__PURE__ */ e(p, { title: "Move checked items to the left", placement: "right", children: /* @__PURE__ */ e("span", { children: /* @__PURE__ */ e(
      f,
      {
        variant: "outlined",
        size: "small",
        onClick: n,
        disabled: (g == null ? void 0 : g.length) === 0,
        "aria-label": "move selected left",
        children: /* @__PURE__ */ e(A, { fontSize: "large" })
      }
    ) }) }),
    /* @__PURE__ */ e(p, { title: "Move all items to the left", placement: "right", children: /* @__PURE__ */ e(u, { component: "span", marginTop: 10, children: /* @__PURE__ */ e(
      f,
      {
        variant: "outlined",
        size: "large",
        onClick: c,
        disabled: (r == null ? void 0 : r.length) === 0,
        "aria-label": "move all left",
        children: /* @__PURE__ */ e(w, { fontSize: "large" })
      }
    ) }) })
  ] });
}
const D = d(L)({
  height: "70vh",
  overflow: "scroll",
  overflowX: "hidden"
}), H = d(M)(({ theme: t }) => ({
  cursor: "pointer",
  marginBottom: 3,
  ".MuiCheckbox-root": {
    padding: 0
  },
  ":has(.Mui-checked)": {
    backgroundColor: t.palette.grey[300]
  },
  "&:hover": {
    background: t.palette.grey[300]
  }
})), $ = d(T)({
  "> span": {
    fontSize: "1.3rem",
    lineHeight: 1.43
  }
});
function C(t) {
  const { listItems: i, checkedItems: l, handleItemOnClick: r, ariaLabel: c } = t, a = i == null ? void 0 : i.sort(
    (n, o) => n.label > o.label ? 1 : o.label > n.label ? -1 : 0
  );
  return /* @__PURE__ */ h(D, { dense: !0, role: "list", "aria-label": c, children: [
    a == null ? void 0 : a.map((n) => {
      const o = b(l).indexOf(n.id) !== -1;
      return /* @__PURE__ */ h(H, { role: "listitem", onClick: () => r(n), children: [
        /* @__PURE__ */ e(z, { children: /* @__PURE__ */ e(
          I,
          {
            checked: o,
            tabIndex: -1,
            disableRipple: !0,
            inputProps: {
              "aria-labelledby": n.label
            },
            "data-testid": `checkbox-${n.id}-${o}`
          }
        ) }),
        /* @__PURE__ */ e($, { id: n.label, primary: n.label })
      ] }, n.id);
    }),
    /* @__PURE__ */ e(M, {})
  ] });
}
const y = d(u)(({ theme: t }) => ({
  padding: 20,
  borderRadius: 5,
  backgroundColor: t.palette.grey[200]
})), x = d(R)({
  textAlign: "center",
  marginBottom: 10
});
function J(t) {
  const {
    headingLeftSide: i,
    headingRightSide: l,
    checkedItems: r,
    leftSideItems: c,
    rightSideItems: a,
    handleMoveAllLeftOnClick: n,
    handleMoveAllRightOnClick: o,
    handleMoveCheckedLeftOnClick: s,
    handleMoveCheckedRightOnClick: g,
    handleItemOnClick: v
  } = t;
  return /* @__PURE__ */ h(m, { container: !0, my: 5, children: [
    /* @__PURE__ */ h(m, { item: !0, xs: 5, children: [
      /* @__PURE__ */ e(x, { variant: "h6", children: i }),
      /* @__PURE__ */ e(y, { children: /* @__PURE__ */ e(
        C,
        {
          listItems: c,
          checkedItems: r,
          handleItemOnClick: v,
          ariaLabel: "left-side-list"
        }
      ) })
    ] }),
    /* @__PURE__ */ e(m, { item: !0, xs: 2, paddingTop: 10, children: /* @__PURE__ */ e(
      j,
      {
        checkedItems: r,
        leftSideItems: c,
        rightSideItems: a,
        handleMoveAllLeftOnClick: n,
        handleMoveAllRightOnClick: o,
        handleMoveCheckedLeftOnClick: s,
        handleMoveCheckedRightOnClick: g
      }
    ) }),
    /* @__PURE__ */ h(m, { item: !0, xs: 5, children: [
      /* @__PURE__ */ e(x, { variant: "h6", children: l }),
      /* @__PURE__ */ e(y, { children: /* @__PURE__ */ e(
        C,
        {
          listItems: a,
          checkedItems: r,
          handleItemOnClick: v,
          ariaLabel: "right-side-list"
        }
      ) })
    ] })
  ] });
}
export {
  J as T,
  k as f,
  b as g,
  F as r
};
