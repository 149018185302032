import { Link, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type PharmacyTableCellProps = {
  name: string;
  code: string;
  id?: number;
};

export function PharmacyTableCell({ name, code, id }: PharmacyTableCellProps) {
  if (!id) {
    return <>-</>;
  }
  return (
    <Tooltip title={name}>
      <Link component={RouterLink} to={`/pharmacy-account/${id}`}>
        <span>{code}</span>
      </Link>
    </Tooltip>
  );
}
