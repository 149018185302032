import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

export interface SubmitEmailData {
  email: string;
}

export interface CustomerEmailInputProps {
  handleEmailSubmit: (formData: SubmitEmailData) => void;
}

export function CustomerEmailInput({ handleEmailSubmit }: CustomerEmailInputProps) {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<SubmitEmailData>({
    defaultValues: {
      email: ''
    }
  });

  return (
    <form onSubmit={handleSubmit(handleEmailSubmit)}>
      <Controller
        name="email"
        control={control}
        rules={{ required: 'Please enter your email' }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            type="text"
            variant="outlined"
            value={value}
            inputRef={ref}
            onChange={onChange}
            onBlur={onBlur}
            label="Email"
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
            fullWidth
          />
        )}
      />

      <Button type="submit" variant="contained" color="primary" fullWidth size="large" sx={{ marginTop: 4 }}>
        Send invite
      </Button>
    </form>
  );
}
