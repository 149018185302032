import { PatientService } from '@/services/patient.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

type PatientNotePayload = {
  patient_id?: string;
  note: string;
};

export const usePostPatientNote = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (payload: PatientNotePayload) => PatientService.postPatientNote(payload),
    onSuccess: (_, payload: PatientNotePayload) => {
      queryClient.invalidateQueries({
        queryKey: ['patient', payload.patient_id]
      });
    },
    onError: () => {
      toast.error('Error while updating Patient note');
    }
  });
  return mutation;
};
