import type { alternaleaf, leafio, uMeds } from '@montugroup/themes';
import { BrandThemeProvider, montu } from '@montugroup/themes';
import { Box, Container, Divider, Stack, Typography, styled } from '@mui/material';
import type { PropsWithChildren, ReactNode } from 'react';

const ContentLayout = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  margin: `0 ${theme.spacing(6)}`,
  minHeight: '100%'
}));

const HeadingDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

const InnerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export type AdminPageProps = {
  title: string;
  themeVariant?: typeof alternaleaf | typeof uMeds | typeof montu | typeof leafio | undefined;
  fullWidth?: boolean;
  subtitle?: ReactNode;
  TitleAdornment?: ReactNode;
  Actions?: ReactNode;
};

function AdminPageLayout({
  title,
  themeVariant = montu,
  fullWidth = true,
  children,
  subtitle,
  TitleAdornment,
  Actions
}: PropsWithChildren<AdminPageProps>) {
  return (
    <BrandThemeProvider theme={themeVariant}>
      <Container
        maxWidth={fullWidth ? false : undefined}
        disableGutters={true}
        sx={{ maxWidth: fullWidth ? '100%' : 1100 }}
      >
        <ContentLayout>
          <InnerContainer>
            <Stack gap={2}>
              <Stack gap={4} direction="row">
                <Typography component="h2" variant="h5">
                  {title}
                </Typography>
                {TitleAdornment && <div>{TitleAdornment}</div>}
              </Stack>

              {subtitle && (
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} color={(theme) => theme.palette.primary.main}>
                  {subtitle}
                </Typography>
              )}
            </Stack>
            {Actions && <div>{Actions}</div>}
          </InnerContainer>

          <HeadingDivider />
          {children}
        </ContentLayout>
      </Container>
    </BrandThemeProvider>
  );
}

export default AdminPageLayout;
