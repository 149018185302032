import axios from 'axios';

import type { GENERATIVE, OUT_OF_STOCK, ProductEnquiryStatus } from '@/components/patient/enquiryModal/common';
import type { PatientProductRating, Product } from '@/components/products/detail/types';
import settings from '@/constants/constants';

const fetchProductEnquiryStatus = async (
  patientId?: number,
  prescriptionId?: number,
  productId?: number,
  rescriptRequestReason?: typeof GENERATIVE | typeof OUT_OF_STOCK
) => {
  const result = await axios<ProductEnquiryStatus>(
    `${settings.url}/patient/${patientId}/product-enquiry-status?prescriptionId=${prescriptionId}&productId=${productId}&rescriptRequestReason=${rescriptRequestReason}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  );

  return result.data;
};

const productListing = (active: boolean, excelData: boolean) =>
  axios.get(`${settings.url}/product?active=${active}`, { params: { excelData } });

const productCatalog = (excludeDiscontinuedProducts = false) =>
  axios.get(`${settings.url}/product/catalog?excludeDiscontinuedProducts=${excludeDiscontinuedProducts === true}`);

type IProductResponse = {
  product?: Product | null;
  user_ratings?: PatientProductRating | null;
  product_rating: string;
};

const getProductOffering = (id: string) => axios.get<IProductResponse>(`${settings.url}/product/offering/${id}`);

const submitPatientProductRating = (data: unknown) => axios.post(`${settings.url}/product/rating`, data);

const getProductEdit = (id: number | string) => axios.get(`${settings.url}/product/edit?id=${id}`);

const createProduct = (body: unknown) =>
  axios.post(`${settings.url}/product`, body, { headers: { 'Content-Type': 'multipart/form-data' } });

const getApprovalDropdowns = () => axios.get(`${settings.url}/product/approvalDropdown`);

const getPrescriptionRange = () => axios.get(`${settings.url}/product/prescriptionRange/`);

const postPrescriptionRange = (body: unknown) => axios.post(`${settings.url}/product/prescriptionRange/`, body);

const getProducts = (gpId: number) => axios.get(`${settings.url}/product/${gpId}`);

const getSupplierRelatedProducts = (supplierId: number) =>
  axios.get(`${settings.url}/product/getBySupplier/${supplierId}`);

const setAlternativeProductsForId = (productId: number, data: unknown) =>
  axios.post(`${settings.url}/product/setAlternativeProducts/${productId}`, data);

const getAvailableProductsList = () => axios.get(`${settings.url}/product/availableProductsList`);

const getAlternativeProducts = (productId: number | string) =>
  axios.get(`${settings.url}/product/alternativeProducts/${productId}`);

export {
  createProduct,
  fetchProductEnquiryStatus,
  getAlternativeProducts,
  getApprovalDropdowns,
  getAvailableProductsList,
  getPrescriptionRange,
  getProductEdit,
  getProductOffering,
  getProducts,
  getSupplierRelatedProducts,
  postPrescriptionRange,
  productCatalog,
  productListing,
  setAlternativeProductsForId,
  submitPatientProductRating
};
