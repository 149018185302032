import { Button, Typography } from '@mui/material';
import { usePreOnboardingContext } from '../hooks/usePreOnboardingContext';

export const OutOfStock = () => {
  const { isExistingUser, setIsExistingUser, setPreOnboardingState } = usePreOnboardingContext();

  return (
    <>
      <Typography variant="subtitle2" fontWeight={600}>
        Sorry, we are unable to fill your script.
      </Typography>
      <Typography component="p">While we do sell your medication, it is currently out of stock.</Typography>
      <Typography component="p">
        {isExistingUser
          ? 'You can login and check again later when it is back in stock.'
          : ' You can sign up and check again later when it is back in stock, or you can submit another script.'}
      </Typography>
      {isExistingUser ? (
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {/* todo: once login portal page is confirmed, add router link */}
          Login
        </Button>
      ) : (
        <>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            {/* todo: once sign up page is confirmed, add router link */}
            Sign up
          </Button>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => setPreOnboardingState('submitToken')}
          >
            Submit another script
          </Button>
        </>
      )}

      {/* Test button just demoing, todo: remove */}
      <Button
        variant="outlined"
        onClick={() => setIsExistingUser(!isExistingUser)}
        sx={{ position: 'absolute', bottom: '-100px' }}
      >
        Test: Set isExistingUser to {`${!isExistingUser}`}
      </Button>
    </>
  );
};
