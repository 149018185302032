import useCustomer from '@/hooks/user/useCustomer';
import type { AlertColor } from '@mui/material';
import { Button, Grid, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import { useErrorManagement } from '@/context/ErrorManagement';
import { AlertWithClose } from '../components/Alerts/AlertWithClose';
import { InfoItem } from './InfoItem';
import {
  StyleSubHeading,
  StyledGridItem,
  StyledHeading,
  StyledTokenInputContainer,
  StyledTopHeading,
  StyledWrapper
} from './LandingStyles';
import { PreOnboardingFlowContainer } from './PreOnboardingFlowContainer';
import ApprovalIcon from './TempLandingIcons/ApprovalIcon';
import CheckIcon from './TempLandingIcons/CheckIcon';
import DocumentsIcon from './TempLandingIcons/DocumentsIcon';
import OrdersIcon from './TempLandingIcons/OrderIcon';
import ScriptsIcon from './TempLandingIcons/ScriptsIcon';
import { PreOnboardingStateProvider } from './context/PreOnboardingContext';

export interface SubmitTokenData {
  token: string;
  agreement: boolean;
}

export function Landing() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openTokenDialog, setOpenTokenDialog] = useState<boolean>(false);
  const { data: customer } = useCustomer();
  const { enqueueError } = useErrorManagement();
  // temp example of hook usage
  // eslint-disable-next-line no-console
  console.log('isExternal', customer?.isExternal);

  /* Temp demo of inline  alerts */
  // todo: move to context or a hook
  const [alert, setAlert] = useState<{
    show: boolean;
    severity: AlertColor | undefined;
    title?: string;
    content: string;
  }>({
    show: false,
    severity: 'success',
    title: '',
    content: ''
  });

  /* Temp demo of app level alerts on mount */
  // todo: move to context or a hook
  useEffect(() => {
    enqueueError({ title: 'Something went wrong', body: 'There is no backend yet so this is an example toast error' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ManagedErrorToasts />
      {alert.show ? <AlertWithClose alert={alert} setAlert={setAlert} /> : null}

      <PreOnboardingStateProvider>
        <StyledWrapper>
          <Grid container>
            <StyledGridItem item xs={12} md={6}>
              <StyledTopHeading variant="h3" fontWeight={600}>
                Medication at your fingertips
              </StyledTopHeading>
              <Typography variant="subtitle2" my={6}>
                Submit your prescription and let us handle the rest.
              </Typography>
              {/* Test button just demoing, todo: remove */}
              <Button
                variant="outlined"
                onClick={() =>
                  setAlert({
                    show: true,
                    severity: 'success',
                    title: 'Alert title',
                    content: 'Succesfull alert content! Close this alert'
                  })
                }
              >
                Test alert
              </Button>
              {isMobile && (
                <StyledTokenInputContainer>
                  <Stack spacing={4} height="100%">
                    <PreOnboardingFlowContainer
                      setOpenTokenDialog={setOpenTokenDialog}
                      openTokenDialog={openTokenDialog}
                    />
                  </Stack>
                </StyledTokenInputContainer>
              )}

              <Typography
                variant="h3"
                fontWeight={600}
                marginTop={{ xs: 0, md: 150 }}
                marginBottom={{ xs: 8.5, md: 12 }}
              >
                How it works
              </Typography>

              <Stack spacing={8}>
                <InfoItem
                  icon={<ScriptsIcon />}
                  title="Prepare your documents"
                  description={
                    <>
                      To order your medication with uMeds, we’ll need your{' '}
                      {/* todo: find a better way to have semantic elements (button/link) work in this context, re hover state */}
                      <Link
                        component="button"
                        color="inherit"
                        onClick={() => setOpenTokenDialog(true)}
                        sx={{
                          '&.MuiTypography-root:hover': {
                            color: `${theme.palette.common.black}`
                          }
                        }}
                      >
                        eScript token
                      </Link>{' '}
                      and{' '}
                      <Link
                        color="inherit"
                        href="https://support.umeds.com.au/hc/en-au/articles/10869503095695-Regulations-and-approvals"
                      >
                        SAS/AP approval letter
                      </Link>
                      .
                    </>
                  }
                />

                <InfoItem
                  icon={<DocumentsIcon />}
                  title="We review your script"
                  description={
                    <>
                      We’ll check if we have your prescribed medication in stock. If we do, we’ll guide you to sign up.
                      If not, we’ll let you know why—keeping you informed at every step.
                    </>
                  }
                />

                <InfoItem
                  icon={<ApprovalIcon />}
                  title="Upload your approval letter"
                  description={
                    <>Provide the SAS/AP approval letter from your doctor so that we can dispense your medication. </>
                  }
                />

                <InfoItem
                  icon={<OrdersIcon />}
                  title="Place your order"
                  description={
                    <>
                      Purchase your medication in just a few clicks. We will deliver it to your door, quickly and
                      securely.
                    </>
                  }
                />
              </Stack>

              <StyledHeading variant="h3" fontWeight={600}>
                Why uMeds?
              </StyledHeading>
              <StyleSubHeading variant="h5" fontWeight={600}>
                We streamline your prescription delivery.
              </StyleSubHeading>

              <Stack spacing={6}>
                <InfoItem
                  icon={<CheckIcon />}
                  iconSize={20}
                  description={<>Your prescriptions stored in one place.</>}
                />

                <InfoItem
                  icon={<CheckIcon />}
                  iconSize={20}
                  description={<>Your medication dispensed by trusted pharmacies anywhere in Australia.</>}
                />

                <InfoItem icon={<CheckIcon />} iconSize={20} description={<>Your orders delivered discreetly.</>} />

                <InfoItem
                  icon={<CheckIcon />}
                  iconSize={20}
                  description={<>Your reassurance beyond technical support.</>}
                />
              </Stack>
            </StyledGridItem>

            {!isMobile && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start'
                }}
              >
                <StyledTokenInputContainer>
                  <Stack spacing={6} height="100%">
                    <PreOnboardingFlowContainer
                      setOpenTokenDialog={setOpenTokenDialog}
                      openTokenDialog={openTokenDialog}
                    />
                  </Stack>
                </StyledTokenInputContainer>
              </Grid>
            )}
          </Grid>
        </StyledWrapper>
      </PreOnboardingStateProvider>
    </>
  );
}
