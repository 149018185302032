import CallSplit from '@mui/icons-material/CallSplit';
import FileCopy from '@mui/icons-material/FileCopy';
import { Box, IconButton, Tooltip, styled } from '@mui/material';

import settings from '@/constants/constants';
import { useSplitOrderModal } from '@/context/orders/OrdersModals';
import type { Order } from '@/services/data.service';
import { useNavigate } from 'react-router-dom';

type ActionTableCellProps = {
  order: Order;
  isDoc: boolean;
};

const GreyIconButton = styled(IconButton)(({ theme }) => ({
  '&:not(:disabled)': {
    color: theme.palette.grey[600],
    '& :hover': {
      fill: theme.palette.grey[800]
    }
  },
  '&:disabled': {
    fill: theme.palette.grey[200]
  }
}));

function ActionsTableCell({ order, isDoc }: ActionTableCellProps) {
  const { showSplitOrderModal } = useSplitOrderModal();
  const navigate = useNavigate();

  const handleRefillOrder = () => {
    navigate(`/order/${order.id}/refill`);
  };

  const canSplitOrder = () => {
    if (order.payment_status === 'INVOICED') {
      return false;
    }

    const allowedOrderStatuses = [
      settings.orderStatus.OUT_OF_REPEATS,
      settings.orderStatus.PHARMACY_OUT_OF_STOCK,
      settings.orderStatus.INTERVAL_TIME,
      settings.orderStatus.PHARMACY_PARTIALLY_OUT_OF_STOCK,
      settings.orderStatus.UNABLE_TO_DISPENSE
    ];

    const notAllowedOrderStatuses = [
      settings.orderStatus.CANCELLED,
      settings.orderStatus.PENDING,
      settings.orderStatus.TEST,
      settings.orderStatus.ERROR,
      settings.orderStatus.EXPIRED,
      settings.orderStatus.PMS_DELETED,
      settings.orderStatus.PHARMACY_DISPENSED,
      settings.orderStatus.PHARMACY_COMPLETED,
      settings.orderStatus.PHARMACY_PENDING,
      settings.orderStatus.PHARMACY_TEST,
      settings.orderStatus.NO_PHARMACY,
      settings.orderStatus.RE_SCRIPT,
      settings.orderStatus.AWAITING_TOKEN
    ];

    return (
      allowedOrderStatuses.includes(order.order_status_id) || !notAllowedOrderStatuses.includes(order.order_status_id)
    );
  };

  return (
    <Box display="flex" flexDirection="row">
      <Tooltip title="Duplicate order" arrow>
        <GreyIconButton
          size="small"
          disabled={order.is_discharge}
          onClick={handleRefillOrder}
          data-testid="refill-button"
        >
          <FileCopy />
        </GreyIconButton>
      </Tooltip>
      {!isDoc && (
        <Tooltip title="Split order">
          <GreyIconButton
            size="small"
            disabled={!canSplitOrder()}
            onClick={() => showSplitOrderModal(order)}
            data-testid="split-button"
          >
            <CallSplit />
          </GreyIconButton>
        </Tooltip>
      )}
    </Box>
  );
}

export default ActionsTableCell;
