import { jsx as r, jsxs as e } from "@emotion/react/jsx-runtime";
import { InfoOutlined as f, CheckCircleOutlineOutlined as p, ReportProblemOutlined as u, ErrorOutlineOutlined as g } from "@mui/icons-material";
import { styled as y, Box as i, Typography as t } from "@mui/material";
import { toast as s, ToastContainer as d } from "react-toastify";
const v = y(i)(({ theme: o }) => ({
  "--toastify-color-info": (o.vars || o).palette.info.main,
  "--toastify-color-success": (o.vars || o).palette.success.main,
  "--toastify-color-warning": (o.vars || o).palette.warning.main,
  "--toastify-color-error": (o.vars || o).palette.error.main,
  "--toastify-color-progress-info": (o.vars || o).palette.info.main,
  "--toastify-color-progress-success": (o.vars || o).palette.success.main,
  "--toastify-color-progress-warning": (o.vars || o).palette.warning.main,
  "--toastify-color-progress-error": (o.vars || o).palette.error.main
})), w = ({ title: o, message: a }) => /* @__PURE__ */ e(i, { children: [
  o && /* @__PURE__ */ r(t, { component: "h2", variant: "body1", children: o }),
  /* @__PURE__ */ r(t, { variant: "body2", children: a })
] }), T = {
  info: /* @__PURE__ */ r(f, { color: "info" }),
  success: /* @__PURE__ */ r(p, { color: "success" }),
  warning: /* @__PURE__ */ r(u, { color: "warning" }),
  error: /* @__PURE__ */ r(g, { color: "error" }),
  default: null
}, n = (o) => (a, c) => o(/* @__PURE__ */ r(w, { ...typeof a == "string" ? { message: a } : a }), {
  // apply the mui icons instead of the defaults
  icon: ({ type: l }) => T[l] || null,
  ...c
}), x = (o) => /* @__PURE__ */ r(v, { children: /* @__PURE__ */ r(d, { ...o }) }), j = Object.assign((o) => n(s)(o), s, {
  info: n(s.info),
  success: n(s.success),
  error: n(s.error),
  warning: n(s.warning),
  warn: n(s.warn)
});
export {
  x as T,
  j as t
};
