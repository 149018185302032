import BankIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Box, styled } from '@mui/material';

import EwayIcon from '@/components/order/EwayIcon';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: theme.spacing(12),
  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.spacing(32)
  },
  '& svg': {
    position: 'relative',
    bottom: theme.spacing(0.5),
    marginRight: theme.spacing(2)
  }
}));

type PaymentStatusTableCellProps = {
  paymentGateway: string;
  paymentStatus: string;
};

function PaymentStatusTableCell({ paymentGateway, paymentStatus }: PaymentStatusTableCellProps) {
  let icon;
  switch (paymentGateway) {
    case 'SHOPIFY_PAYMENTS':
      icon = <CreditCardIcon fontSize="small" />;
      break;
    case 'SHOPIFY_BANK_TRANSFER':
      icon = <BankIcon fontSize="small" />;
      break;
    case 'SHOPIFY_EWAY':
      icon = <EwayIcon />;
      break;
    default:
      return null;
  }

  return (
    <Container>
      {icon}
      <Box display="inline">{paymentStatus}</Box>
    </Container>
  );
}

export default PaymentStatusTableCell;
