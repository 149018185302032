import { Box, Stack, styled, Typography } from '@mui/material';
import type { PropsWithChildren, ReactNode } from 'react';

type ProfileSectionProps = {
  title: string;
  subheading?: ReactNode;
  Actions?: ReactNode;
};

const TitleSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between'
}));

const SubheadingContainer = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(4)} 0`
}));

export function ProfileSection({ title, subheading, Actions, children }: PropsWithChildren<ProfileSectionProps>) {
  return (
    <>
      <TitleSection>
        <Stack gap={2}>
          <Typography>{title}</Typography>
          {subheading && (
            <SubheadingContainer>
              <Typography variant="body2" color={(theme) => theme.palette.grey[500]}>
                {subheading}
              </Typography>
            </SubheadingContainer>
          )}
        </Stack>
        {Actions && <Box>{Actions}</Box>}
      </TitleSection>
      {children}
    </>
  );
}
