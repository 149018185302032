import { jsxs as u, Fragment as k, jsx as n } from "@emotion/react/jsx-runtime";
import { ArrowDropDown as C } from "@mui/icons-material";
import { MenuList as I, alpha as p, MenuItem as v } from "@mui/material";
import A from "@mui/material/Button";
import E from "@mui/material/ClickAwayListener";
import L from "@mui/material/Grow";
import O from "@mui/material/Paper";
import P from "@mui/material/Popper";
import { styled as R } from "@mui/material/styles";
import { useState as T, useRef as d, useEffect as j } from "react";
const B = R(I)(({ theme: t }) => ({
  "& .MuiMenuItem-root:not(:last-child)": {
    borderBottom: `1px solid ${p(t.palette.primary.main, 0.5)}`
  },
  "& .MuiMenuItem-root:hover": {
    backgroundColor: p(t.palette.primary.main, 0.1)
  }
}));
function N({
  buttonLabel: t,
  menuItems: f,
  handleClickMenuItem: l,
  placement: m = "bottom-end",
  variant: h = "outlined",
  color: y = "primary",
  sx: b
}) {
  const [e, i] = T(!1), a = d(null), x = () => {
    i((r) => !r);
  }, s = (r) => {
    a.current && a.current.contains(r.target) || i(!1);
  }, M = (r, o) => {
    l && (s(r), l(o));
  };
  function g(r) {
    r.key === "Tab" ? (r.preventDefault(), i(!1)) : r.key === "Escape" && i(!1);
  }
  const c = d(e);
  return j(() => {
    c.current === !0 && e === !1 && a.current.focus(), c.current = e;
  }, [e]), /* @__PURE__ */ u(k, { children: [
    /* @__PURE__ */ u(
      A,
      {
        sx: { textTransform: "unset", paddingRight: "0.5rem", ...b },
        ref: a,
        "aria-expanded": e ? "true" : void 0,
        "aria-haspopup": "true",
        "aria-label": t,
        onClick: x,
        variant: h,
        color: y,
        children: [
          t,
          /* @__PURE__ */ n(C, {})
        ]
      }
    ),
    /* @__PURE__ */ n(
      P,
      {
        sx: { zIndex: 100 },
        open: e,
        anchorEl: a.current,
        placement: m,
        transition: !0,
        disablePortal: !0,
        children: ({ TransitionProps: r }) => /* @__PURE__ */ n(
          L,
          {
            ...r,
            style: {
              transformOrigin: "left top"
            },
            children: /* @__PURE__ */ n(O, { children: /* @__PURE__ */ n(E, { onClickAway: s, children: /* @__PURE__ */ n(
              B,
              {
                autoFocusItem: e,
                id: "composition-menu",
                "aria-labelledby": "composition-button",
                onKeyDown: g,
                children: f.map((o, w) => /* @__PURE__ */ n(
                  v,
                  {
                    disabled: o.isDisabled,
                    onClick: (D) => M(D, o.value ?? ""),
                    children: (o == null ? void 0 : o.children) ?? (o == null ? void 0 : o.label)
                  },
                  w
                ))
              }
            ) }) })
          }
        )
      }
    )
  ] });
}
export {
  N as D
};
