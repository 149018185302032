import { ProfileSection } from '@/components/admin/patient-profile/ProfileSection';
import Note from '@/components/note/Note';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import { usePostPatientNote } from '@/hooks/admin/usePostPatientNote';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const PatientNote = () => {
  const [isAddingNote, setIsAddingNote] = useState(false);
  const { id } = useParams();

  const { data: patientData } = useGetPatient(id ?? '');

  const patientNote = patientData?.data.notes ?? [];

  const { reset } = useForm<{ note: string }>();

  const onNoteCancel = () => {
    reset({ note: '' });
    setIsAddingNote(false);
  };

  const mutation = usePostPatientNote();

  const onNoteSubmit = (_data: { note: string }) => {
    mutation.mutate(
      { patient_id: id, note: _data.note },

      {
        onSuccess: () => {
          setIsAddingNote(false);
          reset({ note: '' });
        }
      }
    );
  };

  if (!patientData) {
    return (
      <ProfileSection title="Patient Notes" Actions={<Skeleton width={200} height={38} variant="rectangular" />}>
        <Skeleton width="100%" height={100} variant="rectangular" />
      </ProfileSection>
    );
  }

  return (
    <Note
      key={isAddingNote ? 'adding' : 'viewing'}
      title="Patient Notes"
      isAddingNote={isAddingNote}
      setIsAddingNote={setIsAddingNote}
      onNoteSubmit={onNoteSubmit}
      onNoteCancel={onNoteCancel}
      notes={patientNote}
      enableAuthor
      enableDate
      label={'Creating a new patient note'}
    />
  );
};

export { PatientNote };
