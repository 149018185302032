import { FF_ENABLE_CONCESSION_AWARENESS_CHECKOUT } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { Box, Button, Divider, Skeleton, Typography, styled } from '@mui/material';
import type { ReactNode } from 'react';

type Discount = {
  id: string;
  display: string;
  amount: number | string;
  formattedAmount: string;
};

type Product = {
  id: number;
  name: string;
  price: number;
  quantity: number;
  formattedTotalPrice: string;
  supplier: string;
  isConcession: boolean;
  discount?: Discount;
};

type CheckoutData = {
  totalNumberOfProducts: number;
  totalPrice: number;
  formattedTotalPrice: string;
  products: Product[];
  subTotalPrice?: number;
  shippingCalculationNote?: string;
};

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  backgroundColor: theme.palette.grey[100],
  border: '1px solid',
  borderColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius * 2
}));

const OrderDetails = styled('dl')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 0
}));

const LineItemRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(2)
}));

const DiscountText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  textAlign: 'right',
  paddingTop: theme.spacing(2)
}));

const OrderDisclaimer = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  color: theme.palette.text.primary
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  marginTop: theme.spacing(5)
}));

const TermsSection = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(3)
}));

const DEFAULT_SHIPPING_CALCULATION_NOTE = 'Shipping fees calculated at checkout.';
const DEFAULT_CONCESSION_DISCOUNT_TEXT = 'Concession discount included';

interface LineItemProps {
  label: string;
  amount: number | string;
  isTotal?: boolean;
  isDiscount?: boolean;
}

interface ProductLineItemProps {
  quantity: number;
  supplier: string;
  label: string;
  amount: string;
  isConcession: boolean;
  discount?: Discount;
}

function ProductLineItem(props: ProductLineItemProps) {
  const { flags } = useFeatureFlags();
  const { label, amount, quantity, supplier, isConcession, discount } = props;

  return (
    <Box>
      <LineItemRoot>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            {label}
          </Typography>
          <Typography variant="body2">{supplier}</Typography>
          <Typography variant="body2">
            Qty:{' '}
            <Typography component="span" variant="inherit" sx={{ fontWeight: 600 }}>
              {quantity}
            </Typography>
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={(theme) => ({
            fontWeight: 600,
            color: theme.palette.text.primary,
            marginLeft: theme.spacing(4)
          })}
        >
          {amount}
        </Typography>
      </LineItemRoot>
      {discount && <LineItem label={discount.display} amount={discount.formattedAmount} isDiscount />}
      {flags[FF_ENABLE_CONCESSION_AWARENESS_CHECKOUT] && isConcession && (
        <DiscountText variant="body2">{DEFAULT_CONCESSION_DISCOUNT_TEXT}</DiscountText>
      )}
      <SectionDivider />
    </Box>
  );
}

type TitleProps = {
  children: ReactNode;
};

function Title(props: TitleProps) {
  return (
    <Typography variant="subtitle2" component="h3" sx={{ fontWeight: 600 }}>
      {props.children}
    </Typography>
  );
}

function SectionDivider() {
  return <Divider sx={{ my: 3 }} />;
}

export function LineItem(props: LineItemProps) {
  const { label, amount, isTotal, isDiscount } = props;

  const variant = isTotal ? 'subtitle2' : 'body2';

  return (
    <LineItemRoot>
      <Typography variant={variant}>{label}</Typography>
      <Typography
        variant={variant}
        sx={(theme) => ({
          fontWeight: isDiscount ? 400 : 600,
          color: isDiscount ? theme.palette.success.main : theme.palette.text.primary,
          marginLeft: theme.spacing(4)
        })}
      >
        {amount}
      </Typography>
    </LineItemRoot>
  );
}

export type OrderSummaryV2Props = {
  checkoutData: CheckoutData;
  handleCheckout: () => void;
  disabled?: boolean;
  slots?: {
    terms?: ReactNode;
  };
};

export function OrderSummaryV2(props: OrderSummaryV2Props) {
  const {
    checkoutData: { formattedTotalPrice, totalNumberOfProducts, products },
    handleCheckout,
    disabled,
    slots
  } = props;

  const hasAnyProducts = totalNumberOfProducts > 0;

  return (
    <Box>
      <Wrapper>
        <Title>Summary</Title>
        <SectionDivider />
        <OrderDetails>
          {hasAnyProducts
            ? products.map((product) => (
                <ProductLineItem
                  key={product.id}
                  label={product.name}
                  amount={product.formattedTotalPrice}
                  quantity={product.quantity}
                  supplier={product.supplier}
                  isConcession={product.isConcession}
                  discount={product.discount}
                />
              ))
            : null}
        </OrderDetails>
        <OrderDisclaimer variant="caption">{DEFAULT_SHIPPING_CALCULATION_NOTE}</OrderDisclaimer>
        <LineItem isTotal label="Total*" amount={formattedTotalPrice} />
        {slots?.terms ? <TermsSection>{slots.terms}</TermsSection> : null}
      </Wrapper>
      <ButtonContainer>
        <Button onClick={handleCheckout} color="primary" variant="contained" size="large" disabled={disabled}>
          Proceed
        </Button>
      </ButtonContainer>
    </Box>
  );
}

export function OrderSummaryV2Skeleton() {
  return (
    <Box>
      <Wrapper data-testid="order-summary-skeleton">
        <Typography variant="subtitle2" component="h3" sx={{ fontWeight: 600 }}>
          Summary
        </Typography>
        <SectionDivider />
        <OrderDetails>
          <LineItemRoot>
            <Skeleton variant="text" width="10rem" />
            <Skeleton variant="text" width="5rem" />
          </LineItemRoot>
          <LineItemRoot>
            <Skeleton variant="text" width="10rem" />
            <Skeleton variant="text" width="5rem" />
          </LineItemRoot>
          <LineItemRoot>
            <Skeleton variant="text" width="10rem" />
            <Skeleton variant="text" width="5rem" />
          </LineItemRoot>
        </OrderDetails>
        <OrderDisclaimer variant="caption">{DEFAULT_SHIPPING_CALCULATION_NOTE}</OrderDisclaimer>
        <LineItemRoot>
          <Typography variant="subtitle2">Total*</Typography>
          <Skeleton variant="text" width="5rem" />
        </LineItemRoot>
      </Wrapper>
      <ButtonContainer>
        <Button color="primary" variant="contained" size="large" disabled>
          <Skeleton variant="rectangular" />
        </Button>
      </ButtonContainer>
    </Box>
  );
}

export default OrderSummaryV2;
