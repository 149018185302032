import { useOrders } from '@/context/orders/Orders';
import type { Order } from '@/services/data.service';
import { Checkbox } from '@mui/material';

type SelectionTableCellProps = {
  order: Order;
};

export function SelectionTableCell({ order }: SelectionTableCellProps) {
  const { selectedOrders, toggleOrderSelection } = useOrders();

  return (
    <Checkbox
      data-testid="selection-checkbox"
      checked={selectedOrders.includes(order.id)}
      onChange={() => toggleOrderSelection(order.id)}
    />
  );
}
