import { toastOptions } from '@/components/common/toastConfirm';
import { getAlternativeProducts, getProductEdit } from '@/services/product.service';
import getProductImageUrl from '@/utils/getProductImageUrl';
import { Logger } from '@/utils/logger';
import { toast } from '@montugroup/design-system';
import { BrandThemeProvider, montu } from '@montugroup/themes';
import { Alert, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormInput, { FormTextArea } from '../../common/FormInputTyped';
import CircleLoader from '../../common/circleLoader';
import FormCheckbox from './FormCheckbox';
import constantsViewModel from './constantsViewModel';
import productViewModel from './productViewModel';
import { FormRow, Page, SubHeader } from './styles';
import type { AlternativeProductModel, ProductDetailsQueryModel } from './types';

const logger = new Logger('productEdit');

interface AlternativeProductResponse {
  alternative_product_id: number;
  AlternativeProduct: {
    name?: string;
  };
}

const ProductEdit = () => {
  const params = useParams();
  const productId = params.id;

  useEffect(() => {
    return () => {
      toast.dismiss(toastOptions.toastId);
    };
  }, []);

  const {
    data: productData,
    isError,
    isLoading,
    error
  } = useQuery<ProductDetailsQueryModel>({
    queryKey: ['adminProductDetails', productId],
    queryFn: async () => {
      const productResponse = await getProductEdit(productId!);
      const viewModel = productViewModel(productResponse.data);

      if (!viewModel) {
        throw new Error('Unable to build view model for product');
      }

      return {
        viewModel,
        constants: constantsViewModel(productResponse.data)
      };
    }
  });

  useEffect(() => {
    if (isError) {
      toast.error('Could not load product data. Please reload the page.', { autoClose: false });
      logger.error('Could not load product data', { error });
    }
  }, [isError, error]);

  const {
    data: alternativeProductData,
    isError: isErrorAlt,
    error: errorAlt
  } = useQuery<AlternativeProductModel[]>({
    queryKey: ['adminProductAlternatives', productId],
    queryFn: async () => {
      const alternativeProductsResponse: { data: AlternativeProductResponse[] } = await getAlternativeProducts(
        productId!
      );
      return alternativeProductsResponse.data.map((product) => ({
        id: product.alternative_product_id,
        name: product.AlternativeProduct?.name || ''
      }));
    },
    initialData: []
  });

  useEffect(() => {
    if (isErrorAlt) {
      toast.error('Could not load alternative product data', { autoClose: false });
      logger.error('Could not load alternative product data', { error: errorAlt });
    }
  }, [isErrorAlt, errorAlt]);

  if (isLoading) {
    return <CircleLoader />;
  }

  if (isError || !productData?.viewModel) {
    return (
      <Alert severity="error">
        Unable to load product details. Please refresh the page to try again. <pre>{error?.message}</pre>
      </Alert>
    );
  }

  const { constants, viewModel } = productData;

  return (
    <BrandThemeProvider theme={montu}>
      <Page>
        <form>
          <fieldset>
            <div>
              <Typography
                variant="h5"
                component={'h3'}
                sx={(theme) => ({ textTransform: 'uppercase', marginBottom: theme.spacing(2) })}
              >
                Product Details
              </Typography>
            </div>
            <FormRow>
              <FormInput readOnly type="text" value={viewModel.name || ''} label="Name" name="name" />
            </FormRow>
            <FormRow>
              <FormInput
                readOnly
                type="text"
                value={viewModel.description || ''}
                label="Description"
                name="description"
              />
            </FormRow>
            <FormRow>
              <FormInput
                readOnly
                name="category"
                label="Category"
                value={constants.ingredients.find((x) => x.value === viewModel.ingredient.value)?.label}
              />
              <FormInput
                readOnly
                name="formulation"
                label="Formulation"
                value={constants.formulations.find((x) => x.value === viewModel.formulation.value)?.label}
              />
            </FormRow>
            <FormRow>
              <FormInput
                readOnly
                type="text"
                value={viewModel.cbd_strength || ''}
                label="CBD Strength"
                name="cbdStrength"
              />
              <FormInput
                readOnly
                type="text"
                value={viewModel.thc_strength || ''}
                label="THC Strength"
                name="thcStrength"
              />
            </FormRow>
            <FormRow>
              <FormInput readOnly type="text" value={viewModel.sku || ''} label="SKU" name="sku" />
              <FormInput
                readOnly
                type="text"
                value={viewModel.price ? `$${viewModel.price}` : ''}
                label="Price (AUD)"
                name="price (AUD)"
              />
            </FormRow>
            <FormRow>
              <FormInput
                readOnly
                name="Supplier"
                value={constants.suppliers.find((x) => x.value === viewModel.supplier.value)?.label}
                label="Supplier"
              />
              <FormInput readOnly type="text" value={viewModel.cultivar || ''} label="Cultivar" name="cultivar" />
            </FormRow>
            <FormRow>
              <FormInput
                readOnly
                label="Strain"
                name="strain"
                value={constants.strains.find((x) => x.value === viewModel.strain.value)?.label}
              />
              <FormInput
                readOnly
                type="number"
                value={viewModel.thc_percentage || ''}
                label="THC %"
                name="thcPercentage"
              />
            </FormRow>
            <FormRow>
              <FormInput
                readOnly
                name="schedule"
                label="Schedule"
                value={constants.schedules.find((x) => x.value === viewModel.schedule.value)?.label}
              />
              <FormInput
                readOnly
                name="formulation"
                label="Hybrid"
                value={constants.hybrids.find((x) => x.value === viewModel.hybrid.value)?.label}
              />
            </FormRow>
            <FormRow>
              <FormInput readOnly type="number" value={viewModel.interval} label="Interval" name="interval" />
              <FormInput
                readOnly
                label="Spectrum"
                name="Spectrum"
                value={constants.spectrums.find((x) => x.value === viewModel.spectrum.value)?.label}
              />
            </FormRow>
            <FormRow>
              <FormInput readOnly type="number" value={viewModel.weight || ''} label="Weight (Kg)" name="weight" />
            </FormRow>
            <FormRow>
              <FormInput readOnly type="text" value={viewModel.dosage} label="Dosage" name="interval" />
            </FormRow>
            <FormRow>
              <FormTextArea
                readOnly
                value={alternativeProductData.map((x) => `${x.name} (id: ${x.id})`).join(', ')}
                label="Alternative Products"
                name="product-alternativeProducts"
                rows={2}
              />
            </FormRow>
            <FormRow>
              <FormCheckbox id="reasoningToggle" label="Out of Stock - Reasoning" checked={viewModel.reasoningToggle} />
              {viewModel.reasoningToggle && (
                <FormInput
                  readOnly
                  label="Reasoning"
                  type="text"
                  id="reasoning"
                  value={viewModel.reasoning}
                  placeholder="Reasoning"
                  name="interval"
                />
              )}
            </FormRow>
            <FormRow>
              <FormCheckbox id="product-active" label="Active / In-Active" checked={viewModel.active} />
            </FormRow>
            <FormRow>
              <FormCheckbox
                id="product-available2all"
                label="Available to all Doctors"
                checked={viewModel.available2all}
              />
            </FormRow>
            <FormRow>
              <FormCheckbox
                label="Excluded from new prescriptions"
                id="product-isExcludedFromNewPrescriptions"
                checked={viewModel.isExcludedFromNewPrescriptions}
              />
            </FormRow>
            <FormRow>
              <FormCheckbox
                label="Discontinued"
                id="product-isGenerativeScripting"
                checked={viewModel.isGenerativeScripting}
              />
            </FormRow>
            <SubHeader>Product Banners</SubHeader>
            <FormRow>
              <FormCheckbox label="Coming Soon" id="product-coming_soon" checked={viewModel.coming_soon} />
            </FormRow>
            <FormRow>
              <FormCheckbox label="New Product" id="product-new_product" checked={viewModel.new_product} />
            </FormRow>
            <FormRow>
              <FormCheckbox label="New low Price" id="product-new_at_lowprice" checked={viewModel.new_at_lowprice} />
            </FormRow>
            <SubHeader>Patient Access Details</SubHeader>
            <FormRow>
              <FormCheckbox
                label="Product available in Product Catalog"
                id="patient-catalog-box"
                checked={viewModel.patient_catalog}
              />
            </FormRow>
            <FormRow>
              <FormInput
                readOnly
                type="text"
                value={viewModel.short_name || ''}
                label="Product Name (Simplified)"
                name="short_name"
              />
              <FormInput readOnly type="text" value={viewModel.size || ''} label="Volume" name="size" />
            </FormRow>
            <FormRow>
              <FormTextArea
                readOnly
                value={viewModel.description_for_display || ''}
                label="Description (For Display)"
                name="description_for_display"
                rows={3}
              />
            </FormRow>
            <FormRow>
              <FormTextArea readOnly value={viewModel.conditions || ''} label="Conditions" name="conditions" rows={5} />
              <FormTextArea
                readOnly
                value={viewModel.contents || ''}
                label="Flavor/Carrier/Terpenes"
                name="contents"
                rows={5}
              />
            </FormRow>
            <FormRow>
              <FormTextArea readOnly value={viewModel.how_to_use || ''} label="How to Use" name="how_to_use" rows={5} />
            </FormRow>
            <FormRow>
              <FormInput
                readOnly
                type="text"
                value={viewModel.cbd_strength_for_display || ''}
                label="CBD Strength (For Display)"
                name="cbd_strength_for_display"
              />
              <FormInput
                readOnly
                type="text"
                value={viewModel.thc_strength_for_display || ''}
                label="THC Strength (For Display)"
                name="thc_strength_for_display"
              />
            </FormRow>
            <FormRow>
              <FormInput
                readOnly
                type="text"
                value={viewModel.cbd_thc_ratio || ''}
                label="CBD:THC Ratio"
                name="cbd_thc_ratio"
              />
              <div></div>
            </FormRow>
            <FormRow>
              <div>
                <img
                  src={getProductImageUrl(viewModel.product_image, viewModel.ProductImages)}
                  style={{ width: '300px' }}
                />
              </div>
            </FormRow>
          </fieldset>
        </form>
      </Page>
    </BrandThemeProvider>
  );
};

export default ProductEdit;
