import { ToastContainer } from '@montugroup/design-system';
import { CssBaseline } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import enGB from 'date-fns/locale/en-GB';
import { createContext } from 'react';

import settings from '@/constants/constants';
import { PreferencesProvider } from '@/context/Preferences';

import '@montugroup/design-system/Toast/style.css';
import './App.scss';

import type { Card } from './components/braze/components/ContentCard';
import CircleLoader from './components/common/circleLoader';
import AlertSnackbarProvider from './components/error/AlertSnackbarProvider';
import { FF_SHOW_MAINTENANCE_SCREEN } from './constants/featureFlags';
import { ErrorManagementProvider } from './context/ErrorManagement';
import { PatientReferralProvider } from './context/PatientReferral';
import { useAxiosSetup } from './hooks/useAxiosSetup';
import { useBraze } from './hooks/useBraze';
import useFeatureFlags from './hooks/useFeatureFlags';
import { useSetDatadogUser } from './hooks/useSetDatadogUser';
import useTrackingProviders from './hooks/useTrackingProviders';
import { useUserFeatureFlagsSync } from './hooks/useUserFeatureFlagsSync';
import Maintenance from './pages/maintenance/Maintenance';
import { AuthenticatedSnowplow } from './providers/authenticated-snowplow';
import Routes from './routes/routes';

export const BrazeContentCardContext = createContext<Card[]>([]);

function App() {
  useAxiosSetup();
  useTrackingProviders(settings);
  useSetDatadogUser();
  const { flags } = useFeatureFlags();
  const { loading } = useUserFeatureFlagsSync();
  const { brazeContentCards } = useBraze();

  if (loading) {
    return <CircleLoader />;
  }

  return (
    <GoogleOAuthProvider clientId={settings.GoogleAuthId}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <CssBaseline />
        <ToastContainer
          // Allowing unmasking of toast text in session replays, if any toast text is found to be PII the implementation of this will need to be changed
          toastClassName="dd-privacy-allow"
          position="top-right"
          autoClose={7500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnHover
        />
        <AlertSnackbarProvider>
          <ErrorManagementProvider initialErrors={[]}>
            <BrazeContentCardContext.Provider value={brazeContentCards}>
              {flags[FF_SHOW_MAINTENANCE_SCREEN] ? (
                <Maintenance />
              ) : (
                <PreferencesProvider>
                  <PatientReferralProvider>
                    <AuthenticatedSnowplow>
                      <Routes />
                    </AuthenticatedSnowplow>
                  </PatientReferralProvider>
                </PreferencesProvider>
              )}
            </BrazeContentCardContext.Provider>
          </ErrorManagementProvider>
        </AlertSnackbarProvider>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
