import SplitOrderSelectionCell from '@/components/orders/modals/SplitOrderSelectionCell';
import settings from '@/constants/constants';
import { useOrders } from '@/context/orders/Orders';
import { useSplitOrderModal } from '@/context/orders/OrdersModals';
import useGetOrderedProducts from '@/hooks/orders/useGetOrderedProducts';
import type { SplitOrder } from '@/services/order.service';
import { OrderService } from '@/services/order.service';
import type { OrderProduct, Product } from '@/types';
import { Logger } from '@/utils/logger';
import { Button, Table, toast } from '@montugroup/design-system';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import type { ColumnDef } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';

const logger = new Logger('SplitOrderModal');

export type SelectedProduct = Partial<Product> & {
  orderId?: number;
  product_id: number;
  status?: string;
};

export type InactiveProduct = Partial<OrderProduct> & {
  orderId: number;
  status: string;
  product_name: string;
};

export type SplitOrderData = {
  selectedProducts: SelectedProduct[];
  splitOrders: SplitOrder[];
  inactiveProducts: InactiveProduct[];
};

function SplitOrderModal() {
  const { visible, order, hideSplitOrderModal } = useSplitOrderModal();
  const { filter, onFilterChange, refreshOrders } = useOrders();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [splitOrderData, setSplitOrderData] = useState<SplitOrderData>({
    selectedProducts: [],
    splitOrders: [],
    inactiveProducts: []
  });

  const { data, isPending } = useGetOrderedProducts({
    orderId: order?.id || null
  });

  useEffect(() => {
    if (!isPending && data) {
      const selectedProducts = data.products as SelectedProduct[];

      const orders: SplitOrder[] = [];

      for (let i = 1; i <= selectedProducts.length; i++) {
        const order = { order: i, orderName: `Order ${i}`, products: [] };
        orders.push(order);
      }
      for (let i = 0; i < selectedProducts.length; i++) {
        selectedProducts[i].orderId = 1;
        const productId = selectedProducts[i].product_id;
        if (productId) {
          orders[0].products.push(productId);
        }
      }

      const orderNumber = orders.length + 1;

      const inactiveProducts = data.OrderProducts.filter(
        (orderProduct) => !orderProduct.Product.active || orderProduct.Product.is_out_of_stock
      ).map((item) => ({
        ...item,
        orderId: orderNumber,
        status: settings.productStatus.inactive,
        product_name: item.Product.name
      }));

      // add inactive products
      if (inactiveProducts.length) {
        orders.push({
          order: orderNumber,
          orderName: `Order ${orderNumber}`,
          products: inactiveProducts.map((p) => p.product_id),
          isOutOfStock: true
        });
        selectedProducts.push(
          ...inactiveProducts.map((p) => ({
            product_id: p.product_id,
            product_name: p.Product.name,
            quantity: p.quantity,
            status: settings.productStatus.inactive,
            orderId: orderNumber
          }))
        );
      }

      setSplitOrderData({
        selectedProducts,
        splitOrders: orders,
        inactiveProducts
      });
    }
  }, [data, isPending]);

  const handleSplitOrderSelection = useCallback(
    (orderId: number, product: SelectedProduct) => {
      const splitOrders = splitOrderData.splitOrders;
      const selectedProducts = splitOrderData.selectedProducts;
      const productId = product.product_id;
      const currentOrderId = product.orderId;

      if (!splitOrders || !selectedProducts || !productId) {
        return;
      }

      // Find the current and new orders
      const currentOrder = splitOrders.find((order) => order.order === currentOrderId);
      const newOrder = splitOrders.find((order) => order.order === orderId);

      // If either order isn't found, exit early
      if (!currentOrder || !newOrder) {
        return;
      }

      // Move the product to the new order (if not already in it)
      if (!newOrder.products.includes(productId)) {
        newOrder.products.push(productId);
      }

      // Remove the product from the old order
      const currentProductIdx = currentOrder.products.indexOf(productId);
      if (currentProductIdx !== -1) {
        currentOrder.products.splice(currentProductIdx, 1);
      }

      // Update the product's order in selectedProducts
      const productToUpdate = selectedProducts.find((p) => p.product_id === productId);
      if (productToUpdate) {
        productToUpdate.orderId = orderId;
      }

      setSplitOrderData({
        ...splitOrderData,
        splitOrders,
        selectedProducts
      });
    },
    [splitOrderData]
  );

  const saveSplitOrdersMutation = useMutation({
    mutationFn: async ({ orderId, orders }: { orderId: number; orders: SplitOrder[] }) => {
      try {
        const response = await OrderService.saveSplitOrders(orderId, orders);
        return response.data;
      } catch (error: any) {
        throw new Error(`Failed to save split orders: ${error.message}`);
      }
    },
    retry: false,
    onSuccess: (data) => {
      toast[data.status === 1 ? 'success' : 'warn'](data.message);
      if (data.status === 1) {
        if (order?.order_code === filter) {
          refreshOrders();
        } else {
          onFilterChange(order?.order_code ?? '');
        }
      }
      hideSplitOrderModal();
      setDisabled(false);
    }
  });

  const handleSplitOrder = async () => {
    if (!order) {
      // shouldn't happen
      toast.error('Something went wrong, please try again');
      logger.error('Could not split order, order not set.');
      return;
    }
    setDisabled(true);

    const orders = splitOrderData.splitOrders
      .filter((order) => order.products.length > 0) // Filter out orders without products
      .map((order) => order);

    const payload = { orderId: order.id, orders };

    try {
      await saveSplitOrdersMutation.mutateAsync(payload);
    } catch (error: any) {
      logger.error('saveSplitOrdersMutation', error);
      toast.error(error.message);
    }
  };

  const columns: ColumnDef<SelectedProduct>[] = useMemo(
    () => [
      {
        accessorKey: 'product_name',
        header: 'Product',
        enableSorting: false
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        enableSorting: false
      },
      {
        accessorKey: '',
        header: 'Split',
        enableSorting: false,
        cell: ({ row }) => (
          <SplitOrderSelectionCell
            data={splitOrderData}
            product={row.original}
            disabled={disabled}
            onChange={handleSplitOrderSelection}
          />
        )
      }
    ],
    [disabled, handleSplitOrderSelection, splitOrderData]
  );

  return (
    <Dialog open={visible} onClose={hideSplitOrderModal}>
      <DialogTitle>Split Order - {order?.order_code}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Table data={splitOrderData.selectedProducts} columns={columns} hasRowBackgroundColor={false} elevation={0} />
          {splitOrderData.inactiveProducts.length > 0 && (
            <Typography variant="caption">*Inactive products will be split into a separate order.</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" disabled={disabled} onClick={hideSplitOrderModal}>
          Cancel
        </Button>
        <Button color="primary" disabled={disabled} onClick={handleSplitOrder}>
          Split order
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SplitOrderModal;
