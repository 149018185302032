import { toast } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AuthCardContainer,
  AuthFormGroup,
  AuthPageContainer,
  CardHalfContent,
  CardHalfDecoration,
  MobileLogoContainer,
  MobileOnly
} from '@/components/auth/authPage';
import FormInput from '@/components/common/FormInputTyped';
import { PapayaContained } from '@/components/common/Papaya';
import settings from '@/constants/constants';
import USER_ROLES from '@/constants/userRoles';
import { alternaleafTheme } from '@/theme';
import { LoadingButton } from '@/ui-library';
import { Logger } from '@/utils/logger';

import { BrandThemeProvider } from '@montugroup/themes';
import logo from '../../components/layout/alternaleaf.svg';

const logger = new Logger('ResetPassword');
const { url } = settings;

const AuthCardBackground = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  // eslint-disable-next-line quotes
  backgroundImage: "url('/assets/images/patientBack_AL.png')",
  backgroundPosition: 'top left',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  padding: '2rem',
  borderTopLeftRadius: '25px',
  borderBottomLeftRadius: '25px'
}));

export interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}

export function ResetPasswordPage() {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<boolean>(false);

  const { register, handleSubmit, formState, watch } = useForm<ResetPasswordFormData>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  const password = watch('password');
  const shouldShowForm = !message || password === '';
  const { errors } = formState;

  const validateExpiryEpoch = (epochDate: number): void => {
    const now = new Date().toISOString();
    const nowEpoch = Date.parse(now);
    if (nowEpoch > epochDate) {
      toast.error(
        `Sorry, your password reset link has expired or is invalid. Please try again or contact our support on ${settings.support.phone.display}`,
        { autoClose: false }
      );
      navigate('/forgot-password/patient');
    }
  };

  const onSubmit: SubmitHandler<ResetPasswordFormData> = async (formData: ResetPasswordFormData) => {
    setLoading(true);
    const { id } = params;

    try {
      const res = await fetch(`${url}/user/passwordupdate/${id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: formData.password
        })
      });

      const result = await res.json();

      if (res.status !== 200) {
        throw Error(result.message);
      }
      setMessage((msg) => !msg);

      if (result.user.role_id === USER_ROLES.patient) {
        toast.success('Password changed successfully');
        navigate('/login/patient');
        return;
      }

      toast.success('Password changed successfully');
      navigate('/');
    } catch (err) {
      toast.error(`Something went wrong. Please try again or contact support on ${settings.support.phone.display}`);
      setLoading(false);
      logger.error(err);
    }
  };

  useEffect(() => {
    const token = params?.id?.split('$');
    if (token?.length === 3) {
      const epochExpiry = Number(token[2]);

      validateExpiryEpoch(epochExpiry);
    }
  }, [params.id]);

  return (
    <div className="w-100 h-100">
      <BrandThemeProvider theme={alternaleafTheme}>
        <AuthPageContainer>
          <AuthCardContainer>
            <CardHalfDecoration>
              <AuthCardBackground>
                <PapayaContained />
              </AuthCardBackground>
            </CardHalfDecoration>
            <CardHalfContent>
              <MobileLogoContainer>
                <a href="https://www.montu.com.au" target="blank">
                  <img src={logo} alt="Alternaleaf" className="px-4 py-2" />
                </a>
              </MobileLogoContainer>
              <div>
                {shouldShowForm && (
                  <form className="p-4" data-private method="post">
                    <h2 className="mb-4">Reset Password</h2>
                    <p className="mb-4" data-testid="subtitle">
                      Reset your account password? Enter your new password.
                    </p>
                    <AuthFormGroup>
                      <FormInput
                        type="password"
                        id="userPassword"
                        {...register('password', {
                          required: 'You must enter a password',
                          minLength: {
                            value: 5,
                            message: 'Password must be at least 5 characters'
                          }
                        })}
                        error={errors.password?.message}
                        label="New Password"
                        placeholder="Enter password"
                        data-testid="new-password-form-input"
                      />
                      <FormInput
                        type="password"
                        id="userConfirmPassword"
                        {...register('confirmPassword', {
                          required: 'Passwords must match',
                          validate: (value, formData) => formData.password === value || 'Passwords must match'
                        })}
                        error={errors.confirmPassword?.message}
                        label="Confirm Password"
                        placeholder="Enter password"
                        data-testid="confirm-password-form-input"
                      />
                    </AuthFormGroup>
                    <LoadingButton
                      id="btnSubmit"
                      loading={loading}
                      variant="contained"
                      color="primary"
                      className="w-100"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      data-testid="submit-button"
                    >
                      Reset
                    </LoadingButton>
                  </form>
                )}
              </div>
            </CardHalfContent>
          </AuthCardContainer>
        </AuthPageContainer>
      </BrandThemeProvider>
      <MobileOnly>
        <PapayaContained sx={{ position: 'fixed' }} />
      </MobileOnly>
    </div>
  );
}

export default ResetPasswordPage;
