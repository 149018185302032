import { datadogRum } from '@datadog/browser-rum';
import type { LDEvaluationDetail } from 'launchdarkly-react-client-sdk';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import type { ComponentProps, PropsWithChildren } from 'react';
import { useEffect } from 'react';

import settings from '@/constants/constants';
import useUser from '@/hooks/user/useUser';
import { Logger } from '@/utils/logger';
import { convertToLDIdentityContext } from '../utils/convertToLDIdentityContext';

const logger = new Logger('FeatureFlagProvider');

const LAUNCH_DARKLY_API_KEY = settings.launchDarklyConfig?.clientSideId;

export function FeatureFlagProvider({
  children,
  ...props
}: PropsWithChildren<Partial<ComponentProps<typeof LDProvider>>>) {
  const { data: user } = useUser();
  const initialLDUserContext = convertToLDIdentityContext(user);

  useEffect(() => {
    logger.info('LD client initial identity', initialLDUserContext.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LDProvider
      {...{
        clientSideID: LAUNCH_DARKLY_API_KEY,
        options: {
          inspectors: [
            {
              type: 'flag-used',
              name: 'dd-inspector',
              method: (key: string, detail: LDEvaluationDetail) => {
                datadogRum.addFeatureFlagEvaluation(key, detail.value);
              }
            }
          ]
        },
        reactOptions: {
          useCamelCaseFlagKeys: false
        },
        context: initialLDUserContext,
        ...props
      }}
    >
      {children}
    </LDProvider>
  );
}
