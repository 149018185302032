import { styled } from '@mui/material';

import UmedsIcon from '@/components/common/UmedsIcon';
import UmedsLogo from '@/components/common/UmedsLogo';
import { uMeds } from '@montugroup/themes';

const UmedsLogoWithSx = styled(UmedsLogo)({});
const UmedsIconWithSx = styled(UmedsIcon)({});

export const PartnerKey = {
  UMEDS: 'uMeds'
} as const;

const PartnerComponents = {
  [PartnerKey.UMEDS]: {
    name: 'uMeds',
    Logo: UmedsLogoWithSx,
    Icon: UmedsIconWithSx
  }
} as const;

export function usePortalPartnerBrand() {
  const key = uMeds;
  const components = PartnerComponents[PartnerKey.UMEDS];

  return { key, ...components };
}

export default usePortalPartnerBrand;
