import type { AlertColor } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';

export interface AlertState {
  show: boolean;
  severity: AlertColor | undefined;
  title?: string;
  content: string;
}

export interface AlertWithCloseProps {
  alert: AlertState;
  setAlert: Dispatch<SetStateAction<AlertState>>;
}

export function AlertWithClose({ alert, setAlert }: AlertWithCloseProps) {
  return (
    <Alert severity={alert.severity} sx={{ width: '100%' }} onClose={() => setAlert({ ...alert, show: false })}>
      {alert?.title ? <AlertTitle>{alert.title}</AlertTitle> : null}
      {alert.content}
    </Alert>
  );
}
