import type { AxiosResponse } from 'axios';
import axios from 'axios';
import moment from 'moment';

import type {
  GeneralPractictioner,
  GenericStatusResponse,
  IUser,
  Order,
  OrderStatus,
  OutOfStockOrders,
  Patient,
  PatientDiscount,
  Pharmacy,
  Product,
  ProductInventoryStatus,
  ReplaceOrderProduct
} from '@/types';

import settings from '../constants/constants';

const API_URL = `${settings.url}/order`;

const findOutOfStockOrders = (id: number) => axios.get<OutOfStockOrders>(`${API_URL}/v1/findOutOfStockOrders?id=${id}`);

const getAllActiveAndInactiveProducts = () => axios.get(`${API_URL}/all-products`);

const getAllProducts = () => axios.get<Product[]>(`${API_URL}/product`);

export type AwaitingOrdersResponse = {
  count: number;
};

const getAwaitingOrdersCount = async () => axios.post<AwaitingOrdersResponse>(`${API_URL}/awaitingOrdersCount`);

export type ExportAndShipAwaitingOrdersResponse = {
  message: string;
};

const exportAndShipAwaitingOrders = async () =>
  axios.put<ExportAndShipAwaitingOrdersResponse>(`${API_URL}/exportAndShipAllAwaiting`, {
    tz: moment().format('Z'),
    updateDate: moment(new Date()).valueOf()
  });

export type PatientExistRefillResponse = {
  found: boolean;
};

const getPatientExistRefill = (userId: number, pIds: number[]) =>
  axios.get<PatientExistRefillResponse>(`${API_URL}/exist-refill/${userId}/${pIds.join(',')}`);

const getPharmacies = async () => axios.get<Pharmacy[]>(`${API_URL}/pharmacy`);

const getOrderStatus = async (): Promise<AxiosResponse<OrderStatus[]>> => axios.get(`${API_URL}/order-status`);

const getOrderStatusBulk = async (): Promise<AxiosResponse<OrderStatus[]>> => axios.get(`${API_URL}/order-status/bulk`);

const getProductsFromInventory = async (pharmacyId: number): Promise<AxiosResponse<ProductInventoryStatus[]>> =>
  axios.post(`${API_URL}/getProductsFromInventory`, { pharmacyId });

const replaceInvoiceOrderProducts = (orderId: number, products: ReplaceOrderProduct[]) =>
  axios.patch(`${API_URL}/v1/${orderId}/replaceInvoiceOrderProducts`, { products });

const getOrderCreditDiscount = (data: unknown) => axios.post(`${API_URL}/getOrderCreditDiscount`, data);

const getOrderForPatientRefill = (userId: string | number, orderId = 0) =>
  axios.get(`${API_URL}/patient-refill/${userId}/${orderId || ''}`);

export type GetOrderedProductsResponse = Order & {
  PrescriptionOrderHistories: PrescriptionOrderHistory[];
};

export interface PrescriptionOrderHistory {
  id: number;
  order_id: number;
  dispense_date: any;
  cancelled: any;
  product_id: number;
  prescription_id: number;
  units: number;
}

const getOrderedProducts = async (orderId: number) =>
  axios.get<GetOrderedProductsResponse>(`${API_URL}/ordered-products/${orderId}`, {
    headers: {
      prescription: true
    }
  });

export type SplitOrder = {
  order: number;
  orderName: string;
  products: number[];
  isOutOfStock?: boolean;
};

export type SaveSplitOrdersResponse = {
  status: number;
  message: string;
};

const saveSplitOrders = async (orderId: number, orders: SplitOrder[]) =>
  axios.post<SaveSplitOrdersResponse>(`${API_URL}/v1/splitOrder`, {
    orderId,
    orders
  });

const updateShippedStatus = async (orderIds: number[]) =>
  axios.put<GenericStatusResponse<string>>(`${API_URL}/exportAndShip`, {
    orderIds,
    tz: moment().format('Z'),
    updateDate: moment(new Date()).valueOf()
  });

export type BulkStatusUpdateResponse = {
  message?: string;
  success?: boolean;
};

const bulkStatusUpdate = async (orderStatusId: number, orderIds: number[]) =>
  axios.put<BulkStatusUpdateResponse>(`${API_URL}/bulk-update`, {
    order_status_id: orderStatusId,
    items: orderIds,
    tz: moment().format('Z'),
    order_date: moment(new Date()).valueOf()
  });

const getOrder = async ({ id }: { id: number | `${number}` }) => axios.get<Order>(`${API_URL}/${id}`);

export type PatientForOrderDetails = {
  patient: Pick<
    Patient,
    | 'id'
    | 'patient_code'
    | 'client_id'
    | 'client_code'
    | 'pharmacy_id'
    | 'patient_pharmacy_address'
    | 'patient_pharmacy_name'
  > & {
    PatientUser: Pick<IUser, 'first_name' | 'last_name'>;
    PatientDiscounts: PatientDiscount[];
  };
  patientGPs: {
    id: number;
    GeneralPractitioner: Pick<GeneralPractictioner, 'id'> & {
      Doctor: Pick<IUser, 'first_name' | 'last_name' | 'id'>;
    };
  }[];
  pharmacies: Pick<Pharmacy, 'id' | 'name' | 'address' | 'city' | 'zip_code' | 'active'>[];
  products: Product[];
};

const getPatientForOrder = async ({ id }: { id: number }) =>
  axios.get<PatientForOrderDetails>(`${API_URL}/patient-order/${id}`);

// eslint-disable-next-line import/prefer-default-export
export const OrderService = {
  getOrder,
  bulkStatusUpdate,
  exportAndShipAwaitingOrders,
  findOutOfStockOrders,
  getAllActiveAndInactiveProducts,
  getAllProducts,
  getOrderedProducts,
  getAwaitingOrdersCount,
  getOrderCreditDiscount,
  getPatientExistRefill,
  getPatientForOrder,
  getPharmacies,
  getOrderForPatientRefill,
  getOrderStatus,
  getOrderStatusBulk,
  getProductsFromInventory,
  replaceInvoiceOrderProducts,
  saveSplitOrders,
  updateShippedStatus
};
