import { MenuItem, Select } from '@mui/material';
import { CanFulfill } from './PreOnboardingFlowComponents/CanFulfill';
import { DuplicateScript } from './PreOnboardingFlowComponents/DuplicateScript';
import { ExistingCustomerProductMatch } from './PreOnboardingFlowComponents/ExistingCustomerProductMatch';
import { InviteSent } from './PreOnboardingFlowComponents/InviteSent';
import { OutOfStock } from './PreOnboardingFlowComponents/OutOfStock';
import { SubmitToken } from './PreOnboardingFlowComponents/SubmitToken';
import type { PreOnboardingStates } from './context/PreOnboardingContext';
import { usePreOnboardingContext } from './hooks/usePreOnboardingContext';

export interface PreOnboardingContentFlowProps {
  setOpenTokenDialog: (state: boolean) => void;
  openTokenDialog: boolean;
}

export const PreOnboardingFlowContainer = ({ setOpenTokenDialog, openTokenDialog }: PreOnboardingContentFlowProps) => {
  const { preOnboardingState, setPreOnboardingState } = usePreOnboardingContext();

  {
    /* components to be created for the different steps as part of other tickets */
  }
  const PreOnboardingSteps = {
    submitToken: <SubmitToken setOpenTokenDialog={setOpenTokenDialog} openTokenDialog={openTokenDialog} />,
    highConfidence: <CanFulfill highConfidence />,
    lowConfidence: <CanFulfill />,
    outOfStock: <OutOfStock />,
    inviteSent: <InviteSent />,
    duplicateScript: <DuplicateScript />,
    existingCustomerProductMatch: <ExistingCustomerProductMatch />
  };

  return (
    <>
      {PreOnboardingSteps[preOnboardingState]}

      {/* Test different pre onboarding status just demoing, todo: remove */}
      <Select
        value={preOnboardingState}
        onChange={(e) => setPreOnboardingState(e.target.value as PreOnboardingStates)}
        sx={{ position: 'absolute', bottom: '-100px', right: 0 }}
      >
        {Object.keys(PreOnboardingSteps).map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
