import type { LDSingleKindContext } from 'launchdarkly-js-sdk-common';

import type { User } from '@/hooks/user/useUser';

export const anonymousUserContext = {
  kind: 'user',
  anonymous: true,
  key: 'shared-anonymous'
};

export const convertToLDIdentityContext = (user?: User | null) => {
  if (!user) {
    return anonymousUserContext;
  }

  const ldUserName = `${user.firstName} ${user.lastName}`;

  const userContext: LDSingleKindContext = {
    kind: 'user',
    key: `${user.id}`,
    name: ldUserName,
    email: user.email,
    role: user.roleId
  };

  return userContext;
};
