import { Close as CloseIcon } from '@mui/icons-material';
import type { DialogProps } from '@mui/material';
import { Box, Dialog, DialogTitle, IconButton, Slide, styled, useMediaQuery, useTheme } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import React, { forwardRef } from 'react';

export const StyledDialogTitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  '& > h2': {
    padding: 0
  }
}));

interface ResponsiveDialogProps extends Omit<DialogProps, 'title'> {
  title?: React.ReactNode;
  closeButton?: React.ReactNode;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ResponsiveDialog({ title, children, onClose, closeButton, ...muiDialogProps }: ResponsiveDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const customDialogTitle = Boolean(title || closeButton);

  return (
    <Dialog
      TransitionComponent={isMobile ? Transition : undefined}
      PaperProps={{
        sx: (theme) => ({
          minWidth: '420px',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
            margin: 0,
            borderBottomLeftRadius: theme.spacing(0),
            borderBottomRightRadius: theme.spacing(0)
          }
        })
      }}
      onClose={onClose}
      {...muiDialogProps}
    >
      {customDialogTitle ? (
        <StyledDialogTitleWrapper>
          {title ? <DialogTitle padding={0}>{title}</DialogTitle> : null}
          {closeButton ? (
            <IconButton
              aria-label="close"
              data-testid="close-responsive-dialog"
              onClick={(e) => onClose?.(e, 'backdropClick')}
              size="medium"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </StyledDialogTitleWrapper>
      ) : null}
      {children}
    </Dialog>
  );
}
