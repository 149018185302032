import { Chip, styled } from '@mui/material';
import type { ScriptMetadata } from './common';
import { getStatus } from './common';

export type PrescriptionStatusChipProps = {
  prescriptionMetadata: ScriptMetadata;
  size?: 'small' | 'medium';
};

export const StatusChip = styled(Chip)(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightMedium
}));

export function PrescriptionStatusChip({ prescriptionMetadata, size = 'medium' }: PrescriptionStatusChipProps) {
  const determineStatusColor = () => {
    const isInactive = prescriptionMetadata.inActive;
    if (isInactive || prescriptionMetadata.status === 'Ceased' || prescriptionMetadata.status === 'Expired') {
      return 'error';
    }
    if (prescriptionMetadata.status === 'Active') {
      return 'success';
    }
    return 'info';
  };

  return (
    <StatusChip label={getStatus(prescriptionMetadata)} variant="filled" color={determineStatusColor()} size={size} />
  );
}

export default PrescriptionStatusChip;
