import type { PaginationModel } from '@montugroup/design-system';
import { useEffect, useState } from 'react';

/**
 * (Copied from PMS) Convenience hook for managing manual pagination especially for
 * resetting page index.
 *
 * Consider updating `<Table>` component or update `<DataGrid>` component and deprecate this
 * to improve DX
 * @param initialState
 */
export const useTableManualPagination = (initialState?: PaginationModel) => {
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(
    initialState ?? {
      page: 0,
      pageSize: 10
    }
  );

  const [shouldResetPageIndex, setShouldResetPageIndex] = useState(false);

  useEffect(() => {
    if (shouldResetPageIndex) {
      setPaginationModel((prev) => ({
        ...prev,
        page: 0
      }));
    }
  }, [shouldResetPageIndex]);

  return {
    paginationModel,
    setPaginationModel,
    shouldResetPageIndex,
    setShouldResetPageIndex
  };
};
