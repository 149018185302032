import { GroupOutlined as GroupOutlinedIcon } from '@mui/icons-material';
import { Button, styled } from '@mui/material';

import { FF_WOM_PRICE, FF_ENABLE_WOM_DISCOUNT_INCREASED_COPY } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';

import { PatientReferralActionType, usePatientReferral } from '../../context/PatientReferral';
import { GoogleAnalyticsEventName } from '../../types';

type Props = {
  isNavBanner: boolean;
};

const NavBannerButton = styled(Button)(({ theme }) => ({
  borderRadius: '0',
  fontSize: '14px',
  textDecoration: 'underline',
  justifyContent: 'flex-start',
  paddingLeft: '0.5rem',
  paddingRight: '0',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`
  }
}));

const BannerButton = styled(Button)<{ sidebar: 'true' | 'false' }>(({ theme, sidebar }) => ({
  borderRadius: '0',
  fontSize: '14px',
  textDecoration: 'underline',
  position: 'fixed',
  bottom: 0,
  zIndex: 100,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.common.white} !important`
  },
  [theme.breakpoints.up('md')]: {
    position: sidebar === 'true' ? 'static' : 'fixed',
    bottom: sidebar === 'true' ? 'auto' : 0
  }
}));

function PatientReferralNavBanner({ isNavBanner }: Props) {
  const { dispatch, open } = usePatientReferral();
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();
  const { flags } = useFeatureFlags();
  const womPrice = flags[FF_WOM_PRICE];
  const isWomDiscountIncreased = flags[FF_ENABLE_WOM_DISCOUNT_INCREASED_COPY];
  const handleOnBannerClick = () => {
    if (!open) {
      sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.STC_BANNER_CLICK, {
        event_category: 'stc_banner_click',
        event_label: 'stc_banner_cta'
      });
    }

    dispatch({ type: PatientReferralActionType.TOGGLE_OPEN });
  };

   const bannerText = isWomDiscountIncreased
    ? `Share the Care - New $${womPrice} Friends and Family Discount`
    : `Share the Care - Enjoy $${womPrice} off with Alternaleaf`;

  return isNavBanner ? (
    <NavBannerButton fullWidth onClick={handleOnBannerClick}>
      <GroupOutlinedIcon
        style={{
          marginRight: '0.5rem',
          fontSize: '1.5rem'
        }}
      />
      {bannerText}
    </NavBannerButton>
  ) : (
    <BannerButton fullWidth onClick={handleOnBannerClick} sidebar="true">
      <GroupOutlinedIcon
        style={{
          marginRight: '0.5rem',
          fontSize: '1.5rem'
        }}
      />
      {bannerText}
    </BannerButton>
  );
}

export default PatientReferralNavBanner;
