import { useContext } from 'react';
import type { PreOnboardingContextType } from '../context/PreOnboardingContext';
import { PreOnboardingContext } from '../context/PreOnboardingContext';

export const usePreOnboardingContext = (): PreOnboardingContextType => {
  const context = useContext(PreOnboardingContext);
  if (!context) {
    throw new Error('usePreOnboardingContext must be used within the PreOnboarding provider');
  }
  return context;
};
