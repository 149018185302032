import { createContext, useMemo, useState } from 'react';

export type PreOnboardingStates =
  | 'submitToken'
  | 'highConfidence'
  | 'lowConfidence'
  | 'outOfStock'
  | 'inviteSent'
  | 'duplicateScript'
  | 'existingCustomerProductMatch';

export interface PreOnboardingCustomer {
  email?: string;
  phoneNumber?: string;
}

export interface PreOnboardingContextType {
  preOnboardingState: PreOnboardingStates;
  setPreOnboardingState: React.Dispatch<React.SetStateAction<PreOnboardingStates>>;
  isExistingUser: boolean;
  setIsExistingUser: React.Dispatch<React.SetStateAction<boolean>>;
  customerDetails: null | PreOnboardingCustomer;
  setCustomerDetails: React.Dispatch<React.SetStateAction<null | PreOnboardingCustomer>>;
}

export const PreOnboardingContext = createContext<PreOnboardingContextType | undefined>(undefined);

export const PreOnboardingStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [preOnboardingState, setPreOnboardingState] = useState<PreOnboardingStates>('submitToken');
  const [isExistingUser, setIsExistingUser] = useState<boolean>(false);
  const [customerDetails, setCustomerDetails] = useState<null | PreOnboardingCustomer>(null);

  const value = useMemo(() => {
    return {
      preOnboardingState,
      setPreOnboardingState,
      isExistingUser,
      setIsExistingUser,
      customerDetails,
      setCustomerDetails
    };
  }, [preOnboardingState, isExistingUser, customerDetails]);

  return <PreOnboardingContext.Provider value={value}>{children}</PreOnboardingContext.Provider>;
};
