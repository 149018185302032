import { Link } from '@mui/material';
import { useState } from 'react';

/* TODO Currently no functionality to actually send invite */
export const ResendInviteButton = () => {
  const [isCounting, setIsCounting] = useState<boolean>(false);
  const [count, setCount] = useState<number>(10);

  const handleResendClick = () => {
    setIsCounting(true);
    let secondsLeft = 10;
    const countdownTimer = setInterval(() => {
      if (secondsLeft <= 1) {
        setIsCounting(false);
        setCount(10);
        clearInterval(countdownTimer);
      }

      secondsLeft -= 1;
      setCount(secondsLeft);
    }, 1000);
  };

  if (isCounting) {
    return <>Resend in {count.toString()}</>;
  }

  return (
    <Link component="button" onClick={handleResendClick}>
      Resend invite
    </Link>
  );
};
