import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import AuthService from '@/services/authentication.service';
import { Button } from '@montugroup/design-system';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';

export function EditOrderButton() {
  const { isEditing, isExistingOrder, toggleEditing, order, isAdminOrderInAuthorisedPaymentStatus } =
    useOrderFormMeta();

  if (
    !AuthService.isAdmin() ||
    !isAdminOrderInAuthorisedPaymentStatus ||
    !isExistingOrder ||
    isEditing ||
    !order ||
    order.payment_status === 'PAID'
  ) {
    return null;
  }

  return (
    <Button size="large" startIcon={<EditIcon fontSize="small" />} onClick={toggleEditing}>
      <Box sx={{ position: 'relative', top: '1.5px' }}>Edit</Box>
    </Button>
  );
}
