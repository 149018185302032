import { Box, Divider, styled, Typography } from '@mui/material';
import type { ReactNode } from 'react';

import MontuLogo from '@/components/common/MontuLogo';
import useGetUser from '@/hooks/rest/useGetUser';
import legacyTheme from '@/theme';
import { BrandThemeProvider } from '@montugroup/themes';

const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common.white,
  padding: '2rem 5%',

  [theme.breakpoints.up('md')]: {
    padding: '2rem 10%'
  }
}));

const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #1a4457;
  gap: 1rem;
`;

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const TextHeader = styled(Typography)(({ theme }) => ({
  fontSize: '1.3rem',
  lineHeight: 1,
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.125rem',
    lineHeight: 1.235
  }
}));

const TextBranding = styled(Typography)(({ theme }) => ({
  marginTop: '0.5rem',
  fontSize: '1rem',
  lineHeight: 1,
  '> svg': {
    marginBottom: '0.35rem' // aligning with the text from the design
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.2rem',
    '> svg': {
      marginBottom: '0.15rem' // aligning with the text from the design
    }
  }
}));

const MPatNumber = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: '0.25rem',
  fontSize: '1rem',
  lineHeight: 1,
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem'
  }
}));

const WrapperDivider = styled(Divider)(({ theme }) => ({
  border: `0.5px solid ${legacyTheme.colorSchemes.light.palette.primary.main}`, // legacy theme required
  margin: '1.6rem 0 0',

  [theme.breakpoints.up('lg')]: {
    marginBottom: '1.6rem'
  }
}));

interface Props {
  header: string;
  children: ReactNode;
}

function MontuBrandWrapper({ header, children }: Props) {
  const { data: userDetails } = useGetUser();

  return (
    <MainContainer className="montu-wrapper">
      <Header>
        <TextContainer data-dd-privacy="allow">
          <TextHeader variant="h4">{header}</TextHeader>

          <TextBranding variant="body1">
            powered by&nbsp;&nbsp;
            <MontuLogo fillColor="#1a4457" />
          </TextBranding>
        </TextContainer>

        <MPatNumber data-dd-privacy="allow" variant="h5">
          {userDetails?.mPatNumber}
        </MPatNumber>
      </Header>

      <WrapperDivider />

      <BrandThemeProvider theme={legacyTheme}>{children}</BrandThemeProvider>
    </MainContainer>
  );
}

export default MontuBrandWrapper;
