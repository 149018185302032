import MedicationPrescribedTable from '@/components/admin/patient-profile/MedicationPrescribedTable';
import { PatientActions } from '@/components/admin/patient-profile/PatientActions';
import { PatientApprovals } from '@/components/admin/patient-profile/PatientApprovals';
import { PatientDetails } from '@/components/admin/patient-profile/PatientDetails';
import PatientDiscounts from '@/components/admin/patient-profile/PatientDiscounts';
import { PatientNote } from '@/components/admin/patient-profile/PatientNote';
import { PatientPrescriptions } from '@/components/admin/patient-profile/PatientPrescriptions';
import AdminPageLayout from '@/components/layout/AdminPageLayout';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import { BrandThemeProvider, montu } from '@montugroup/themes';
import { Chip, Skeleton, Stack } from '@mui/material';
import { Navigate, useLocation, useParams } from 'react-router-dom';

export function PatientProfile() {
  const { pathname } = useLocation();
  const { id } = useParams();

  const { data: patientData } = useGetPatient(id ?? '');

  const ConcessionBadge = () => {
    if (!patientData?.data?.has_valid_concession_card) {
      return null;
    }
    return <Chip color="success" size="medium" label="Concession" sx={{ bottom: 5, position: 'relative' }} />;
  };

  if (pathname.startsWith('/onboard/')) {
    return <Navigate to={`/patient/${id}`} replace />;
  }

  return (
    <BrandThemeProvider theme={montu}>
      <AdminPageLayout
        fullWidth={false}
        title="Patient Profile"
        TitleAdornment={<ConcessionBadge />}
        subtitle={!patientData ? <Skeleton width={150} height={20} /> : patientData?.data.patient_code}
        Actions={!patientData ? <Skeleton width={200} height={38} variant="rectangular" /> : <PatientActions />}
      >
        <Stack gap={6}>
          <PatientDetails />
          <MedicationPrescribedTable />
          <PatientPrescriptions />
          <PatientDiscounts />
          <PatientApprovals />
          <PatientNote />
        </Stack>
      </AdminPageLayout>
    </BrandThemeProvider>
  );
}
