import { useQuery } from '@tanstack/react-query';

import { OrderService } from '@/services/order.service';

export function useGetOrder({ id }: { id: number | `${number}` | undefined }) {
  return useQuery({
    queryKey: ['order', id],
    enabled: Boolean(id),
    queryFn: async () => {
      if (id === undefined) {
        return;
      }
      const response = await OrderService.getOrder({ id });
      return response.data;
    }
  });
}
