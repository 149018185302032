import { BrandThemeProvider } from '@montugroup/themes';
import { Box, styled } from '@mui/material';
import type { ReactNode } from 'react';

import AlertSnackbarProvider from '@/components/error/AlertSnackbarProvider';

import OnboardingAlert from './components/OnboardingAlert';

import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import OnboardingBackground from './components/OnboardingBackground';
import OnboardingContent from './components/OnboardingContent';
import OnboardingFooter from './components/OnboardingFooter';
import OnboardingHeader from './components/OnboardingHeader';
import { LayoutConnector } from './contexts/onboarding-layout-context';

const LayoutContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export type PortalPartnerLayoutProps = {
  children?: ReactNode;
};

export function OnboardingLayout({ children }: PortalPartnerLayoutProps) {
  const { key: partnerKey } = usePortalPartnerBrand();
  return (
    <LayoutConnector>
      <BrandThemeProvider theme={partnerKey}>
        <AlertSnackbarProvider>
          <LayoutContainer>{children}</LayoutContainer>
        </AlertSnackbarProvider>
      </BrandThemeProvider>
    </LayoutConnector>
  );
}

OnboardingLayout.OnboardingHeader = OnboardingHeader;
OnboardingLayout.Alert = OnboardingAlert;
OnboardingLayout.Content = OnboardingContent;
OnboardingLayout.Background = OnboardingBackground;
OnboardingLayout.OnboardingFooter = OnboardingFooter;

export default OnboardingLayout;
