import { Box, Grid, styled, Typography } from '@mui/material';

export const StyledTokenInputContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: theme.spacing(6),
  padding: theme.spacing(16),
  paddingLeft: theme.spacing(18.75),
  paddingRight: theme.spacing(18.75),
  position: 'sticky',
  top: 200,
  marginBottom: theme.spacing(24),
  width: '100%',
  height: 'auto',
  boxShadow: '0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(8),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    position: 'static',
    top: 'auto',
    width: '100%',
    height: 'auto'
  }
}));

export const StyledSvgContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  height: 'auto',
  display: 'flex',
  paddingRight: theme.spacing(42),
  paddingBottom: theme.spacing(40),
  flexDirection: 'column',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    paddingRight: 0
  }
}));

export const StyledTopHeading = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(38),
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(0)
  }
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(12),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(20)
  }
}));

export const StyleSubHeading = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(12)
}));

export const StyledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingTop: theme.spacing(8)
}));
