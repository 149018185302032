import useGetDoctors from '@/hooks/doctors/useGetDoctors';
import useTableCountWithLoading from '@/hooks/table/useTableCountWithLoading';
import { useTableFilter } from '@/hooks/table/useTableFilter';
import { useTableManualPagination } from '@/hooks/table/useTableManualPagination';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import type { GPData } from '@/services/data.service';
import { noOp } from '@/utils/noOp';
import type { PaginationModel } from '@montugroup/design-system';
import type { SortingState } from '@tanstack/react-table';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const INITIAL_PAGE = 0;
const DEFAULT_PAGE_SIZE = 50;

export enum GP_FILTERS {
  All = '1',
  'Authorised prescriber' = '2',
  'General prescriber' = '3'
}

type DoctorsData = {
  page: number;
  pageSize: number;
  shouldResetPageIndex?: boolean;
  handlePaginationModelChange: (model: PaginationModel) => void;
  handleSortingOrderChange: (sortOrder: SortingState) => void;
  filter: string;
  onFilterChange: (filter: string) => void;
  gpFilter: GP_FILTERS;
  setGpFilter: (filter: GP_FILTERS) => void;
  isLoading: boolean;
  doctors: GPData[];
  doctorCount: number;
};

const DoctorsContext = createContext<DoctorsData>({
  page: INITIAL_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  shouldResetPageIndex: false,
  handlePaginationModelChange: noOp,
  handleSortingOrderChange: noOp,
  filter: '',
  onFilterChange: noOp,
  gpFilter: GP_FILTERS.All,
  setGpFilter: noOp,
  isLoading: false,
  doctors: [],
  doctorCount: 0
});

const sortFieldOverrides = {
  gp_name: 'Doctor.first_name',
  email: 'Doctor.email',
  joined_date: 'created_date',
  phone: 'Doctor.phone',
  ahpraNo: 'aphra_no'
};

export function DoctorsProvider(props: PropsWithChildren) {
  const { children } = props;
  const {
    paginationModel: { page, pageSize },
    setPaginationModel,
    shouldResetPageIndex,
    setShouldResetPageIndex
  } = useTableManualPagination({
    page: INITIAL_PAGE,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder({
    sortFieldOverrides
  });
  const { filter, onFilterChange } = useTableFilter();
  const [gpFilter, setGpFilter] = useState<GP_FILTERS>(GP_FILTERS.All);

  const { data, isFetching } = useGetDoctors({ page, pageSize, sortingOrder, filter, gpFilter });

  const count = useTableCountWithLoading(isFetching, data?.count);

  const handlePaginationModelChange = useCallback(
    (model: PaginationModel) => {
      setPaginationModel(model);
      setShouldResetPageIndex(false);
    },
    [setPaginationModel, setShouldResetPageIndex]
  );

  useEffect(() => {
    setShouldResetPageIndex(true);
  }, [filter, setShouldResetPageIndex]);

  const value = useMemo(
    () => ({
      page,
      pageSize,
      shouldResetPageIndex,
      isLoading: isFetching,
      doctors: data?.doctors ?? [],
      doctorCount: count,
      filter,
      onFilterChange,
      gpFilter,
      setGpFilter,
      handlePaginationModelChange,
      handleSortingOrderChange
    }),
    [
      page,
      pageSize,
      shouldResetPageIndex,
      isFetching,
      data?.doctors,
      count,
      filter,
      onFilterChange,
      gpFilter,
      setPaginationModel,
      handleSortingOrderChange
    ]
  );

  return <DoctorsContext.Provider value={value}>{children}</DoctorsContext.Provider>;
}

export const useDoctors = () => {
  const {
    page,
    pageSize,
    shouldResetPageIndex,
    isLoading,
    doctors,
    doctorCount,
    filter,
    onFilterChange,
    gpFilter,
    setGpFilter,
    handlePaginationModelChange,
    handleSortingOrderChange
  } = useContext(DoctorsContext);

  return {
    page,
    pageSize,
    shouldResetPageIndex,
    isLoading,
    doctors,
    doctorCount,
    filter,
    onFilterChange,
    gpFilter,
    setGpFilter,
    handlePaginationModelChange,
    handleSortingOrderChange
  };
};
