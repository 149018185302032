import PatientListTable from '@/components/admin/viewPatients/PatientListTable';
import ViewPatientsActions from '@/components/admin/viewPatients/ViewPatientsActions';
import ViewPatientsTable from '@/components/admin/viewPatients/ViewPatientsTable';
import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import AdminPageLayout from '@/components/layout/AdminPageLayout';
import { FF_ENABLE_PATIENT_LIST } from '@/constants/featureFlags';
import { ViewPatientsProvider } from '@/context/view-patients/ViewPatients';
import useFeatureFlags from '@/hooks/useFeatureFlags';

function ViewPatients() {
  const { flags } = useFeatureFlags();
  return (
    <ViewPatientsProvider>
      <AdminPageLayout title="Patients">
        <ManagedErrorToasts />
        <ViewPatientsActions />
        {flags[FF_ENABLE_PATIENT_LIST] ? <PatientListTable /> : <ViewPatientsTable />}
      </AdminPageLayout>
    </ViewPatientsProvider>
  );
}

export default ViewPatients;
