import { Typography } from '@mui/material';
import { useState } from 'react';
import type { SubmitTokenData } from '../Landing';
import { TokenInput } from '../TokenInput';
import { TokenLoading } from '../TokenLoading';
import { usePreOnboardingContext } from '../hooks/usePreOnboardingContext';

export interface SubmitTokenProps {
  setOpenTokenDialog: (state: boolean) => void;
  openTokenDialog: boolean;
}

export const SubmitToken = ({ setOpenTokenDialog, openTokenDialog }: SubmitTokenProps) => {
  const { setPreOnboardingState, setIsExistingUser } = usePreOnboardingContext();
  const [loading, setLoading] = useState(false); // todo: this loading state is temporary - will be handled by the query

  const handleTokenSubmit = async (formData: SubmitTokenData) => {
    // todo: hook up with erx mock + reactQuery
    setLoading(true); // Start loading

    try {
      /* mock the response time for animation to show */
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log('formData ->', formData);
    } catch (error) {
      console.error('Error submitting token:', error);
    } finally {
      setIsExistingUser(false); // hardcoding
      setPreOnboardingState('highConfidence'); // hardcoding the highConfidence state just to test the PreOnboardingProvider is working
      setLoading(false);
    }
  };

  if (loading) {
    return <TokenLoading />;
  }

  return (
    <>
      <Typography variant="subtitle2" fontWeight={600}>
        Enter your script details
      </Typography>
      <TokenInput
        setOpenTokenDialog={setOpenTokenDialog}
        openTokenDialog={openTokenDialog}
        handleTokenSubmit={handleTokenSubmit}
      />
    </>
  );
};
