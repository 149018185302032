import { ProfileSection } from '@/components/admin/patient-profile/ProfileSection';
import type { IUser } from '@/types/user.types';
import { Box, Button, Card, CardContent, TablePagination, TextField, Typography, styled } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const TextFieldStyled = styled(TextField)`
  width: 100%;
  display: flex;
`;

const BottomButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'flex-end',
  margin: theme.spacing(4, 0)
}));

const VerticalMargins = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(2)
}));

const VerticalScroll = styled(Typography)(({ theme }) => ({
  overflowY: 'auto',
  maxHeight: theme.spacing(40),
  marginTop: theme.spacing(3)
}));

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15];
const DEFAULT_ROWS_PER_PAGE = 5;

type NoteProps = {
  id: number;
  note: string;
  User?: Pick<IUser, 'first_name' | 'last_name'>;
  created_date: string;
};
export interface NotesProps<
  T extends { id: number; note: string; User?: Pick<IUser, 'first_name' | 'last_name'>; created_date?: string }
> {
  title?: string;
  label: string;
  isAddingNote: boolean;
  setIsAddingNote: (isAdding: boolean) => void;
  onNoteSubmit: (data: { note: string }) => void;
  notes: T[];
  canShowPagination?: boolean;
  enableAuthor?: boolean;
  enableDate?: boolean;
  onNoteCancel: () => void;
}

const Note = ({
  isAddingNote,
  setIsAddingNote,
  onNoteSubmit,
  title = 'Notes',
  label,
  notes,
  canShowPagination = true,
  enableAuthor = true,
  enableDate = true,
  onNoteCancel
}: NotesProps<any>) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<{ note: string }>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hasNotes = notes.length > 0;

  const paginatedNotes = hasNotes ? notes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];

  const noteToRender = canShowPagination ? paginatedNotes : notes;

  const noteValue = watch('note', '');

  const Actions = () => (
    <Button variant="contained" color="primary" onClick={() => setIsAddingNote(true)} disabled={isAddingNote}>
      Add New Note
    </Button>
  );
  return (
    <Box>
      <ProfileSection title={title} Actions={<Actions />}>
        {isAddingNote && (
          <form onSubmit={handleSubmit(onNoteSubmit)}>
            <Controller
              name="note"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextFieldStyled
                  {...field}
                  label={label}
                  placeholder="New Note"
                  variant="outlined"
                  multiline
                  rows={4}
                  error={!!errors.note}
                  helperText={errors.note?.message}
                />
              )}
            />
            <BottomButtonContainer>
              <Button onClick={onNoteCancel} variant="outlined">
                Cancel
              </Button>
              <Button type="submit" variant="contained" disabled={!noteValue.trim()}>
                Add
              </Button>
            </BottomButtonContainer>
          </form>
        )}

        {hasNotes ? (
          <Box>
            {noteToRender.map((note: NoteProps) => (
              <VerticalMargins key={note.id}>
                <Card>
                  <CardContent>
                    {enableAuthor && (
                      <Typography variant="caption" color={(theme) => theme.palette.grey[500]}>
                        {note.User?.first_name} {note.User?.last_name}{' '}
                      </Typography>
                    )}
                    {enableDate && (
                      <Typography variant="caption" color="textSecondary">
                        {DateTime.fromISO(note.created_date).setZone('local').toFormat('dd-MMM-yyyy, hh:mm a')}
                      </Typography>
                    )}
                    <VerticalScroll variant="body1">{note.note}</VerticalScroll>
                  </CardContent>
                </Card>
              </VerticalMargins>
            ))}
          </Box>
        ) : (
          <Typography variant="body2">{`No notes available. Click 'Add New Note' to create one.`}</Typography>
        )}

        {canShowPagination && hasNotes && (
          <TablePagination
            component="div"
            count={notes.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          />
        )}
      </ProfileSection>
    </Box>
  );
};

export default Note;
