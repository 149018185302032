import CopyToClipboard from '@/components/buttons/CopyToClipboard';
import { Box, Link, Stack, styled } from '@mui/material';

type OrderNumberTableCellProps = {
  isDoc: boolean;
  orderCode: string;
  orderId: number;
};

const Content = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(32),
  gap: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    maxWidth: theme.spacing(40)
  }
}));

function OrderNumberTableCell({ isDoc, orderCode, orderId }: OrderNumberTableCellProps) {
  return (
    <Content>
      {isDoc && orderCode}
      {!isDoc && (
        <Stack direction="row" alignItems="center">
          <Link href={`/order/${orderId}`} target="_blank">
            {orderCode}
          </Link>
          <CopyToClipboard textToCopy={orderCode} buttonVariant="icon" size="small" />
        </Stack>
      )}
    </Content>
  );
}

export default OrderNumberTableCell;
