import settings from '@/constants/constants';
import { FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE } from '@/constants/featureFlags';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import { useGetStates } from '@/hooks/data/useGetStates';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useAddressValidation from '@/hooks/user/useAddressValidation';
import type { Address } from '@/types/address.types';

import type { AddressDataType, PlaceDetails } from '@montugroup/design-system';
import { LocationInput, toast } from '@montugroup/design-system';
import { MenuItem, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

// TODO: Look to move this to an address util file
const formatAddress = (fullAddress: Address): string => {
  const { addressLine1, addressLine2, suburb, postcode, state } = fullAddress;
  if (!addressLine1 && !addressLine2 && !suburb && !postcode && !state) {
    return '';
  }

  const addressPart = addressLine2 ? `${addressLine2}/${addressLine1}` : addressLine1;
  const parts = [addressPart, suburb, postcode, state].filter(Boolean);

  return parts.join(', ');
};

export const LocationNestedInput = () => {
  const { id } = useParams();
  const { data: { data: patientData } = {} } = useGetPatient(id ?? '');
  const { data: states } = useGetStates(patientData?.country_id as number);
  const { flags } = useFeatureFlags();
  const [isManualAddressInput, setIsManualAddressInput] = useState(false);

  const isGooglePlacesEnabled = flags[FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE];

  const {
    register,
    control,
    watch,
    formState: { errors }
  } = useFormContext<{
    fullAddress: {
      addressLine1: string;
      addressLine2: string;
      suburb: string;
      postcode: string;
      state: string;
    };
  }>();

  const { validateParcelLocker, validatePostcode, validateStateBelongsToPostcode, validateStreetNumber } =
    useAddressValidation();

  const handleSelectedAddress = async (addressData: AddressDataType) => {
    const placeDetails: PlaceDetails = {
      address_components: [
        {
          long_name: addressData.street_number,
          short_name: addressData.street_number,
          types: ['street_number']
        }
      ]
    };

    const streetNumberValidationResult = validateStreetNumber(placeDetails);
    if (!streetNumberValidationResult.isValid) {
      toast.error(streetNumberValidationResult.errorMessage);
      return;
    }

    const formattedAddress = [addressData.subpremise, addressData.street_number, addressData.address]
      .filter(Boolean)
      .join(' ');

    const updatedAddress: Address = {
      addressLine1: formattedAddress,
      addressLine2: '',
      state: addressData.state,
      suburb: addressData.suburb || addressData.city,
      postcode: addressData.postcode
    };

    return updatedAddress;
  };

  const stateValue = watch('fullAddress.state');

  const addressLine1Rules = {
    required: 'Street Address 1 is required.',
    validate: (value: string) => validateParcelLocker(value)?.errorMessage ?? true
  };

  const addressLine2Rules = {
    validate: (value: string) => {
      if (!value) {
        return true;
      }
      return validateParcelLocker(value)?.errorMessage ?? true;
    }
  };

  const suburbRules = {
    required: 'Suburb is required.'
  };

  const stateRules = {
    required: 'State is required.'
  };

  const postcodeRules = {
    required: 'Postcode is required.',
    validate: (value: string) => {
      // First validate the postcode format
      const formatValidation = validatePostcode(value);
      if (!formatValidation.isValid) {
        return formatValidation.errorMessage;
      }

      // If format is valid, check state match
      const stateValidation = validateStateBelongsToPostcode(value, stateValue);
      if (!stateValidation.isValid) {
        return stateValidation.errorMessage;
      }

      return true;
    }
  };

  if (isGooglePlacesEnabled && !isManualAddressInput) {
    return (
      <Controller
        name="fullAddress"
        control={control}
        rules={{
          required: 'Address is required.'
        }}
        render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => {
          return (
            <LocationInput
              id="location-input"
              googlePlacesApiKey={settings.googlePlacesApiKey}
              label="Address"
              shrinkLabel
              onSelectedAddress={async (addressData) => {
                const formattedAddress = await handleSelectedAddress(addressData);
                onChange(formattedAddress);
              }}
              value={formatAddress(value)}
              manualAddressFn={() => setIsManualAddressInput(true)}
              error={(error && error.message) || (error && 'Address is not valid.')}
              {...rest}
            />
          );
        }}
      />
    );
  }

  return (
    <Stack rowGap={4} paddingTop={4}>
      <TextField
        fullWidth
        required
        label="Street Address 1"
        error={!!errors.fullAddress?.addressLine1}
        helperText={errors.fullAddress?.addressLine1?.message}
        InputLabelProps={{ shrink: true }}
        {...register('fullAddress.addressLine1', addressLine1Rules)}
      />
      <Stack direction={'row'} width={'100%'} columnGap={4}>
        <TextField
          fullWidth
          label="Street Address 2"
          InputLabelProps={{ shrink: true }}
          {...register('fullAddress.addressLine2', addressLine2Rules)}
        />
        <TextField
          fullWidth
          required
          label="Suburb"
          error={!!errors.fullAddress?.suburb}
          helperText={errors.fullAddress?.suburb?.message}
          InputLabelProps={{ shrink: true }}
          {...register('fullAddress.suburb', suburbRules)}
        />
      </Stack>
      <Stack direction={'row'} width={'100%'} columnGap={4}>
        <TextField
          select
          fullWidth
          required
          label="State/Territory"
          InputLabelProps={{ shrink: true }}
          error={!!errors.fullAddress?.state}
          helperText={errors.fullAddress?.state?.message}
          value={stateValue}
          {...register('fullAddress.state', stateRules)}
        >
          <MenuItem value="select" disabled>
            Select
          </MenuItem>
          {states?.map((state) => (
            <MenuItem key={state.id} value={state.name}>
              {state.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          required
          label="Postcode"
          error={!!errors.fullAddress?.postcode}
          helperText={errors.fullAddress?.postcode?.message}
          InputLabelProps={{ shrink: true }}
          {...register('fullAddress.postcode', postcodeRules)}
        />
      </Stack>
    </Stack>
  );
};
