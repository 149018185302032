import CopyToClipboard from '@/components/buttons/CopyToClipboard';
import type { Order } from '@/services/data.service';
import { Box, Link, Stack, styled } from '@mui/material';

type PatientDetailsTableCellProps = {
  order: Order;
};

const Container = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(24),
  [theme.breakpoints.up('lg')]: {
    minWidth: theme.spacing(24),
    maxWidth: theme.spacing(40)
  }
}));

export function PatientDetailsTableCell({ order }: PatientDetailsTableCellProps) {
  return (
    <Container>
      <Stack gap={2}>
        <Box>{order.patient_name}</Box>
        <Box>{order.patient_address_state_abbr}</Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Link href={`/onboard/${order.patient_uniq_id}`} target="_blank">
            {order.patient_id}
          </Link>
          <CopyToClipboard textToCopy={order.patient_id} buttonVariant="icon" size="small" />
        </Box>
      </Stack>
    </Container>
  );
}
