import type { AxiosResponse } from 'axios';
import axios from 'axios';

import type { PharmacistOrderTableView } from '@/context/pharmacist/orders/PharmacistOrders';
import type {
  AddMultipleStockToInventoryPayload,
  ConfirmDispenseOrderResult,
  GenericStatusResponse,
  OrderStatus,
  Pharmacist,
  PharmacistOrder,
  PharmacistReasonStatus,
  PharmacyInventoryHistory,
  ProcessDispenseResult,
  Product,
  TableSortOrder,
  UnableToDispenseInfo,
  UnableToDispenseProduct
} from '@/types';

import settings from '../constants/constants';

// TODO: FX-614 - Remove this when the feature is being enabled permanently.
const getCircuitProducts = () => axios.get(`${settings.url}/pharmacist/circleProducts`);

/**
 * Use `getPharmacyProducts` from pharmacy.service
 * @deprecated
 */
const getPharmacyProducts = () => axios.get(`${settings.url}/pharmacist/pharmacyProducts`);

// TODO: FX-614 - Remove this when the feature is being enabled permanently.
const getInventoryStatusList = () => axios.get(`${settings.url}/pharmacist/inventoryStatusList`);

const getPharmacistDetails = async () => axios.get<Pharmacist>(`${settings.url}/pharmacist/details`);

// TODO: FX-614 - Remove this when the feature is being enabled permanently.
const addStockToInventory = (data: unknown) => axios.post(`${settings.url}/pharmacist/addStockToInventory`, data);

/**
 * Use `getInventoryHistory` from pharmacy.service
 *  @deprecated
 */
const getInventoryHistory = (params: unknown) =>
  axios.get(`${settings.url}/pharmacist/getInventoryHistory`, { params });

const addMultipleStockToInventory = async (data: AddMultipleStockToInventoryPayload) =>
  axios.post<GenericStatusResponse>(`${settings.url}/pharmacist/addMultipleStockToInventory`, data);

const processDispensing = async (orderIds: number[]) =>
  axios.post<ProcessDispenseResult>(`${settings.url}/pharmacist/process-dispensing`, { orderIds });

const getEachInventoryHistory = (productId: number) =>
  axios.get<PharmacyInventoryHistory[]>(`${settings.url}/pharmacist/getEachInventoryHistory/${productId}`);

const getReasonStatusList = () =>
  axios.get<PharmacistReasonStatus[]>(`${settings.url}/pharmacist/unableToDispenseReasons`);

const unableToDispense = (data: UnableToDispenseProduct[]) =>
  axios.post(`${settings.url}/pharmacist/saveUnableToDispense`, data);

export type UnableToDispenseResponse = AxiosResponse & {
  orderProductData: UnableToDispenseInfo;
};

const getUnableToDispenseInfo = async (orderId: number) => {
  try {
    const response = await axios.get<UnableToDispenseResponse>(
      `${settings.url}/order/getUnableToDispenseOrderDetails/${orderId}`
    );
    return response?.data?.orderProductData;
  } catch {
    throw new Error('Failed to fetch order details');
  }
};

export type GetCountDataResponse = number;

const getCountData = () => axios.get<GetCountDataResponse>(`${settings.url}/pharmacist/process-dispensing/count`);

export type ConfirmDispenseOrderResponse = ConfirmDispenseOrderResult[] | undefined;

const confirmDispenseOrder = () =>
  axios.post<AxiosResponse<ConfirmDispenseOrderResponse | null>>(
    `${settings.url}/pharmacist/process-dispensing/confirm`
  );

export type ProductDetails = Pick<Product, 'name' | 'id'>;

export type PharmacistOrdersResponse = {
  count: number;
  orders: PharmacistOrder[];
};

const getPharmacistOrders = (
  pageSize: number,
  page: number,
  sortingOrder: TableSortOrder,
  filter: string,
  activeTab: PharmacistOrderTableView,
  statusFilter: OrderStatus[],
  signal?: AbortSignal
) =>
  axios.get<PharmacistOrdersResponse>(`${settings.url}/pharmacist/orders`, {
    signal,
    params: {
      limit: pageSize,
      offset: page * pageSize,
      sortingOrder: sortingOrder.name,
      sortOrder: sortingOrder.reverse ? 'DESC' : 'ASC',
      filter,
      statusFilter: statusFilter.map((status) => status.id),
      ts: new Date().getTime(),
      activeTab
    }
  });

export interface PharmacistBatchOrder {
  batchNumber: number;
  dispensedTimestamp: string;
  orderCount: number;
}

const getPharmacistBatchDispenseOrders = async (
  pageSize: number,
  page: number
): Promise<AxiosResponse<PharmacistBatchOrder[]>> => {
  const { data: pharmacistDetails } = await getPharmacistDetails();
  const pharmacyId = pharmacistDetails.pharmacy_id;
  const response = await axios.get(`${settings.adminApiUrl}/pharmacy/get-dispense-summary/${pharmacyId}`, {
    params: {
      limit: pageSize,
      offset: page * pageSize
    }
  });

  return response.data;
};

const getPharmacistDispenseDocument = async (dispenseId: number): Promise<AxiosResponse<{ signedUrl: string }>> => {
  const response = await axios.get(`${settings.adminApiUrl}/pharmacy/get-dispensed-order-doc/${dispenseId}`, {});

  return response.data;
};

export {
  addMultipleStockToInventory,
  addStockToInventory,
  confirmDispenseOrder,
  getCircuitProducts,
  getCountData,
  getEachInventoryHistory,
  getInventoryHistory,
  getInventoryStatusList,
  getPharmacistBatchDispenseOrders,
  getPharmacistDetails,
  getPharmacistDispenseDocument,
  getPharmacistOrders,
  getPharmacyProducts,
  getReasonStatusList,
  getUnableToDispenseInfo,
  processDispensing,
  unableToDispense
};
