import { Button, Typography } from '@mui/material';
import { usePreOnboardingContext } from '../hooks/usePreOnboardingContext';
import { CanFulfill } from './CanFulfill';

export const DuplicateScript = () => {
  const { isExistingUser } = usePreOnboardingContext();

  if (!isExistingUser) {
    return <CanFulfill highConfidence />;
  }

  return (
    <>
      <Typography variant="subtitle1" fontWeight={600}>
        Welcome back!
      </Typography>
      <Typography>
        Your script is already associated with an existing account. Login to order this medication.
      </Typography>
      <Button type="submit" variant="contained" color="primary" fullWidth>
        {/* TODO: once login portal page is confirmed, add router link */}
        Login
      </Button>
    </>
  );
};
