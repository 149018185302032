import { Button } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import { useState } from 'react';

import ExportAllDateRangeButton from '@/components/common/ExportAllDateRangeButton';
import TableSearchInput from '@/components/common/TableSearchInput';
import MergePatientModal from '@/components/patient/mergePatientModal/mergePatientModal';
import { FF_ENABLE_PATIENT_LIST } from '@/constants/featureFlags';
import { useViewPatients } from '@/context/view-patients/ViewPatients';
import useDownload from '@/hooks/useDownload';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';

const ActionsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2)
}));

function ViewPatientsActions() {
  const { flags } = useFeatureFlags();
  const [showMergePatientModal, setShowMergePatientModal] = useState<boolean>(false);
  const { filter, onFilterChange } = useViewPatients();
  const { downloadFile } = useDownload();

  const exportAll = async (fromDate: string, toDate: string) => {
    const fileName = `${new Date().getTime()}-montu-patients.csv`;

    await downloadFile(fileName, 'excel/patient/download', {
      method: 'POST',
      responseType: 'blob',
      data: {
        from_date: fromDate,
        to_date: toDate
      }
    });
  };

  return (
    <ActionsContainer>
      <TableSearchInput value={filter} onChange={onFilterChange} label="Search" placeholder="Name, ID, Email, Phone" />
      {!flags[FF_ENABLE_PATIENT_LIST] && (
        <Box>
          {AuthService.isSuperAdmin() && (
            <>
              <ButtonContainer>
                <Button size="large" variant="outlined" onClick={() => setShowMergePatientModal(true)}>
                  Merge patients
                </Button>
                <ExportAllDateRangeButton onExport={exportAll} />
              </ButtonContainer>

              <MergePatientModal onClick={() => setShowMergePatientModal(false)} open={showMergePatientModal} />
            </>
          )}
        </Box>
      )}
    </ActionsContainer>
  );
}

export default ViewPatientsActions;
