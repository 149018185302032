import { LocationNestedInput } from '@/components/admin/patient-profile/LocationNestedInput';
import { ProfileSection } from '@/components/admin/patient-profile/ProfileSection';
import settings from '@/constants/constants';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import { useGetStates } from '@/hooks/data/useGetStates';
import type { PatientData } from '@/services/patient.service';
import { Logger } from '@/utils/logger';
import { toast } from '@montugroup/design-system';
import { Box, Button, Skeleton, Stack, TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

const logger = new Logger('PatientDetails');

const { url } = settings;

const getFormDefaultValue = (patientData: PatientData | undefined) => {
  const { address, city: suburb, postal_code: postcode, state } = patientData ?? {};
  const [addressLine1, addressLine2] = address?.split('$$$$') ?? [];

  return {
    firstName: patientData?.first_name,
    lastName: patientData?.last_name,
    email: patientData?.email,
    phone: patientData?.phone,
    fullAddress: {
      addressLine1,
      addressLine2,
      suburb,
      postcode,
      state
    }
  };
};

export const PatientDetails = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data: { data: patientData } = {} } = useGetPatient(id ?? '');
  const { data: states } = useGetStates(patientData?.country_id as number);

  // NOTE: The patient update PUT request requires semi random casing and only requires some properties
  // (the GET request properties names and casings shouldn't be trusted)
  // also requires we send only the previous value for the priority
  const { mutateAsync } = useMutation({
    mutationFn: async (values: ReturnType<typeof getFormDefaultValue>) => {
      await axios.put(`${url}/patient/${id}`, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email?.trim(),
        phone: values.phone,

        address: `${values.fullAddress.addressLine1}$$$$${values.fullAddress.addressLine2 ?? ''}`,
        city: values.fullAddress.suburb,
        state: states?.find((state) => state.name.toLowerCase() === values.fullAddress.state?.toLowerCase())?.id,
        country_id: patientData?.country_id,
        zip_code: values.fullAddress.postcode,

        newPharmacyName: patientData?.patient_pharmacy_name,
        newPharmacyAddress: patientData?.patient_pharmacy_address,
        pharmacy_id: patientData?.pharmacy_id === '-' ? null : patientData?.pharmacy_id,
        note: null,
        priority: patientData?.priority,
        previousValues: {
          priority: patientData?.priority
        }
      });
    },
    onSuccess: (_, values) => {
      reset(values);
      toast.success('Patient details updated!', {});
      queryClient.invalidateQueries({ queryKey: ['patient', id] });
      logger.info('updated patient details');
    },
    onError: (error) => {
      toast.error('Error updating patient');
      logger.error('Error updating patient', error);
    }
  });

  const formMethods = useForm({
    values: getFormDefaultValue(patientData)
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isLoading, isSubmitting, isValidating, errors }
  } = formMethods;

  const isFormSubmittable = isDirty && !isLoading && !isSubmitting && !isValidating;

  if (!patientData) {
    return (
      <ProfileSection title="Patient Details">
        <Box>
          <Stack rowGap={4}>
            <Stack direction={'row'} width={'100%'} columnGap={4}>
              <Skeleton width="100%" height={56} variant="rectangular" />
              <Skeleton width="100%" height={56} variant="rectangular" />
            </Stack>
            <Stack direction={'row'} width={'100%'} columnGap={4}>
              <Skeleton width="100%" height={56} variant="rectangular" />
              <Skeleton width="100%" height={56} variant="rectangular" />
            </Stack>
            <Skeleton width="100%" height={40} variant="rectangular" />
            <Stack direction={'row'} justifyContent={'flex-end'} columnGap={2}>
              <Skeleton width={150} height={38} variant="rectangular" />
              <Skeleton width={150} height={38} variant="rectangular" />
            </Stack>
          </Stack>
        </Box>
      </ProfileSection>
    );
  }

  return (
    <ProfileSection title="Patient Details">
      <FormProvider {...formMethods}>
        <Stack rowGap={4}>
          <Stack direction={'row'} width={'100%'} columnGap={4}>
            <TextField
              required
              label="First Name"
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              {...register('firstName', { required: 'First Name is required.' })}
            />
            <TextField
              required
              label="Last Name"
              fullWidth
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              {...register('lastName', { required: 'Last Name is required.' })}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
          <Stack direction={'row'} width={'100%'} gap={4}>
            <TextField
              required
              label="Email"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              {...register('email', {
                required: 'Email is required.',
                validate: (value) => {
                  const result = z.string().email('Invalid email format.').safeParse(value);

                  return result.success || result.error.errors[0].message;
                }
              })}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              required
              label="Phone"
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone?.message}
              {...register('phone', {
                required: 'Phone is required.',
                validate: (value) => {
                  const result = z
                    .string()
                    .trim()
                    .refine((val) => isValidPhoneNumber(val, 'AU'), {
                      message: 'Invalid phone number.'
                    })
                    .safeParse(value);

                  return result.success || result.error.errors[0].message;
                }
              })}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
          <LocationNestedInput />
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-end'} columnGap={2}>
          <Button variant="outlined" size="large" disabled={!isDirty || isSubmitting} onClick={() => reset()}>
            Discard Changes
          </Button>
          <Button
            variant="contained"
            size="large"
            disabled={!isFormSubmittable}
            onClick={handleSubmit(async (values) => await mutateAsync(values))}
          >
            Update Profile
          </Button>
        </Stack>
      </FormProvider>
    </ProfileSection>
  );
};
