import { ProfileSection } from '@/components/admin/patient-profile/ProfileSection';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import type { PatientProduct } from '@/types';
import { PaginationVariant, Table } from '@montugroup/design-system';
import type { ColumnDef } from '@tanstack/react-table';
import { useParams } from 'react-router-dom';

const columns: ColumnDef<PatientProduct>[] = [
  {
    accessorKey: 'name',
    header: 'Medication',
    enableSorting: false
  },
  {
    accessorKey: 'remaining_units',
    header: 'Remaining Units',
    enableSorting: false
  }
];

const DEFAULT_PAGE_SIZE = 5;
const ROWS_PER_PAGE = [5, 10, 20, 50];

const MedicationPrescribedTable = () => {
  const { id } = useParams();

  const { data: patientData } = useGetPatient(id ?? '');
  const medicationList = patientData?.data?.products ?? [];

  if (!medicationList.length) {
    return null;
  }

  return (
    patientData?.data.client_id && (
      <ProfileSection title="Medication Prescribed">
        <Table
          columns={columns}
          data={medicationList}
          hasRowBackgroundColor={false}
          showPagination={medicationList.length > DEFAULT_PAGE_SIZE}
          pageSize={DEFAULT_PAGE_SIZE}
          manualPagination
          paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
          total={medicationList.length}
          pageSizeOptions={ROWS_PER_PAGE}
          elevation={0}
        />
      </ProfileSection>
    )
  );
};

export default MedicationPrescribedTable;
