import type { Order } from '@/services/data.service';
import { Box, Stack } from '@mui/material';

type ProductsTableCellTableCellProps = {
  order: Order;
};

export function ProductsTableCell({ order }: ProductsTableCellTableCellProps) {
  return (
    <Stack spacing={2}>
      {order.product_name.map((productName, index) => (
        <Box key={`${productName}-${index}`} display="flex" gap={2}>
          <Box fontWeight="bold">{order.quantity[index]}x</Box>
          <Box>{productName}</Box>
        </Box>
      ))}
    </Stack>
  );
}
