import { Button, toast } from '@montugroup/design-system';
import { Box, Stack, styled } from '@mui/material';
import axios from 'axios';
import moment from 'moment/moment';

import ExportAllDateRangeButton from '@/components/common/ExportAllDateRangeButton';
import { OrderStatus } from '@/components/order/order.types';
import BulkActions from '@/components/orders/bulkActions/BulkActions';
import settings from '@/constants/constants';
import {
  FF_ORDER_LIST_DISABLE_EXPORT_AND_SHIP_BUTTON,
  FF_ORDER_LIST_DISABLE_EXPORT_BUTTON
} from '@/constants/featureFlags';
import { useOrders } from '@/context/orders/Orders';
import { useExportAndShipAwaitingModal } from '@/context/orders/OrdersModals';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';

const url = settings.url;

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center'
  }
}));

function OrdersActions() {
  const { statusFilters, toggleSelectAllOrders } = useOrders();
  const { showExportAndShipAwaitingModal } = useExportAndShipAwaitingModal();

  const isSuperAdmin = AuthService.isSuperAdmin();
  const { flags } = useFeatureFlags();

  const downloadFile = async (from: string | null = null, to: string | null = null) => {
    const response = await axios.post(`${url}/excel/order/download`, {
      data: {
        ...(from ? { from_date: from } : {}),
        ...(to ? { to_date: to } : {})
      }
    });

    toast.success(response.data.message);
  };

  const exportAll = (fromDate: string, toDate: string) => {
    downloadFile(fromDate, toDate);
  };

  const exportAllAdmin = () => {
    downloadFile(
      moment().subtract(1, 'months').format('YYYY-MM-DD').toString(),
      moment().format('YYYY-MM-DD').toString()
    );
  };

  if (!AuthService.isAdmin()) {
    return null;
  }

  return (
    <Stack data-testid="orders-actions" direction="column" spacing={2}>
      <ActionsContainer>
        <BulkActions />
        {!flags[FF_ORDER_LIST_DISABLE_EXPORT_AND_SHIP_BUTTON] && (
          <Button size="large" variant="outlined" onClick={showExportAndShipAwaitingModal}>
            Export & Ship All Awaiting
          </Button>
        )}
        {statusFilters.length === 1 && statusFilters[0].name === OrderStatus.AwaitingShipment && (
          <Button size="large" variant="outlined" onClick={toggleSelectAllOrders}>
            Select all
          </Button>
        )}
        {!flags[FF_ORDER_LIST_DISABLE_EXPORT_BUTTON] && (
          <>
            {isSuperAdmin && <ExportAllDateRangeButton buttonProps={{ size: 'large' }} onExport={exportAll} />}
            {!isSuperAdmin && (
              <Button size="large" variant="outlined" onClick={exportAllAdmin}>
                Export
              </Button>
            )}
          </>
        )}
      </ActionsContainer>
    </Stack>
  );
}

export default OrdersActions;
