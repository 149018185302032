import { BrandThemeProvider } from '@montugroup/themes';
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Box, IconButton, SwipeableDrawer, Toolbar, styled } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import AltLogo from '@/components/common/AltLogo';
import { alternaleafAppBarConfig } from '@/components/layout/navbar/navbar.config';
import USER_ROLES from '@/constants/userRoles';
import AuthService from '@/services/authentication.service';
import { BRANDS } from '@/types';

import Logout from '../sidebar/Logout';
import { OrderButton } from '../sidebar/Sidebar';

import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import { useCreateOrderButton } from '@/hooks/useCreateOrderButton';
import { NavMenuItem } from '@montugroup/circuit-shared';

const NavigationBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '100%',
  boxShadow: 'none'
}));

const NavToolbar = styled(Toolbar)(({ theme }) => ({
  padding: theme.spacing(4),
  justifyContent: 'space-between',
  gap: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(5)
  }
}));

const MenuIconContainer = styled(Box)`
  display: flex;
`;

function MobileAppBar() {
  const { key: partnerKey } = usePortalPartnerBrand();
  const createOrderButton = useCreateOrderButton();
  const roleId = AuthService.getUser()?.user?.role_id || USER_ROLES.patient;
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const handleLinkClick = () => {
    scrollToTop();
    toggleMenu();
  };

  const mainMenuItemsAlternaleaf = alternaleafAppBarConfig.filter(
    (menuItem) => menuItem.brand === BRANDS.ALTERNALEAF && menuItem.roleId.includes(roleId)
  );

  return (
    <NavigationBar>
      <NavToolbar>
        <Link to="/home" onClick={scrollToTop}>
          <AltLogo />
        </Link>

        <MenuIconContainer>
          <IconButton
            size="small"
            aria-haspopup="true"
            aria-label="menu"
            onClick={toggleMenu}
            sx={{
              color: (theme) => theme.palette.primary.main,
              '&:focus': {
                outline: 'none'
              }
            }}
          >
            <MenuIcon
              sx={{
                height: 35,
                width: 35,
                color: (theme) => theme.palette.common.white
              }}
            />
          </IconButton>
        </MenuIconContainer>
      </NavToolbar>

      <SwipeableDrawer
        anchor="right"
        open={menuOpen}
        onClose={toggleMenu}
        onOpen={toggleMenu}
        PaperProps={{
          sx: {
            width: '100%',
            backgroundColor: (theme) => theme.palette.primary.main
          }
        }}
      >
        <NavToolbar
          sx={{
            marginTop: (theme) => theme.spacing(1)
          }}
        >
          <Link to="/home" onClick={toggleMenu}>
            <AltLogo />
          </Link>
          <MenuIconContainer>
            <IconButton onClick={toggleMenu}>
              <CloseIcon
                sx={{
                  color: (theme) => theme.palette.common.white
                }}
              />
            </IconButton>
          </MenuIconContainer>
        </NavToolbar>
        {mainMenuItemsAlternaleaf.map((mainMenuItem) => (
          <NavMenuItem key={mainMenuItem.id} menuItem={mainMenuItem} onClick={handleLinkClick} />
        ))}
        <Box paddingX={(theme) => theme.spacing(4)} mb={6} mt="auto">
          <BrandThemeProvider theme={partnerKey}>
            <Link to={createOrderButton.link} onClick={handleLinkClick}>
              <OrderButton variant="contained" size="large" color="primary">
                {createOrderButton.icon && <createOrderButton.icon />}
                {createOrderButton.name}
              </OrderButton>
            </Link>
          </BrandThemeProvider>
        </Box>
        <Logout />
      </SwipeableDrawer>
    </NavigationBar>
  );
}

export default MobileAppBar;
