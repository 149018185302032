import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Skeleton, styled, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import useGetShippingAddress from '@/hooks/order/useGetShippingAddress';

import { formatShippingAddress } from '../utils';

export type OrderStage = 'cancelled' | 'orderPlaced' | 'shippedByPharmacy' | 'delivered';

export type PharmacyOrderDetailsProps = {
  pharmacyName?: string;
  pharmacyLogoUrl?: string;
  stage: OrderStage;
  fullOrderCode: string;
};

const Wrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius
}));

const PharmacyDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(6),
  gap: theme.spacing(3)
}));

const PharmacyDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2)
}));

const ShippingDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(6),
  gap: theme.spacing(2)
}));

const OrderStepsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

const OrderStagesInOrder = ['orderPlaced', 'shippedByPharmacy', 'delivered'];

const StyledStep = styled(Step)(({ theme }) => ({
  '& .MuiStepLabel-root .Mui-completed svg': {
    color: `${theme.palette.success.main}`
  }
}));

export function PharmacyOrderDetails(props: PharmacyOrderDetailsProps) {
  const { pharmacyName, pharmacyLogoUrl, stage, fullOrderCode } = props;
  const stepperIndex = OrderStagesInOrder.indexOf(stage);

  const { data: shippingAddress, isLoading: isShippingAddressLoading } = useGetShippingAddress(fullOrderCode);

  return (
    <Wrapper>
      {pharmacyName && (
        <PharmacyDetailsContainer>
          <PharmacyDetails>
            {pharmacyLogoUrl ? <img src={pharmacyLogoUrl} alt="logo" width="40px" height="40px" /> : null}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="caption" lineHeight="1.5">
                Dispensing from
              </Typography>
              <Typography variant="subtitle2" fontWeight="demibold" lineHeight="1.3">
                {pharmacyName}
              </Typography>
            </Box>
          </PharmacyDetails>
          {stage !== 'cancelled' ? (
            <Typography variant="body2" lineHeight="1.3">
              Pharmacy chosen is based on fastest delivery time. Updating your address may change the allocated
              pharmacy.
            </Typography>
          ) : null}
        </PharmacyDetailsContainer>
      )}

      {!isShippingAddressLoading ? (
        shippingAddress && (
          <ShippingDetailsContainer>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <LocationOnIcon sx={(theme) => ({ color: theme.palette.secondary.main })} />
              <Typography variant="caption" lineHeight="1.3">
                SHIPPING TO
              </Typography>
            </Box>
            <Typography sx={{ marginLeft: (theme) => theme.spacing(1.5) }}>
              {formatShippingAddress(shippingAddress)}
            </Typography>
          </ShippingDetailsContainer>
        )
      ) : (
        <Skeleton variant="rectangular" width="100%" height={32} sx={(theme) => ({ marginBottom: theme.spacing(4) })} />
      )}

      <OrderStepsContainer>
        <Stepper activeStep={stepperIndex} orientation="vertical">
          <StyledStep key="OrderPlaced" completed={stepperIndex >= 0}>
            <StepLabel>Order placed</StepLabel>
          </StyledStep>
          <StyledStep key="ShippedByPharmacy" completed={stepperIndex >= 1}>
            <StepLabel>Shipped by pharmacy</StepLabel>
          </StyledStep>
          <StyledStep key="Delivered" completed={stepperIndex >= 2}>
            <StepLabel>Delivered</StepLabel>
          </StyledStep>
        </Stepper>
      </OrderStepsContainer>
    </Wrapper>
  );
}

export default PharmacyOrderDetails;
