import { Button, Typography } from '@mui/material';

/* TODO this page doesn't currently have associated functionality */
export const ExistingCustomerProductMatch = () => (
  <>
    <Typography variant="subtitle1" fontWeight={600}>
      Welcome back!
    </Typography>
    <Typography>Your script has been added to your account. Login to order this medication.</Typography>
    <Button type="submit" variant="contained" color="primary" fullWidth>
      {/* TODO: once login portal page is confirmed, add router link */}
      Login
    </Button>
  </>
);
