import React, { forwardRef } from 'react';

interface ExtraProps {
  info?: string;
  error?: string;
  label?: string;
  noLabel?: boolean;
  errorClass?: string;
  // rows,
  showViewMode?: boolean;
}
type FormInputProps = React.InputHTMLAttributes<HTMLInputElement> & ExtraProps;
type Ref = HTMLInputElement;

export const FormInput = forwardRef<Ref, FormInputProps>((props, ref) => {
  const { className, label, noLabel, id, showViewMode, error, info, errorClass, ...otherProps } = props;

  return (
    <div className={`form-group ${className || ''}`}>
      {!noLabel && (
        <label className="float-left mr-2 label" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        ref={ref}
        className={showViewMode ? 'form-control has-prefix input-field-invisible' : 'form-control has-prefix'}
        style={error ? { border: '1px solid red' } : {}}
        {...otherProps}
      />
      {error && <div className={errorClass || ''}>{error}</div>}
      {info && <small className="form-text text-muted ml-1">{info}</small>}
    </div>
  );
});

FormInput.displayName = 'FormInput';

type FormTextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & ExtraProps;
type AreaRef = HTMLTextAreaElement;

export const FormTextArea = forwardRef<AreaRef, FormTextAreaProps>((props, ref) => {
  const { className, label, noLabel, id, showViewMode, error, info, errorClass, ...otherProps } = props;

  return (
    <div className={`form-group ${className || ''}`}>
      {!noLabel && (
        <label className="float-left mr-2 label" htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        id={id}
        ref={ref}
        className={showViewMode ? 'form-control has-prefix input-field-invisible' : 'form-control has-prefix'}
        style={error ? { border: '1px solid red' } : {}}
        {...otherProps}
      />
      {error && <div className={errorClass || ''}>{error}</div>}
      {info && <small className="form-text text-muted ml-1">{info}</small>}
    </div>
  );
});

FormTextArea.displayName = 'FormTextArea';

export default FormInput;
