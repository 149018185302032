import { Switch } from '@mui/material';
import { CheckboxLabel, CheckboxWrapper } from './styles';

interface FormCheckboxProps {
  id: string;
  label: string;
  checked: boolean;
}

export const FormCheckbox = (props: FormCheckboxProps) => {
  const { id, label, checked } = props;
  return (
    <CheckboxWrapper>
      <CheckboxLabel>
        <label htmlFor={id}>{label}</label>
      </CheckboxLabel>
      <Switch checked={checked} readOnly id={id} name={id} />
    </CheckboxWrapper>
  );
};

export default FormCheckbox;
