import { OrderService } from '@/services/order.service';
import { useQuery } from '@tanstack/react-query';

export default function useGetPatientForOrder(id: number | undefined) {
  return useQuery({
    queryKey: ['patientForOrder', id],
    enabled: Boolean(id),
    queryFn: async () => {
      if (id === undefined) {
        return;
      }
      const response = await OrderService.getPatientForOrder({ id });
      return response.data;
    }
  });
}
