import type { Prescription } from '@/components/patient/prescription/common';

export type SupplierProduct = {
  id: number;
  product_id: number;
  supplier_id: number;
  createdAt: string;
  updatedAt: string;
};

export type Supplier = {
  id: number;
  supplier_code: string;
  supplier_name: string;
  secondary_email: string | null;
  is_montu_brand: boolean;
  SupplierProducts?: SupplierProduct[];
};

export type ProductImage = {
  id: number;
  image_url: string;
  display_order: number;
  product_id: number;
  created_date: string;
  modified_date: string;
};

export type Strain = {
  id: number;
  name: string;
  active: boolean;
  shopify_key: string;
  created_date: string;
  modified_date: string;
};

export type ProductSchedule = {
  id: number;
  name: string;
  active: boolean;
  created_date: string;
  modified_date: string;
};

export type ProductFormulationData = {
  id: number;
  name: string;
  whitelabel_image_url: string;
};

export type PrescriptionProduct = {
  id: number;
  dosage: string;
  interval: number;
  repeats: number;
  quantity: number;
  remaining_units: number;
  expired: boolean;
  eprescription_token: any;
  active: boolean;
  prescription_id: number;
  product_id: number;
  version_id: number;
  Prescription: Prescription;
  created_date?: string;
  modified_date?: string;
};

export type Product = {
  id: number;
  name: string;
  short_name?: string | null;
  sku_code?: string;
  price: number;
  pre_approved?: boolean | null;
  active?: boolean;
  order?: number | null;
  description?: string | null;
  description_for_display?: string | null;
  how_to_use?: string | null;
  size?: string | null;
  contents?: string | null;
  conditions?: string | null;
  available_to_all_gp?: boolean;
  thc_strength?: string | null;
  thc_strength_for_display?: string | null;
  thc_percentage?: number | null;
  cbd_thc_ratio?: string | null;
  cultivar?: string | null;
  cbd_strength?: string | null;
  cbd_strength_for_display?: string | null;
  dosage?: string | null;
  interval?: number | null;
  is_out_of_stock: boolean;
  new_product?: boolean;
  coming_soon?: boolean;
  new_at_lowprice?: boolean;
  campaign_discount_price: boolean;
  stock_return_date?: Date | string | null;
  reasoning_toggle: boolean;
  patient_catalog?: boolean;
  reasoning?: string | null;
  weight?: number | null;
  group_index?: number | null;
  image_url?: string | null;
  is_concession: boolean;
  is_supply_chain_risk?: boolean;
  is_generative_scripting: boolean;
  is_excluded_from_new_prescriptions?: boolean;
  ingredient_id?: number;
  formulation_id: number;
  quantity: number;
  product_group?: any;
  ProductImages?: ProductImage[];
  Strain?: Strain;
  schedule_id?: number;
  ProductSchedule?: ProductSchedule;
  ProductFormulation: ProductFormulationData;
  repeats?: number;
  remaining_units?: number;
  quantity_base_order: number;
  notAddable: boolean;
  dispensedDate?: string;
  intervalEndDate?: string;
  isProductLocked?: boolean;
  latest_prescription_expiry_date?: string;
  prescription_expiry_date?: string;
  prescription_code?: string;
  prescription_id?: number;
  prescriber_id?: number;
  prescriber_user_id?: number;
  prescriber_name?: string;
  needSASApproval?: boolean;
  remainingUnits?: number;
  Suppliers?: Supplier[];
};

export const ProductFormulations = {
  ORAL_LIQUID: 'Oral Liquid',
  HERB_DRIED: 'Herb, Dried',
  CAPSULE: 'Capsule',
  PASTILLE: 'Pastille',
  DEVICE: 'Device',
  INHALATION: 'Inhalation',
  TOPICAL: 'Topical',
  SPRAY: 'Spray',
  WAFER: 'Wafer',
  TABLET_CHEWABLE: 'Tablet, Chewable',
  CONCENTRATED_EXTRACT: 'Concentrated Extract',
  INHALANT_PRESSURIZED: 'Inhalant (pressure)',
  POWDER: 'Powder'
} as const;

export type ProductFormulation = (typeof ProductFormulations)[keyof typeof ProductFormulations];

export const ProductFormulationFilters = {
  ...ProductFormulations,
  ALL: 'all'
} as const;

export type ProductFormulationFilter = (typeof ProductFormulationFilters)[keyof typeof ProductFormulationFilters];

export const ProductStrains = {
  SATIVA: 'Sativa',
  INDICA: 'Indica',
  HYBRID: 'Hybrid',
  /** @deprecated Superseded by {@link ProductStrains.HYBRID} */
  BALANCED: 'Balanced'
} as const;

export type ProductStrain = (typeof ProductStrains)[keyof typeof ProductStrains];

export const ProductStrainKeys = {
  SATIVA: 'sativa',
  INDICA: 'indica',
  HYBRID: 'hybrid'
} as const;

export type ProductStrainKey = (typeof ProductStrainKeys)[keyof typeof ProductStrainKeys];

export const ProductSpectrums = {
  BROAD: 'Broad',
  FULL: 'Full'
};

export const BRANDS = {
  MONTU: 1,
  ALTERNALEAF: 2,
  UMEDS: 3
};
