import type { PaginationModel } from '@montugroup/design-system';
import { PaginationVariant, Table, toast } from '@montugroup/design-system';
import { Edit, Email } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { FF_ENABLE_PATIENT_PROFILE_UPLIFT } from '@/constants/featureFlags';
import { useErrorManagement } from '@/context/ErrorManagement';
import { useViewPatients } from '@/context/view-patients/ViewPatients';
import { useSearchPatients } from '@/hooks/admin/useSearchPatients';
import { useTableManualPagination } from '@/hooks/table/useTableManualPagination';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import type { SearchPatient } from '@/services/data.service';
import { UserService } from '@/services/user.service';

const sortFieldOverrides = {
  patient_name: 'PatientUser.first_name',
  phone: 'PatientUser.phone',
  email: 'PatientUser.email',
  state: 'PatientUser.State.name'
} as const;

const resendInvite = async (email: string) => {
  const response = await UserService.resendLoginInvite({ email });
  if (response.data.status === 200) {
    toast.success('Invite sent.');
  } else {
    toast.error('Failed to send invite.');
  }
};

function ViewPatientsTable() {
  const { enqueueError } = useErrorManagement();

  const { flags } = useFeatureFlags();
  const { filter } = useViewPatients();

  const {
    paginationModel: { page, pageSize },
    setPaginationModel,
    shouldResetPageIndex,
    setShouldResetPageIndex
  } = useTableManualPagination();
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder({ sortFieldOverrides });
  const { data, isLoading, error } = useSearchPatients({
    page,
    pageSize,
    sortingOrder,
    filter
  });

  useEffect(() => {
    if (error) {
      enqueueError({ title: 'Something went wrong', body: (error as { message: string }).message || '' });
    }
  }, [enqueueError, error]);

  const columns: ColumnDef<SearchPatient>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        enableSorting: true
      },
      {
        accessorKey: 'patient_code',
        header: 'Patient ID',
        enableSorting: true,
        cell: ({ row }) => (
          <Link to={`/${flags[FF_ENABLE_PATIENT_PROFILE_UPLIFT] ? 'patient' : 'onboard'}/${row.original.id}`}>
            {row.original.patient_code}
          </Link>
        )
      },
      {
        accessorKey: 'patient_name',
        header: 'Name',
        enableSorting: false
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: false
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        enableSorting: false
      },
      {
        accessorKey: 'state',
        header: 'State',
        enableSorting: false
      },
      {
        accessorKey: 'gpname',
        header: 'Doctor',
        enableSorting: false
      },
      {
        accessorKey: 'approval',
        header: 'Approval',
        enableSorting: false
      },
      {
        accessorKey: 'total_prescriptions',
        header: 'Total prescriptions',
        enableSorting: false
      },
      {
        accessorKey: 'circuit_access',
        header: 'Patient access',
        enableSorting: false
      },
      {
        id: 'resend',
        header: 'Resend invite',
        enableSorting: false,
        cell: ({ row }) => {
          if (row.original.circuit_access === 'Non-PMS' || row.original.circuit_access === 'Not active') {
            return null;
          }
          return (
            <IconButton
              disabled={row.original.circuit_access === 'Active'}
              onClick={() => resendInvite(row.original.email)}
            >
              <Email aria-label="resend invite" />
            </IconButton>
          );
        }
      },
      {
        id: 'edit',
        header: 'Edit',
        enableSorting: false,
        cell: ({ row }) => (
          <Link to={`/${flags[FF_ENABLE_PATIENT_PROFILE_UPLIFT] ? 'patient' : 'onboard'}/${row.original.id}`}>
            <Edit aria-label="edit" color="action" sx={{ cursor: 'pointer' }} />
          </Link>
        )
      }
    ],
    [flags]
  );

  useEffect(() => {
    setShouldResetPageIndex(true);
  }, [filter, setShouldResetPageIndex]);

  const onPaginationModelChange = (changedPaginationModel: PaginationModel) => {
    setPaginationModel(changedPaginationModel);
    setShouldResetPageIndex(false);
  };

  return (
    <Table
      isLoading={isLoading}
      data={data?.patients ?? []}
      columns={columns}
      columnVisibility={{ id: false }}
      total={data?.count}
      showPagination
      manualPagination
      shouldResetPageIndex={shouldResetPageIndex}
      pageSize={pageSize}
      onPaginationModelChange={onPaginationModelChange}
      paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
      onSortingChange={handleSortingOrderChange}
      hasRowBackgroundColor={false}
    />
  );
}

export default ViewPatientsTable;
