import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PropsWithChildren } from 'react';

export const Page = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: '83.3333%',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

export const FormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '1rem',
  ['&>*']: {
    flex: 1,
    width: 'calc(50% - 0.5rem)',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%'
    }
  }
}));

export const CheckboxWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start'
}));

export const CheckboxLabel = styled(Box)`
  width: 40%;
`;

export const PrefixedInput = styled(Box)`
  position: relative;
`;

export const Prefix = styled(Box)`
  position: absolute;
  left: 1rem;
`;

export const SubHeader = (props: PropsWithChildren) => {
  const { children } = props;
  return (
    <Typography
      variant="h6"
      component="h4"
      sx={(theme) => ({ textTransform: 'uppercase', marginTop: theme.spacing(6) })}
    >
      {children}
    </Typography>
  );
};
