import { PaginationVariant, Table } from '@montugroup/design-system';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';

import { usePharmacistOrders } from '@/context/pharmacist/orders/PharmacistOrders';
import useDownload from '@/hooks/useDownload';
import { getPharmacistDispenseDocument } from '@/services/pharmacist.service';
import type { PharmacistBatchOrder } from '@/types';
import { LoadingButton } from '@/ui-library';
import { dateColumnSort } from '@/utils/table';
import { Download } from '@mui/icons-material';
import { IconButton, TableCell } from '@mui/material';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

const DATE_DISPLAY_FORMAT = 'dd-MMM-yyyy hh:mm a';

const tableCellStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  padding: 0
};

const tableHeaderStyle = {
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

function PharmacistBatchOrdersTable() {
  const {
    batchOrders,
    batchCount,
    pageSize,
    handlePaginationModelChange,
    handleSortingOrderChange,
    loading,
    shouldResetPageIndex
  } = usePharmacistOrders();

  const { isDownloading, downloadFile, error } = useDownload();

  const handleDownloadClick = useCallback(
    async (dispenseId: number) => {
      const { data } = await getPharmacistDispenseDocument(dispenseId);
      const fileName = `Dispensed_Orders-${dispenseId}`;
      const fileType = '.pdf';
      const s3Url = data.signedUrl;

      downloadFile(fileName, s3Url, {}, fileType);

      if (error) {
        toast.error('Error downloading file');
      }
    },
    [downloadFile, error]
  );

  const columns: ColumnDef<PharmacistBatchOrder>[] = useMemo(() => {
    return [
      {
        accessorKey: 'batchNumber',
        header: 'Batch Number:',
        meta: {
          sx: tableHeaderStyle
        },
        cell: ({ row }: { row: Row<PharmacistBatchOrder> }) => (
          <TableCell sx={tableCellStyle}>{row.getValue('batchNumber')}</TableCell>
        )
      },
      {
        accessorKey: 'dispensedTimestamp',
        header: 'Dispensed On:',
        meta: {
          sx: tableHeaderStyle
        },
        accessorFn: (row: PharmacistBatchOrder) =>
          DateTime.fromISO(row.dispensedTimestamp).toFormat(DATE_DISPLAY_FORMAT),
        sortingFn: (rowA, rowB) => dateColumnSort(rowA.original.dispensedTimestamp, rowB.original.dispensedTimestamp),
        cell: ({ row }: { row: Row<PharmacistBatchOrder> }) => (
          <TableCell sx={tableCellStyle}>{row.getValue('dispensedTimestamp')}</TableCell>
        )
      },
      {
        accessorKey: 'orderCount',
        header: 'Order Count:',
        enableSorting: false,
        meta: {
          sx: tableHeaderStyle
        },
        cell: ({ row }: { row: Row<PharmacistBatchOrder> }) => (
          <TableCell sx={tableCellStyle}>{row.getValue('orderCount')}</TableCell>
        )
      },
      {
        accessorKey: 'dispense_doc',
        header: 'Download Dispense Document:',
        meta: {
          sx: tableHeaderStyle
        },
        enableSorting: false,
        cell: ({ row }: { row: Row<PharmacistBatchOrder> }) => (
          <TableCell sx={tableCellStyle}>
            {isDownloading ? (
              <LoadingButton />
            ) : (
              <IconButton
                onClick={async () => {
                  const dispenseId = row.getValue('batchNumber') as number;
                  await handleDownloadClick(dispenseId);
                }}
              >
                <Download />
              </IconButton>
            )}
          </TableCell>
        )
      }
    ];
  }, [handleDownloadClick, isDownloading]);

  return (
    <Table
      data={batchOrders}
      columns={columns}
      showPagination
      manualPagination
      shouldResetPageIndex={shouldResetPageIndex}
      pageSize={pageSize}
      total={batchCount}
      paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
      onPaginationModelChange={handlePaginationModelChange}
      onSortingChange={handleSortingOrderChange}
      isLoading={loading}
    />
  );
}

export default PharmacistBatchOrdersTable;
