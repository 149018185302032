import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import AuthService from '@/services/authentication.service';
import type { PatientConsentFormData } from '@/services/patientConsent.service';
import { fetchConsentStatus, submitPatientConsent } from '@/services/patientConsent.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('usePatientConsentStatus');

export function usePatientConsentStatus() {
  const userId = AuthService.getUserID()!;
  const queryClient = useQueryClient();

  const [patientConsentRequired, setPatientConsentRequired] = useState(true);

  const { isLoading, error, data } = useQuery({
    queryKey: ['patientConsentStatus'],
    queryFn: async () => {
      try {
        return await fetchConsentStatus(userId);
      } catch (err) {
        // Submitting consent is required in order to use the app.
        // If the API call fails, require the user to reload.
        logger.error('Call to fetch consent status failed', err);
        return Promise.resolve({
          requiresConsentSubmission: true,
          pendingConsentUuid: null,
          requireReload: true
        });
      }
    }
  });

  const submitConsentMutation = useMutation({
    mutationFn: (formData: PatientConsentFormData) =>
      submitPatientConsent({
        ...formData,
        uuid: data?.pendingConsentUuid || null,
        userId
      }),
    onSuccess: async (res) => {
      queryClient.invalidateQueries({
        queryKey: ['patientConsentStatus']
      });
      setPatientConsentRequired(!res?.data.succeeded);
    }
  });

  if (error) {
    return {
      isLoading: false,
      data: {
        requiresConsentSubmission: true,
        pendingConsentUuid: null,
        requireReload: true
      },
      submitConsent: submitConsentMutation.mutateAsync
    };
  }

  return {
    isLoading,
    data: {
      requiresConsentSubmission: patientConsentRequired && data?.requiresConsentSubmission,
      requireReload: data?.requireReload || false
    },
    submitConsent: submitConsentMutation.mutateAsync
  };
}

export default usePatientConsentStatus;
