import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import type { ButtonProps } from '@mui/material';
import { Button, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';

import { Logger } from '@/utils/logger';

const logger = new Logger('CopyToClipboard');

const TOOLTIP_CONTENT_DEFAULT = 'Copy to clipboard';
const TOOLTIP_CONTENT_COPIED = 'Copied to clipboard';
const TOOLTIP_CONTENT_FAILED_COPY = 'Failed to copy';

interface Props extends Omit<ButtonProps, 'onClick' | 'href'> {
  textToCopy: string;
  buttonVariant?: 'button' | 'icon';
}

function CopyToClipboard({ children, textToCopy, buttonVariant = 'button', ...buttonProps }: Props) {
  const [tooltipContent, setTooltipContent] = useState(TOOLTIP_CONTENT_DEFAULT);
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setTooltipContent(TOOLTIP_CONTENT_COPIED);
    } catch (error) {
      setTooltipContent(TOOLTIP_CONTENT_FAILED_COPY);
      logger.error('Failed to copy to clipboard', {
        error
      });
    } finally {
      setTimeout(() => setTooltipContent(TOOLTIP_CONTENT_DEFAULT), 2000);
    }
  };

  if (buttonVariant === 'button') {
    return (
      <Tooltip title={tooltipContent} arrow data-testid="copybutton">
        <Button variant="outlined" color="secondary" onClick={handleCopyToClipboard} {...buttonProps}>
          {children}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipContent} arrow data-testid="copybutton">
      <IconButton onClick={handleCopyToClipboard} data-testid="copybutton" {...buttonProps}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
}

export default CopyToClipboard;
