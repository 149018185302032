import { jsxs as r, jsx as n, Fragment as D } from "@emotion/react/jsx-runtime";
import { styled as Te, Box as F, Paper as we, TableContainer as ze, Table as ve, TableHead as Re, TableRow as _, TableCell as E, TableSortLabel as Ae, TableBody as De, Skeleton as _e, TablePagination as Ee, Typography as Fe, Pagination as Me, ButtonGroup as $e, Button as j } from "@mui/material";
import { visuallyHidden as Be } from "@mui/utils";
import { useReactTable as Ge, getCoreRowModel as He, getFilteredRowModel as Ne, getSortedRowModel as ke, getPaginationRowModel as je, flexRender as L } from "@tanstack/react-table";
import { memo as Le, useState as O, useMemo as U, useEffect as C } from "react";
var Oe = /* @__PURE__ */ ((o) => (o.VARIABLE_PAGE_SIZE = "Variable Page Size", o.FIXED_PAGE_SIZE = "Fixed Page Size", o.UNKNOWN_PAGE_SIZE = "Unknown page size", o))(Oe || {}), Ue = /* @__PURE__ */ ((o) => (o.ROW = "row", o.PAGE = "page", o))(Ue || {});
const Ze = Te(F)({
  display: "flex",
  alignItems: "center",
  padding: "1rem 1rem 0 1rem"
}), We = (o = 0, s = 0, d = 50) => {
  if (o === 0)
    return {
      summaryItemsCountFrom: 0,
      summaryItemsCountTo: 0,
      summaryPageTotal: o || 0
    };
  const p = s * d + 1, h = Math.ceil(o / d), T = s + 1 === h ? o : (s + 1) * d;
  return {
    summaryItemsCountFrom: p,
    summaryItemsCountTo: T,
    summaryPageTotal: h
  };
};
function Ke({
  className: o,
  data: s,
  columns: d,
  isLoading: p,
  elevation: h = 1,
  globalFilter: f,
  globalFilterFn: T,
  skeletonCount: Z = 10,
  skeletonHeight: W = 28,
  pageSize: g = 10,
  pageCount: K,
  total: m = 100,
  showPagination: X = !1,
  showPaginationSummary: q = !0,
  onClickRow: w,
  compact: J = !1,
  onPaginationModelChange: b,
  onSortingChange: z,
  manualSorting: Q = !1,
  initialSort: Y = [],
  hasRowBackgroundColor: M = !0,
  isScrollable: $ = !1,
  maxHeight: V = 300,
  rowSelection: ee,
  onRowSelectionChange: te,
  noDataFoundMessage: ne = "No data found",
  columnVisibility: oe,
  manualPagination: c,
  pageSizeOptions: ae,
  paginationVariant: u = "Variable Page Size",
  paginationLabelVariant: ie = "row",
  FooterLeftElement: B,
  isTableLayoutFixed: re,
  hasPrevPage: G,
  hasNextPage: se,
  shouldResetPageIndex: H,
  autoResetPageIndex: le,
  applyRowStyles: N
}) {
  const [i, de] = O(Y), [ce, ge] = O({
    pageIndex: 0,
    pageSize: g
  }), me = U(() => d, [d]), v = U(() => s, [s]), {
    getHeaderGroups: ue,
    getRowModel: k,
    getAllColumns: pe,
    setPageIndex: he,
    getState: a,
    setPageSize: R,
    getRowCount: A,
    resetPageIndex: x
  } = Ge({
    data: v || [],
    columns: me,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: g
      },
      columnVisibility: oe
    },
    state: {
      pagination: ce,
      globalFilter: f == null ? void 0 : f.trim(),
      sorting: i,
      rowSelection: ee
    },
    pageCount: K,
    enableSorting: !0,
    manualSorting: Q,
    enableSortingRemoval: !1,
    onSortingChange: de,
    getCoreRowModel: He(),
    getFilteredRowModel: Ne(),
    getSortedRowModel: ke(),
    onRowSelectionChange: te,
    getPaginationRowModel: je(),
    onPaginationChange: ge,
    manualPagination: c,
    globalFilterFn: T ?? "auto",
    autoResetPageIndex: le
  }), { summaryItemsCountFrom: fe, summaryItemsCountTo: be, summaryPageTotal: xe } = We(
    c ? m : A(),
    a().pagination.pageIndex,
    a().pagination.pageSize
  ), Se = Array.from({ length: Z }, (t, e) => e), ye = pe().length, Pe = !p && (!v || v.length === 0 || k().rows.length < 1), Ie = (t) => {
    const e = Number(t.target.value);
    R(e), b && b({
      page: a().pagination.pageIndex,
      pageSize: e
    });
  }, S = (t) => {
    he(t), b && b({
      page: t,
      pageSize: a().pagination.pageSize
    });
  }, Ce = ({ from: t, to: e, count: l, page: y }) => {
    if (ie === "row")
      return `${t}–${e} of ${l !== -1 ? l : `more than ${e}`}`;
    const P = y + 1, I = Math.ceil(l / a().pagination.pageSize);
    return `${P} of ${I}`;
  };
  return C(() => {
    g && R(g);
  }, [g, R]), C(() => {
    z && z(i);
  }, [z, i]), C(() => {
    !m && c && x();
  }, [s, x, m, c]), C(() => {
    H && x();
  }, [s, H, x]), /* @__PURE__ */ r(we, { sx: { pb: 4, borderRadius: 2 }, className: o, elevation: h, children: [
    /* @__PURE__ */ r(
      ze,
      {
        sx: {
          borderBottom: M ? 1 : "none",
          borderColor: "grey.300",
          maxHeight: $ ? V : "auto"
        },
        children: [
          /* @__PURE__ */ r(
            ve,
            {
              size: J ? "small" : "medium",
              stickyHeader: $,
              sx: {
                tableLayout: re ? "fixed" : "auto"
              },
              children: [
                /* @__PURE__ */ n(Re, { "data-testid": "table-heading", children: ue().map((t) => /* @__PURE__ */ n(_, { children: t.headers.map((e) => {
                  var l, y, P, I;
                  return /* @__PURE__ */ n(
                    E,
                    {
                      style: (l = e.column.columnDef.meta) == null ? void 0 : l.style,
                      sx: (y = e.column.columnDef.meta) == null ? void 0 : y.sx,
                      children: e.isPlaceholder ? null : /* @__PURE__ */ r(
                        Ae,
                        {
                          active: ((P = i == null ? void 0 : i[0]) == null ? void 0 : P.id) === e.id,
                          direction: e.column.getIsSorted() === "asc" ? "asc" : "desc",
                          onClick: e.column.getToggleSortingHandler(),
                          hideSortIcon: !e.column.columnDef.enableSorting,
                          sx: { "& > :first-child:hover + svg": { opacity: 0 } },
                          children: [
                            L(e.column.columnDef.header, e.getContext()),
                            ((I = i == null ? void 0 : i[0]) == null ? void 0 : I.id) === e.id ? /* @__PURE__ */ n(F, { component: "span", sx: Be, children: e.column.getIsSorted() === "desc" ? "sorted descending" : "sorted ascending" }) : null
                          ]
                        }
                      )
                    },
                    e.id
                  );
                }) }, t.id)) }),
                /* @__PURE__ */ n(De, { "data-testid": "table-content", children: p ? /* @__PURE__ */ n(D, { children: Se.map((t) => /* @__PURE__ */ n(_, { children: Array.from({ length: ye }, (e, l) => l).map((e) => /* @__PURE__ */ n(E, { children: /* @__PURE__ */ n(_e, { height: W }) }, e)) }, t)) }) : k().rows.map((t) => /* @__PURE__ */ n(
                  _,
                  {
                    sx: {
                      "&:last-child td, &:last-child th": { border: 0 },
                      ...M ? {
                        "&:nth-of-type(odd)": {
                          bgcolor: "action.hover"
                        }
                      } : {},
                      ...N ? N(t) : {}
                    },
                    hover: !0,
                    children: t.getVisibleCells().map((e) => /* @__PURE__ */ n(E, { scope: "row", onClick: () => w == null ? void 0 : w(e, t), children: L(e.column.columnDef.cell, e.getContext()) }, e.id))
                  },
                  t.id
                )) })
              ]
            }
          ),
          Pe && /* @__PURE__ */ n(F, { my: 2, textAlign: "center", children: ne })
        ]
      }
    ),
    X && /* @__PURE__ */ r(
      Ze,
      {
        justifyContent: u === "Fixed Page Size" || u === "Variable Page Size" && B ? "space-between" : "flex-end",
        children: [
          u === "Variable Page Size" && /* @__PURE__ */ r(D, { children: [
            B,
            /* @__PURE__ */ n(
              Ee,
              {
                component: "div",
                count: c ? m : A(),
                page: a().pagination.pageIndex,
                rowsPerPage: a().pagination.pageSize,
                onRowsPerPageChange: Ie,
                onPageChange: (t, e) => S(e),
                rowsPerPageOptions: ae,
                labelDisplayedRows: Ce
              }
            )
          ] }),
          u === "Fixed Page Size" && /* @__PURE__ */ r(D, { children: [
            q && /* @__PURE__ */ r(Fe, { "data-testid": "pagination-navigation-label", children: [
              "Showing ",
              /* @__PURE__ */ n("b", { children: fe }),
              " to ",
              /* @__PURE__ */ n("b", { children: be }),
              " of",
              " ",
              /* @__PURE__ */ n("b", { children: c ? m : A() })
            ] }),
            /* @__PURE__ */ n(
              Me,
              {
                count: xe,
                page: a().pagination.pageIndex + 1,
                onChange: (t, e) => S(e - 1),
                variant: "outlined",
                shape: "rounded"
              }
            )
          ] }),
          u === "Unknown page size" && /* @__PURE__ */ r($e, { variant: "outlined", children: [
            /* @__PURE__ */ n(
              j,
              {
                disabled: typeof G == "boolean" ? !G : a().pagination.pageIndex <= 0,
                onClick: () => S(a().pagination.pageIndex - 1),
                children: "Previous"
              }
            ),
            /* @__PURE__ */ n(
              j,
              {
                onClick: () => S(a().pagination.pageIndex + 1),
                disabled: se === !1,
                children: "Next"
              }
            )
          ] })
        ]
      }
    )
  ] });
}
const Ve = Le(Ke);
export {
  Oe as P,
  Ve as T,
  Ue as a,
  Ke as b
};
