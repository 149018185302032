import { useQuery } from '@tanstack/react-query';

import { getPharmacistBatchDispenseOrders } from '@/services/pharmacist.service';

const useGetPharmacistBatchDispense = (pageSize: number, page: number) => {
  const { isLoading, data, isError, isSuccess, refetch } = useQuery({
    queryKey: ['getPharmacistBatchDispense'],
    queryFn: async () => await getPharmacistBatchDispenseOrders(pageSize, page)
  });

  return { isLoading, data, isError, isSuccess, refetch };
};

export default useGetPharmacistBatchDispense;
