import settings from '@/constants/constants';
import { FF_DISABLE_DISPENSE_PRIORITY } from '@/constants/featureFlags';
import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';
import { FormControlLabel, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export function DispensePrioritySwitch() {
  const { order, isRefill, isEditing, isExistingOrder } = useOrderFormMeta();
  const { flags } = useFeatureFlags();

  const hasPharmacist = (order?.Pharmacy?.Pharmacists ?? []).length > 0;

  const { control } = useFormContext();

  if (
    flags[FF_DISABLE_DISPENSE_PRIORITY] ||
    !AuthService.isAdmin() ||
    !hasPharmacist ||
    isRefill ||
    !isExistingOrder ||
    order?.order_status_id === settings.orderStatus.PHARMACY_DISPENSED
  ) {
    return null;
  }

  return (
    <Controller
      name="priority"
      control={control}
      render={({ field: { onChange, value }, ...rest }) => (
        <FormControlLabel
          sx={{ marginBottom: 0 }}
          control={
            <Switch
              checked={!!value}
              disabled={!isEditing}
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
              {...rest}
            />
          }
          label="Dispense priority"
          labelPlacement="start"
        />
      )}
    />
  );
}
