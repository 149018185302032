import { Divider, Typography } from '@mui/material';
import { ResendInviteButton } from '../ResendInviteButton';
import { usePreOnboardingContext } from '../hooks/usePreOnboardingContext';
import type { SubmitEmailData } from './CustomerEmailInput';
import { CustomerEmailInput } from './CustomerEmailInput';

export interface CanFulfillProps {
  highConfidence?: boolean;
}

export const CanFulfill = ({ highConfidence }: CanFulfillProps) => {
  const { customerDetails, setPreOnboardingState, setCustomerDetails } = usePreOnboardingContext();
  const customerContactInfo = customerDetails?.email || customerDetails?.phoneNumber;

  const textContentHighConfidence = customerContactInfo ? (
    <>
      <Typography>
        Create an account to order your medication. We&apos;ve sent an invite link to <b>{customerContactInfo}</b>.
      </Typography>
      <Typography>If your details have changed, please update them with your doctor.</Typography>
    </>
  ) : (
    <Typography>
      Create an account to order your medication. Enter your email and we&apos;ll send you an invite link.
    </Typography>
  );

  const textContentLowConfidence = customerContactInfo ? (
    <>
      <Typography>
        We are reviewing your script to make sure we provide the correct medication. We will inform you of the outcome.
      </Typography>
      <Typography>
        You can still view your medication by signing up with the link we&apos;ve sent to <b>{customerContactInfo}</b>.
      </Typography>
      <Typography>If your details have changed, please update them with your doctor.</Typography>
    </>
  ) : (
    <>
      <Typography>
        We are reviewing your script to make sure we provide the correct medication. We will inform you of the outcome.
      </Typography>
      <Typography>
        You can still view your medication by signing up. Enter your email and we&apos;ll send you an invite link.
      </Typography>
    </>
  );

  /* TODO add functionality to actually submit email/send invite */
  const handleEmailSubmit = (formData: SubmitEmailData) => {
    setCustomerDetails({ email: formData.email });
    setPreOnboardingState('inviteSent');
  };

  /* TODO Currently hardcoded email, once we know the structure of the data we will have to update this component to be more dynamic */
  return (
    <>
      {highConfidence ? (
        <>
          <Typography variant="subtitle1" fontWeight={600}>
            Good news! We can fill your script.
          </Typography>
          {textContentHighConfidence}
        </>
      ) : (
        <>
          <Typography variant="subtitle1" fontWeight={600}>
            Looks like we can fill your script, but let us check.
          </Typography>
          {textContentLowConfidence}
        </>
      )}
      {customerContactInfo ? (
        <>
          <Divider />
          <Typography variant="body2">
            Didn&apos;t get the invite? <ResendInviteButton />
          </Typography>
        </>
      ) : (
        <CustomerEmailInput handleEmailSubmit={handleEmailSubmit} />
      )}
    </>
  );
};
